import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Grid } from '@mui/material';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';

const AccessDenied = (props) => {
	const { width = '60%' } = props;

	const styles = {
		mainContainer: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
		},
		accessDeniedContainer: {
			borderRadius: '24px',
			maxWidth: width,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			margin: '48px 0px',
			padding: '6px 12px',
		},
		accessDeniedTitle: {
			marginBottom: '-12px',
		},
	};
	return (
		<div style={styles.mainContainer}>
			<Grid container style={styles.accessDeniedContainer}>
				{/* <h3 style={styles.accessDeniedTitle}>UNAUTHORIZED</h3> */}
				<LockPersonOutlinedIcon sx={{ fontSize: '48px' }} />
				<p>
					You don't have enough permissions to access this resource. Contact
					your administrator for more information
				</p>
			</Grid>
		</div>
	);
};

AccessDenied.propTypes = {
	width: PropTypes.any,
};

export default AccessDenied;
