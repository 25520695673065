import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

// Auth
// import { useAuth0 } from '@auth0/auth0-react';
import { useNeuAuth } from '@neuraptic/neuauth-react';

export const ProtectedRoute = ({ children }) => {
	const { isAuthenticated } = useNeuAuth();

	// Maintenance mode

	if (process.env.REACT_APP_IS_MAINTENANCE_MODE === 'true') return children;

	return isAuthenticated() ? children || <Outlet /> : <Navigate to="/signin" />;
};

ProtectedRoute.propTypes = {
	children: PropTypes.func,
};
