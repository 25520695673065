import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Manage roles & permissions
import { HasAccess } from '@permify/react-role';

// Components
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import CardItem from '../../../../Components/Shared/CardItem';
import EditableFlow from '../../../../Components/Core/Task/Schema/EditableFlow';
import { Loader } from '../../../../Components/Shared/Loader';
import { ConfirmationDialog } from '../../../../Components/Shared/ConfirmationDialog';
import AccessDenied from '../../../../Components/Core/AccessDenied';

// Styles
import { measures } from '../../../../consts/sizes';
import styles from './styles.module.css';

// Redux
import { DELETE_SCHEMA_NODE, GET_SCHEMA } from '../../../../redux/schema.slice';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';
import requestFactory from '../../../../services/request.factory';
import { ADD_ALERT, REMOVE_ALERT } from '../../../../redux/alerts.slice';
import { SCHEMA_NODE_DELETED } from '../../../../AlertsList/projectSettingsAlerts';

export function TaskSchema() {
	const dispatch = useDispatch();

	const { accessToken } = useSelector((state) => state.user);

	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { schema: schemaState } = useSelector((state) => state.schema);

	const [openConfirm, setOpenConfirm] = useState(false);
	const [nodeToDeleteId, setNodeToDeleteId] = useState('');
	const [nodeToDeleteType, setNodeToDeleteType] = useState('');
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);

	const handleOpenConfirmDialog = (type, id) => {
		setNodeToDeleteId(id);
		setNodeToDeleteType(type);
		setOpenConfirm(true);
	};

	const handleDeleteNode = async () => {
		setIsDeleteLoading(true);
		if (nodeToDeleteType && nodeToDeleteId) {
			await dispatch(
				DELETE_SCHEMA_NODE({
					nodeType: nodeToDeleteType,
					nodeId: nodeToDeleteId,
					taskId: currentTaskState.id,
					dispatch,
					accessToken,
				})
			);
			await dispatch(
				GET_SCHEMA({ accessToken, taskId: currentTaskState.id, dispatch })
			);

			dispatch(
				ADD_ALERT({
					type: 'success',
					message: SCHEMA_NODE_DELETED(nodeToDeleteType),
				})
			);
			setTimeout(() => {
				dispatch(REMOVE_ALERT(SCHEMA_NODE_DELETED(nodeToDeleteType)));
			}, 2000);
			setOpenConfirm(false);
			setIsDeleteLoading(false);
			setNodeToDeleteId('');
			setNodeToDeleteType('');
		}
	};

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								padding: 1,
								marginBottom: 3,
							}}
						>
							<Box sx={{ height: '584px' }}>
								{Object.keys(schemaState).length > 0 ? (
									<EditableFlow
										handleOpenConfirmDialog={handleOpenConfirmDialog}
										isDeleteLoading={isDeleteLoading}
									/>
								) : (
									<Loader size="L" />
								)}
							</Box>
						</CardItem>
					</Grid>
				</Grid>
				<ConfirmationDialog
					className={styles.Delete_modal}
					title={`Delete ${nodeToDeleteType}?`}
					open={openConfirm}
					setOpen={setOpenConfirm}
					onConfirm={handleDeleteNode}
					isLoading={isDeleteLoading}
				>
					<>Are you sure you want to delete this {nodeToDeleteType}?</>
				</ConfirmationDialog>
			</Grid>
		</HasAccess>
	);
}

/* <Grid item xs={12} md={5} lg={5}>
					<CardItem
						className={styles.schema_card_block}
						elevation={measures.cardItemElevation}
						sx={{
							padding: 1,
							marginBottom: 3,
							height: '590px',
						}}
					>
						<div
							style={{
								display: 'flex',
								width: '100%',
								height: '100%',
								marginBottom: '0px',
								marginTop: '0px',
								gap: '0',
							}}
						>
							<div className={styles.schema_input_options}>
								<div
									className={classes.dataSelectorContainer}
									style={{
										display: 'flex',
										flexDirection: 'column',
										width: '100%',
										height: '100%',
										overflow: 'auto',
									}}
								>
									<button
										type="button"
										onClick={() => handleDataSelector('inputs')}
										className={`${classes.dataSelector} ${
											dataSelector === 'inputs' ? 'active' : ''
										}`}
									>
										<LoginRoundedIcon
											fontSize="small"
											className={classes.inputs}
										/>
										<div className={classes.inputs}>Inputs</div>
									</button>

									<button
										type="button"
										onClick={() => handleDataSelector('targets')}
										className={`${classes.dataSelector} ${
											dataSelector === 'targets' ? 'active' : ''
										}`}
									>
										<LogoutRoundedIcon
											fontSize="small"
											className={classes.outputs}
										/>
										<div className={classes.outputs}>Targets</div>
									</button>

									<button
										type="button"
										onClick={() => handleDataSelector('meta-data')}
										className={`${classes.dataSelector} ${
											dataSelector === 'meta-data' ? 'active' : ''
										}`}
									>
										<LocalOfferOutlinedIcon
											fontSize="small"
											className={classes.metaData}
										/>
										<div className={classes.metaData}>Meta data</div>
									</button>

									<button
										type="button"
										onClick={() => handleDataSelector('groups')}
										className={`${classes.dataSelector} ${
											dataSelector === 'groups' ? 'active' : ''
										}`}
									>
										<AutoAwesomeMotionRoundedIcon
											fontSize="small"
											className={classes.groups}
										/>
										<div className={classes.groups}>Groups</div>
									</button>
								</div>
							</div>

							<div className={styles.schema_drag_block}>
								{
                  // Inputs
                } 
								{dataSelector === 'inputs' && (
									<div
										className={classes.addDataContainer}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											width: '100%',
											height: '100%',
											marginBottom: '0px',
											marginTop: '0px',
											gap: '0',
										}}
									>
										<div className={styles.schema_drag_listing}>
											{!!inputs.length &&
												inputs.map((input) => (
													<div
														key={input.id || input.id}
														className={classes.addData}
													>
														<div className={styles.Drag_icon}>
															<DragIndicatorIcon />
														</div>
														<div className={classes.dataType}>
															{input.name}
														</div>
														<div className={classes.dataSize}>
															<CloudUploadOutlinedIcon fontSize="x-small" />
															225k
														</div>
														<div className={classes.dataManage}>
															<IconButton
																className={styles.schema_edit_options}
																onClick={() =>
																	handleOpenUpdateDialog(input, 'input')
																}
															>
																<Edit
																	sx={{
																		color: `${colors.darkGray} !important`,
																	}}
																/>
															</IconButton>
															<IconButton
																className={styles.schema_edit_options}
																onClick={() =>
																	handleOpenConfirmDialog(
																		'input',
																		input.id || input.element
																	)
																}
															>
																<Delete
																	sx={{
																		color: `red !important`,
																	}}
																/>
															</IconButton>
														</div>
													</div>
												))}
										</div>
										<Button
											variant="outlined"
											onClick={handleOpenCreateDialog}
											className={classes.addData}
											sx={{
												marginLeft: '10px !important',
												boxShadow: 'none !important',
												border: 'none',
												cursor: 'pointer',
												'&:hover': {
													boxShadow: 'none !important',
												},
											}}
										>
											+ Add Input
										</Button>
									</div>
								)}

								{
                  // Targets
                }
								{dataSelector === 'targets' && (
									<div
										className={classes.addDataContainer}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											width: '100%',
											height: '100%',
											marginBottom: '0px',
											marginTop: '0px',
											gap: '0',
										}}
									>
										<div className={styles.schema_drag_listing}>
											{!!targets.length &&
												targets.map((target) => (
													<div
														key={target.id || target.element}
														className={classes.addData}
													>
														<div className={classes.dataType}>
															{target.name}
														</div>
														<div className={classes.dataSize}>
															<CloudUploadOutlinedIcon fontSize="x-small" />
															225k
														</div>
														<div className={classes.dataManage}>
															<IconButton
																className={styles.schema_edit_options}
																onClick={() =>
																	handleOpenUpdateDialog(target, 'target')
																}
															>
																<Edit
																	sx={{
																		color: `${colors.darkGray} !important`,
																	}}
																/>
															</IconButton>
															<IconButton
																className={styles.schema_edit_options}
																onClick={() =>
																	handleOpenConfirmDialog(
																		'target',
																		target.id || target.element
																	)
																}
															>
																<Delete
																	className={styles.schema_edit_options}
																	sx={{
																		color: `red !important`,
																	}}
																/>
															</IconButton>
														</div>
													</div>
												))}
										</div>
										<Button
											variant="outlined"
											onClick={() => setOpenCreateDialog(true)}
											className={classes.addData}
											sx={{
												marginLeft: '10px !important',
												boxShadow: 'none !important',
												border: 'none',
												cursor: 'pointer',
												'&:hover': {
													boxShadow: 'none !important',
												},
											}}
										>
											+ Add Target
										</Button>{' '}
									</div>
								)}

								{
                  // Meta data
                }
								{dataSelector === 'meta-data' && (
									<div
										className={classes.addDataContainer}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											width: '100%',
											height: '100%',
											marginBottom: '0px',
											marginTop: '0px',
											gap: '0',
										}}
									>
										<div className={styles.schema_drag_listing}>
											{!!metadata.length &&
												metadata.map((meta) => (
													<div
														key={meta.id || meta.element}
														className={classes.addData}
													>
														<div className={classes.dataType}>
															{
                                // {elementTypeToIconMap[meta.type]('metadata')}{' '}
                                }
															{meta.name}
														</div>
														<div className={classes.dataSize}>
															<CloudUploadOutlinedIcon fontSize="x-small" />
															225k
														</div>
														<div className={classes.dataManage}>
															<IconButton
																className={styles.schema_edit_options}
																onClick={() =>
																	handleOpenUpdateDialog(meta, 'metadata')
																}
															>
																<Edit
																	sx={{
																		color: `${colors.darkGray} !important`,
																	}}
																/>
															</IconButton>
															<IconButton
																className={styles.schema_edit_options}
																onClick={() =>
																	handleOpenConfirmDialog(
																		'metadata',
																		meta.id || meta.element
																	)
																}
															>
																<Delete
																	sx={{
																		color: `red !important`,
																	}}
																/>
															</IconButton>
														</div>
													</div>
												))}
										</div>
										<Button
											variant="outlined"
											onClick={() => setOpenCreateDialog(true)}
											className={classes.addData}
											sx={{
												marginLeft: '10px !important',
												boxShadow: 'none !important',
												border: 'none',
												cursor: 'pointer',
												'&:hover': {
													boxShadow: 'none !important',
												},
											}}
										>
											+ Add Metadata
										</Button>{' '}
									</div>
								)}

								{
                  // Groups
                }
								{dataSelector === 'groups' && (
									<div
										className={classes.addDataContainer}
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											width: '100%',
											height: '100%',
											marginBottom: '0px',
											marginTop: '0px',
											gap: '0',
										}}
									>
										<div className={styles.schema_drag_listing}>
											{!!groups.length &&
												groups.map((group) => (
													<div
														key={group.id || group.element}
														className={classes.addData}
													>
														<div className={classes.dataType}>
															<Workspaces
																sx={{
																	fill: colors.taskGroups,
																	color: colors.taskGroups,
																}}
															/>{' '}
															{group.name}
														</div>
														<div className={classes.dataSize}>
															<CloudUploadOutlinedIcon fontSize="x-small" />
															225k
														</div>
														<div className={classes.dataManage}>
															<IconButton
																className={styles.schema_edit_options}
																onClick={() =>
																	handleOpenUpdateGroupDialog(group, 'group')
																}
															>
																<Edit
																	sx={{
																		color: `${colors.darkGray} !important`,
																	}}
																/>
															</IconButton>
															<IconButton
																className={styles.schema_edit_options}
																onClick={() =>
																	handleOpenConfirmDialog(
																		'group',
																		group.id || group.element
																	)
																}
															>
																<Delete
																	sx={{
																		color: `red !important`,
																	}}
																/>
															</IconButton>
														</div>
													</div>
												))}
										</div>
										<Button
											variant="outlined"
											onClick={() => setOpenCreateGroupDialog(true)}
											className={classes.addData + styles.remove_shadow}
											sx={{
												marginLeft: '10px !important',
												boxShadow: 'none !important',
												border: 'none',
												cursor: 'pointer',
												'&:hover': {
													boxShadow: 'none !important',
												},
											}}
										>
											+ Add Group
										</Button>{' '}
									</div>
								)}
							</div>
						</div>
					</CardItem>
				</Grid> */
