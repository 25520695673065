/* eslint-disable no-await-in-loop */
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import { ADD_ALERT, REMOVE_ALERT } from '../../../../redux/alerts.slice';
import { CREATE_TASK_FILE } from '../../../../redux/tasks.slice';
import { GET_EXAMPLE_FILE } from '../../../../redux/examples.slice';

export const parseCSVFile = async ({
	file,
	allColumns,
	schemaState,
	dispatch,
	setIsLoadingFile,
	setFileToUpload,
}) => {
	const parseFile = async (file) =>
		new Promise((resolve) => {
			Papa.parse(file, {
				header: true,
				delimiter: ',',
				newline: '\r\n',
				dynamicTyping: true,
				preview: 100,
				skipEmptyLines: true,
				complete: (results) => {
					if (results.errors.length > 0) {
						dispatch(
							ADD_ALERT({
								type: 'error',
								message: `Error parsing csv file: ${results.errors[0].message}`,
							})
						);
						setFileToUpload(null);
						setIsLoadingFile(false);
						return;
					}
					if (results.data.length === 0) {
						dispatch(
							ADD_ALERT({
								type: 'warning',
								message: 'No data found in csv file.',
							})
						);
						setTimeout(() => {
							dispatch(REMOVE_ALERT('No data found in csv file.'));
						}, 2000);
						setFileToUpload(null);
						setIsLoadingFile(false);
						return;
					}

					let headersMismatch = false;

					// allColumns.length - n (n = number of extra columns like comments column)

					const tmpColumns = allColumns.filter(
						(column) =>
							column.fieldType !== 'output' && column.fieldType !== 'airelease'
					);
					if (Object.keys(results.data[0]).length !== tmpColumns.length) {
						headersMismatch = true;
					}

					if (headersMismatch) {
						dispatch(
							ADD_ALERT({
								type: 'error',
								message: 'Headers do not match schema.',
							})
						);
						setFileToUpload(null);
						setIsLoadingFile(false);
						return;
					}

					const headers = {};
					Object.keys(results.data[0]).forEach((key) => {
						headers[key] = key;
					});

					resolve(
						results.data.map((data) => {
							let tmp = {};
							Object.keys(data).forEach((key) => {
								if (
									data[key] !== '' &&
									data[key] !== null &&
									data[key] !== undefined
								) {
									// INPUTS
									if (
										schemaState &&
										schemaState.inputs &&
										schemaState.inputs.length > 0 &&
										schemaState.inputs.find((input) => input.name === key)
									) {
										schemaState.inputs.forEach((input) => {
											if (input.name === key) {
												if (input.type === 'float') {
													tmp = {
														...tmp,
														[key]:
															// todo
															// Number(data[key].replace(',', '.')) ||
															Number(data[key]),
													};
												} else if (
													(input.type === 'text' ||
														input.type === 'category') &&
													typeof data[key] === 'boolean'
												) {
													tmp = { ...tmp, [key]: String(data[key]) };
												} else tmp = { ...tmp, [key]: data[key] };
											}
										});
									}

									// METADATA
									if (
										schemaState &&
										schemaState.metadata &&
										schemaState.metadata.length > 0 &&
										schemaState.metadata.find((meta) => meta.name === key)
									) {
										schemaState.metadata.forEach((meta) => {
											if (meta.name === key) {
												if (meta.type === 'float') {
													tmp = {
														...tmp,
														[key]: Number(data[key].replace(',', '.')),
													};
												} else if (
													(meta.type === 'text' || meta.type === 'category') &&
													typeof data[key] === 'boolean'
												) {
													tmp = { ...tmp, [key]: String(data[key]) };
												} else tmp = { ...tmp, [key]: data[key] };
											}
										});
									}

									// OUTPUTS
									if (
										schemaState &&
										schemaState.outputs &&
										schemaState.outputs.length > 0 &&
										schemaState.outputs.find((output) => output.name === key)
									) {
										schemaState.outputs.forEach((output) => {
											if (output.name === key) {
												if (output.type === 'float') {
													tmp = {
														...tmp,
														[key]: Number(data[key].replace(',', '.')),
													};
												} else if (
													(output.type === 'text' ||
														output.type === 'category') &&
													typeof data[key] === 'boolean'
												) {
													tmp = { ...tmp, [key]: String(data[key]) };
												} else tmp = { ...tmp, [key]: data[key] };
											}
										});
									}
								}
							});
							return tmp;
						})
					);
				},
			});
		});

	const parsedData = await parseFile(file);

	return parsedData;
};

export const createCSVTemplate = (allColumns) => {
	const tmpHeaders = [];
	allColumns.forEach((column) => {
		if (
			column.field !== 'edit' &&
			column.field !== 'comments' &&
			column.field !== 'airelease' &&
			column.fieldType !== 'output'
		) {
			tmpHeaders.push({
				...tmpHeaders[0],
				key: column.field,
				label: column.field,
			});
		}
	});

	const csvReport = {
		headers: tmpHeaders,
		filename: 'CSV Template.csv',
	};

	return csvReport;
};

const getFilePickerOptions = (fileType) => {
	switch (fileType) {
		case 'image_file':
			return {
				types: [
					{
						accept: {
							'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
						},
					},
				],
				excludeAcceptAllOption: true,
				multiple: false,
			};
		case 'document_file':
			return {
				types: [
					{
						accept: {
							'application/*': ['.pdf'],
						},
					},
				],
				excludeAcceptAllOption: true,
				multiple: false,
			};
		case 'audio_file':
			return {
				types: [
					{
						accept: {
							'audio/*': ['.mp3', '.wav'],
						},
					},
				],
				excludeAcceptAllOption: true,
				multiple: false,
			};
		case 'video_file':
			return {
				types: [
					{
						accept: {
							'video/*': ['.avi', '.mp4', '.mpeg'],
						},
					},
				],
				excludeAcceptAllOption: true,
				multiple: false,
			};
		case 'generic_file':
			return;
		// todo: need to be defined
		// {
		//   types: [
		//     {
		//       accept: {
		//         'application/*': ['.pdf'],
		//       },
		//     },
		//   ],
		//   excludeAcceptAllOption: true,
		//   multiple: false,
		// };
		default:
			return null;
	}
};

async function requestFileSystemAccess() {
	try {
		const handle = await window.showDirectoryPicker();
		return handle;
	} catch (error) {
		console.error('Error requesting file system access:', error);
	}
}

async function matchAndRetrieveFile({ file, directoryHandle, rowNum, errors }) {
	try {
		const fileHandle = await directoryHandle.getFileHandle(file, {
			create: false,
		});
		return fileHandle.getFile();
	} catch (error) {
		// https://developer.mozilla.org/en-US/docs/Web/API/DOMError errors list
		switch (error.name) {
			case 'NotFoundError':
				errors.push(
					`At example ${
						rowNum + 1
					}: File ${file} not found (check if file name, extension or route are correct).`
				);
				break;
			default:
				break;
		}
	}
}

const matchAndRetrieveSubfolder = async ({
	file,
	directoryHandle,
	rowNum,
	errors,
}) => {
	const subFolders = file.split('/');

	if (subFolders.length > 1) {
		const subFolderName = subFolders.shift();
		for await (const entry of directoryHandle.values()) {
			if (entry.kind === 'directory' && entry.name === subFolderName) {
				return matchAndRetrieveSubfolder({
					file: subFolders.join('/'),
					directoryHandle: entry,
					rowNum,
					errors,
				});
			}
		}
	} else {
		return matchAndRetrieveFile({
			file,
			directoryHandle,
			rowNum,
			errors,
		});
	}
};

export const getFilesFromParsedCSV = async (
	parsedData,
	schemaState,
	dispatch,
	currentTaskState,
	accessToken,
	setUploadStatus
) => {
	const errors = [];
	const modifiedRows = [];
	let directoryHandle;

	// Check if schema have type file elements
	const checkFilesInInputs = schemaState.inputs
		.map((element) => element.type.split('_')[1] === 'file')
		.includes(true);
	const checkFilesInOutputs = schemaState.outputs
		.map((element) => element.type.split('_')[1] === 'file')
		.includes(true);
	// console.log(isFileTypeInSchema)
	const isFileTypeInSchema = checkFilesInInputs || checkFilesInOutputs;
	if (isFileTypeInSchema) directoryHandle = await requestFileSystemAccess();

	setUploadStatus(`Getting examples info`);
	await Promise.all(
		parsedData.map(async (row, rowNum) => {
			let modifiedRow = row;
			await Promise.all(
				Object.keys(modifiedRow.values).map(async (key) => {
					// Initializers and resets
					let responseData = {};
					let tmpFile = null;
					const columnName = modifiedRow.values[key].element;
					const cellValue = modifiedRow.values[key].value;
					let usedFor = null;

					// Select usedFor (input, metadata, output)
					if (
						schemaState &&
						schemaState.inputs &&
						schemaState.inputs.length > 0 &&
						schemaState.inputs.find((element) => element.name === columnName)
					) {
						usedFor = 'input';
					}
					if (
						schemaState &&
						schemaState.metadata &&
						schemaState.metadata.length > 0 &&
						schemaState.metadata.find((element) => element.name === columnName)
					) {
						usedFor = 'metadata';
					}
					if (
						schemaState &&
						schemaState.outputs &&
						schemaState.outputs.length > 0 &&
						schemaState.outputs.find((element) => element.name === columnName)
					) {
						usedFor = 'output';
					}

					// Get schema element
					const schemaElement = schemaState.inputs.find(
						(element) => element.name === columnName
					);

					// Check if is a file
					if (
						schemaElement &&
						(schemaElement.type === 'generic_file' ||
							schemaElement.type === 'document_file' ||
							schemaElement.type === 'image_file' ||
							schemaElement.type === 'video_file' ||
							schemaElement.type === 'audio_file')
					) {
						// Match file name in file directory
						if (!cellValue.includes('/')) {
							try {
								tmpFile = await matchAndRetrieveFile({
									file: cellValue,
									directoryHandle,
									rowNum,
									errors,
								});
							} catch (error) {
								console.log(error);
								return;
							}
						} else {
							try {
								tmpFile = await matchAndRetrieveSubfolder({
									file: cellValue,
									directoryHandle,
									rowNum,
									errors,
								});
							} catch (error) {
								console.log(error);
								return;
							}
						}

						let res = null;

						if (tmpFile && !tmpFile.errors) {
							try {
								res = await dispatch(
									CREATE_TASK_FILE({
										taskId: currentTaskState.id,
										file: tmpFile,
										accessToken,
										dispatch,
										usedFor,
										fileType: schemaElement.type.split('_')[0],
									})
								);

								if (res && res.payload && res.payload.id) {
									responseData = {
										...modifiedRow,
										values: modifiedRow.values.map((val) => {
											if (val.element === columnName)
												return {
													element: columnName,
													value: res.payload.id,
												};
											return val;
										}),
									};
									// Modify the cell value based on the response
									modifiedRow = responseData;
								}
							} catch (error) {
								console.log(error);
							}
						}
					}
				})
			);
			modifiedRows.push(modifiedRow);
		})
	);
	if (errors.length > 0) return { errors };
	return modifiedRows;
};

// export const getFilesFromParsedCSV = async (
// 	parsedData,
// 	schemaState,
// 	dispatch,
// 	currentTaskState,
// 	accessToken
// ) => {
// 	const modifiedRows = [];

// 	await Promise.all(
// 		parsedData.map(async (row, rowNum) => {
// 			let modifiedRow = { ...row };
// 			let responseData = {};
// 			// Use async forEach for each key in the row
// 			await Promise.all(
// 				Object.keys(modifiedRow.values).map(async (key) => {
// 					// Initializers and resets
// 					// const cell = modifiedRow.values[key];
// 					// const cellValue = modifiedRow.values[key].value;
// 					let tmpFile = null;
// 					const columnName = modifiedRow.values[key].element;
// 					let usedFor = null;

// 					// Select usedFor (input, metadata, output)
// 					if (
// 						schemaState &&
// 						schemaState.inputs &&
// 						schemaState.inputs.length > 0
// 					) {
// 						usedFor = 'input';
// 					}
// 					if (
// 						schemaState &&
// 						schemaState.metadata &&
// 						schemaState.metadata.length > 0
// 					) {
// 						usedFor = 'metadata';
// 					}
// 					if (
// 						schemaState &&
// 						schemaState.outputs &&
// 						schemaState.outputs.length > 0
// 					) {
// 						usedFor = 'output';
// 					}

// 					// Get schema element
// 					const schemaElement = schemaState.inputs.find(
// 						(element) => element.name === columnName
// 					);

// 					// Check if is a file
// 					if (
// 						schemaElement &&
// 						(schemaElement.type === 'generic_file' ||
// 							schemaElement.type === 'document_file' ||
// 							schemaElement.type === 'image_file' ||
// 							schemaElement.type === 'video_file' ||
// 							schemaElement.type === 'audio_file')
// 					) {
// 						// Set file picker options
// 						const pickerOpts = getFilePickerOptions(schemaElement.type);

// 						try {
// 							// Open file picker and get selected file
// 							const [fileHandle] = await window.showOpenFilePicker(pickerOpts);
// 							tmpFile = await fileHandle.getFile();
// 						} catch (error) {
// 							console.log(error);
// 						}

// 						let res = null;

// 						try {
// 							res = await dispatch(
// 								CREATE_TASK_FILE({
// 									taskId: currentTaskState.id,
// 									file: tmpFile,
// 									accessToken,
// 									dispatch,
// 									usedFor,
// 									fileType: schemaElement.type.split('_')[0],
// 								})
// 							);

// 							if (res)
// 								responseData = {
// 									...modifiedRow,
// 									values: modifiedRow.values.map((val) => {
// 										if (val.element === columnName)
// 											return {
// 												element: columnName,
// 												value: res.payload.id,
// 											};
// 										return val;
// 									}),
// 								};
// 						} catch (error) {
// 							console.log(error);
// 						}
// 					}

// 					// Modify the cell value based on the response
// 					modifiedRow = responseData;
// 				})
// 			);

// 			modifiedRows.push(modifiedRow);
// 		})
// 	);
// 	return modifiedRows;
// };

// const isValidHttpUrl = (string) => {
// 	try {
// 		const url = new URL(string);
// 		return url.protocol === 'http:' || url.protocol === 'https:';
// 	} catch (err) {
// 		return false;
// 	}
// };

// const url2blob = async (url) => {
// 	let file = null;

// 	// todo: pending to confirm. The problem is that CORS block almost all the external URLs cause the servers not allow it. If a customer configure his servers to allow CORS, they can place urls and work withouth any problem.
// 	try {
// 		await fetch(url, {
// 			method: 'GET',
// 		})
// 			.then((res) => res.blob())
// 			.then((blob) => {
// 				console.log(blob);
// 				const fileType = url.split('.').slice(-1)[0];

// 				switch (fileType) {
// 					case 'jpg':
// 					case 'jpeg':
// 					case 'webp':
// 					case 'png':
// 						file = new File([blob], uuidv4(), {
// 							type: `image/${fileType}`,
// 						});
// 						break;
// 					default:
// 						return null;
// 				}
// 			});

// 		// console.log(headers);

// 		// Object.defineProperty(file, 'size', { value: fileSize });
// 		return file;
// 	} catch (err) {
// 		console.error(err.name, err.message);
// 	}
// };

// function base64ToBlob(base64Data, contentType = 'image/jpeg') {
// 	const binaryString = window.atob(base64Data);
// 	const bytes = new Uint8Array(binaryString.length);
// 	for (let i = 0; i < binaryString.length; i += 1) {
// 		bytes[i] = binaryString.charCodeAt(i);
// 	}
// 	return new Blob([bytes], { type: contentType });
// }

// function createFileFromBase64(base64Data, fileName, contentType) {
// 	const blob = base64ToBlob(base64Data, contentType);
// 	return new File([blob], fileName, { type: contentType });
// }

// async function loadImageAndConvertToBase64(url) {
// 	return new Promise((resolve, reject) => {
// 		const image = new Image();
// 		image.src = url;

// 		image.onload = () => {
// 			const canvas = document.createElement('canvas');
// 			canvas.width = image.width;
// 			canvas.height = image.height;

// 			const context = canvas.getContext('2d');
// 			context.drawImage(image, 0, 0);

// 			const base64Image = canvas.toDataURL('image/jpeg'); // or "image/png" for PNG images
// 			resolve(base64Image);
// 		};
// 		image.onerror = (error) => {
// 			reject(error);
// 		};
// 	});
// }

// async function url2File(url) {
// 	window.open(url);
// 	try {
// 		const base64Image = await loadImageAndConvertToBase64(url);
// 		console.log('Base64 image:', base64Image);
// 	} catch (error) {
// 		console.error('Error loading image:', error);
// 	}
// }

// const url2File = async (url) => {
// 	const fileType = url.split('.').slice(-1)[0].replace('"', '');
// 	const fileName = uuidv4();
// 	console.log(fileName);
// 	let file = null;

// 	await fetch(url, {
// 		method: 'GET',
// 	})
// 		.then((res) => res.blob())
// 		.then((blob) => {
// 			switch (fileType) {
// 				case 'jpg':
// 				case 'jpeg':
// 				case 'webp':
// 				case 'png':
// 					file = new File([blob], fileName, {
// 						type: `image/${fileType}`,
// 					});
// 					break;
// 				default:
// 					return null;
// 			}
// 		});
// 	console.log(file);
// 	return file;
// };
