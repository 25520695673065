import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import requestFactory from '../services/request.factory';
import { ADD_ALERT, REMOVE_ALERT } from './alerts.slice';
import { TEST_CREATED } from '../AlertsList/testsAlerts';

// GET

export const GET_PREDICTIONS = createAsyncThunk(
	'user/GET_PREDICTIONS',
	async (props) => {
		console.log('user/GET_PREDICTIONS');
		const tmpProps = [];
		let isPage = false;
		let isPerPage = false;
		let isAIModel = false;
		const { dispatch, taskId, accessToken } = props;

		Object.keys(props).forEach((prop) => {
			if (
				(prop !== '' || prop !== undefined || prop !== null) &&
				prop !== 'taskId' &&
				prop !== 'accessToken' &&
				prop !== 'dispatch' &&
				props[prop] !== '' &&
				props[prop] !== false
			) {
				if (prop === 'page') isPage = true;
				if (prop === 'per_page') isPerPage = true;
				if (prop === 'ai_model') isAIModel = true;
				tmpProps.push({ [prop]: props[prop] });
			}
		});

		// Create the query based on the array and set the default options
		let query = '?';
		// Set the default options
		if (isPage)
			query += `page=${tmpProps.find((prop) => prop['page'])['page']}`;
		else query += 'page=1';
		if (isPerPage)
			query += `&per_page=${
				tmpProps.find((prop) => prop['per_page'])['per_page']
			}`;
		if (isAIModel)
			query += `&ai_model=${
				tmpProps.find((prop) => prop['ai_model'])['ai_model']
			}`;
		else query += '&per_page=25';
		query += '&total_count=true';

		// Create the query
		tmpProps.forEach((prop) => {
			const propName = Object.keys(prop)[0];
			const propValue = prop[propName];
			if (
				propName !== 'page' &&
				propName !== 'per_page' &&
				propName !== 'total_count' &&
				propName !== 'query'
			)
				query += `&${propName}=${propValue}`;
			if (propName === 'query') query += `&${propValue}`;
		});

		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/prediction-logs${query}`,
			accessToken,
			dispatch,
		});

		return res;
	}
);

// POST

export const CREATE_PREDICTION = createAsyncThunk(
	'user/CREATE_PREDICTION',
	async ({
		taskId,
		newPrediction,
		accessToken,
		dispatch,
		formatedPrediction,
	}) => {
		console.log('user/CREATE_PREDICTION');

		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/predict`,
			accessToken,
			dispatch,
			data: newPrediction,
		});

		if (res) {
			dispatch(ADD_ALERT({ type: 'success', message: TEST_CREATED }));
			setTimeout(() => {
				dispatch(REMOVE_ALERT(TEST_CREATED));
			}, 2000);

			console.log(
				formatedPrediction.map((prediction, index) => ({
					...prediction,
					outputs: res.predictions.outputs[index],
				}))
			);

			return formatedPrediction.map((prediction, index) => ({
				...prediction,
				outputs: res.predictions.outputs[index],
			}));

			// return {
			// 	outputs: res.predictions.outputs,
			// 	...formatedPrediction,
			// };
		}
	}
);

export const predictionSlice = createSlice({
	name: 'predictions',
	initialState: {
		isLoading: false,
		currentPrediction: {},
		predictions: [
			// {
			// 	ai_model: 'UnEtekl1R3Y',
			// 	created_at: '2024-02-02T12:25:35Z',
			// 	created_by: {
			// 		id: 'SHdjLVJ2cWk',
			// 		type: 'client',
			// 		uuid: '9246907b-7af9-43f9-ba98-58313e83894c',
			// 	},
			// 	environment: 'testing',
			// 	id: 'YmxnLWRhTFo',
			// 	inputs: [
			// 		{
			// 			element: 'Int',
			// 			value: [
			// 				0.31, 0.3, 1.12, 1.3, 0.39, 0.19, 0.9, 0.65, 0.1, 0.35, 0.98,
			// 				1.07, 1.15, 0.41, -0.15, 1.29, 1.29, -0.39, 0.68, 1.5, -1.71,
			// 				0.94, 0.83, -0.44, 0.1, -0.12, 0.66, 3.31, -0.83, -2.02, 2.2,
			// 				2.55, 2.86, 1.12, 0.6, 0.62, 0.54, -0.26, 0.27, 1.69, 0.32, -3.19,
			// 				15.89, 0.76, 0.64, -0.31, -0.59, -1.12, 3.87, 3.54, -0.78, -0.94,
			// 				-1.02, 2.92, 5.67, -4.8, 1.27, 3.22, 2.53, 0.86, 0.42, 0.39, 0.55,
			// 				0.82, 1.08, 0.1, -0.45, 2.85, -0.26, -0.49, 1.25, 1.11, 0.35,
			// 				0.45, 1.35, 0.59, 0.2, 2.4, -0.86, -3.46, 3.1, 0.77, -2.25, 6.05,
			// 				2.17, -1.55, 3.44, 2.62, 1, -0.07, 0, 0.33, 1.37, 0.06, -0.74,
			// 				3.02, 1.02, -0.3, 0.01, 0.94, 0.52, 2.23, 0.24, -0.16, 1.41, 0.95,
			// 				-0.15, 0.3, 2.35, -0.02, -0.2, 1.56, -1.39, -3.84, 0.63, -0.34,
			// 				-0.8, 3.24, 2.11, -1.51, -0.77, 1.45, 1.14, 0.94, 0.77, 0.84,
			// 				1.23, 0.94, -0.02, -0.03, 0.95, -0.04, -0.04, 0.82, -0.06, 1.19,
			// 				0.86, 0.04, 0.26, 1.79, 0.41, 0.58, 5.61, 3.13, -2.05, 0.33, 1.74,
			// 				1.37, -0.07, 0.76, 0.9, -1.8, -1.37, 1.51, 0.01, 0.12, -0.09,
			// 				-0.64, -0.29, -2.67, 6.86, 0.98, 1.17, 0.54, -0.17, -0.05, 0.42,
			// 				0.4, 0.88, 0.75, 0.06, -0.02, 0.16, 0.54, 0.73, 0.29, 0.27, -0.19,
			// 				0.03, 2.17, 4.48, -2.36, 1.31, 2.75, 1.99, 0.93, 0.56, 0.69, 0.72,
			// 				0.67, 0.81, 0.45, 0.2, 0.28, 0.12, -0.44, -0.08, 3.01, 6.29,
			// 				-4.42, 0.46, 3.15, 2.92, 1.4, 0.56, 0.57, 0.48, 0.76, 0.64, 0.24,
			// 				0.23, 0.29, 0.03, -0.18, 0.61, 3.52, -3.58, -1.46, 3.46, 3.92,
			// 				0.47, -3.32, 5.12, 1.77, -2.41, -0.18, 2.39, 1.71, 0.84, 0.56,
			// 				0.57, 1.6, 1.01, -0.8, 1.66, 0.95, -0.54, 1.34, 0.65, -0.6, -0.65,
			// 				-0.08, -1.23, 1.02, 0.49, -0.28, -0.85, -0.39, 3.74, -2.14, -1.07,
			// 				3.3, 3.85, 0.9, -1.06, 2.1, 7.96, 0.27, -1.74, -1.08, 6.68, -0.7,
			// 				-1.13, 4.12, 2.87, 0.74, 0.05, 0.14, 0.58, 1.59, -0.35, 0.57,
			// 				1.43, -1, 1.95, 0.9, -0.64, 2.55, -1.32, 15.99, 0.42, 0.37, -0.06,
			// 				-0.02, 0.21, 0.14, 1.34, -5.5, -1.6, 1.5, 0.11, -0.1, -0.28, 1.11,
			// 				0.78, -0.17, -0.29, 0.7, -0.33, 1.06, 1.04, -0.28, -0.15, 0.83,
			// 				0.06, 1.67, 2.64, 1.14, -0.98, 0.32, 0.8, 0.75, 0.02, 0.38, 0.05,
			// 				-1.07, -8.38, 0.94, 0.85, 0.98, 0.52, 0.14, 0.55, 0.55, 0.06,
			// 				-0.56, 0.69, 0.82, 0.03, 0.02, 0.81, 0.71, 0.31, 0.29, 0.58, 0.53,
			// 				-0.04, 3.27, 4.77, -3.8, 1.34, 2.27, 2.33, 0.91, 0.78, 0.62, 0.86,
			// 				0.61, 0.53, 0.1, 0.11, 0.26, 0.42, 2.01, -0.15, -0.03, 2.26,
			// 				-0.37, 0.07, 2.06, -1.16, -12.71, -0.26, -0.89, -0.25, 0.08, 0.55,
			// 				0.06, 0.37, 0.41, 1.32, 0.39, -1.44, 3.48, -0.24, -0.71, 1.62,
			// 				1.26, 1.9, 0.77, 0.39, 0.25, 1.04, 0.97, -0.11, 0.17, 0.52, 1.74,
			// 				1.17, -0.34, 1.09, 1.11, -0.1, 0.65, 0.54, 0.84, 0.62, -0.04,
			// 				1.07, -0.11, 2.44, 0.92, -1.22, 2.68, -0.48, -5.61, 1.66, 0.92,
			// 				-0.13, -0.35, 0.6, 0.16, -0.07, 0.11, 0.2, -0.63, 0.39, 4.36,
			// 				-5.24, 1, 3.78, 2.73, 0.72, 3.22, 2.35, -5.3, 3.19, 1.64, 2.93,
			// 				1.21, 0.85, 0.39, 0.79, 0.71, 0.63, 0.69, 0.12, -0.19, 0.19,
			// 				-0.25, -0.74, -3.65, -1.96, -0.68, 1.27, 0.55, 0.03, 0.29, 0.37,
			// 				0.1, 0.46, 1.17, 0.25, -0.05, 0.11, 0.97, 0.57, 0.15, 1.67, 0.16,
			// 				0.44, -0.24, -0.74, 1.48, 4.73, -5.04, 0.33, 4.27, 2.8, 0.66,
			// 				0.18, 0.34, 0.54, 1.29, 0.47, 0.17, 0.16, 0.22, 0.78, 0.37, 0.39,
			// 				0.17, -0.17, -0.1, 0.84, 1.36, 0.35, 0.02, -0.3, -0.06, 1.63,
			// 				4.16, -5.29, -0.53, 2.86, 3.37, 1.29, -0.36, 1.44, 3.45, 4.88,
			// 				-0.06, -1.69, -0.11, 4.4, 4.91, -4.71, 2.85, 3.21, 1.94, 0.49,
			// 				0.02, 0.21, 0.63, 0.63, 0.19, 0.31, 1.11, 0.95, 0.15, 0.85, 0.95,
			// 				-0.65, 0.64, 1.72, -0.15, -0.17, 0.69, 0.24, 0.79, 1.58, 0.02,
			// 				0.07, 1.29, 1.27, 0.01, 1.03, 0.96, 0.21, 0.16, 3.34, -1.69,
			// 				-7.09, 3.41, 0.27, -1.49, 2.17, 1.43, 0.76, -0.01, 0.16, 1.49,
			// 				-0.93, -6.92, -1.1, -0.29, -0.55, -0.38, 0.6, 0.2, 0.09, 0.24,
			// 				0.19, 1.45, 0.65, -0.06, 0.17, 1.04, 0.49, 0.31, -0.16, -0.53,
			// 				0.53, 2.88, 1.55, -1.51, 4.06, 2.66, 0.97, 0.2, 0.15, 0.46, 1.16,
			// 				0.92, -0.21, -0.28, 2.16, 0.37, -0.04, 0.16, 0.74, 0.84, 0.82,
			// 				0.07, -1.08, 1.38, 5.1, 1.42, 0.47, 1.23, 2.57, 1.2, 0.21, 0.53,
			// 				1.08, 0.77, -0.06, 1.72, 0.91, -0.41, 1.73, 0.53, -0.43, 0.28,
			// 				0.92, -2.08, 3.92, 4.98, -3.31, 2.32, 3.1, 1.91, 0.69, 0.03, 0.47,
			// 				0.45, 0.5, 0.02, 0.52, 1.24, 1.07, -0.04, -0.37, 2.18, 1.02, 0.23,
			// 				0.06, 0.66, 1.05, 0.39, 0.27, 0.61, 2.17, 0.55, -0.37, 0.72, 2.06,
			// 				0.37, -0.11, 2.66, -1.22, -2.98, -1.36, 2.19, 0.21, -0.29, -0.23,
			// 				0.92, 0.98, 0.25, -0.15, -0.01, 0.6, 0.35, 0.33, 0.51, 0.7, 0.78,
			// 				0.5, 0.71, 0.17, 0.44, 0.52, 0.54, 0.75, 1.17, 1.24, 0.88, -0.43,
			// 				-0.12, 1.59, -0.03, 0, 0.17, 0.64, 0.5, 0.34, 0.45, 0.17, 0.08,
			// 				0.42, 1.45, 0.99, -0.15, 1.1, 0.18, 0.2, -0.34, 0.17, 4.58, 2.97,
			// 				-2.14, 1.36, 2.19, 2.12, 0.94, 0.42, 0.54, 0.64, 0.4, 0.69, 0.21,
			// 				0.07, 0.71, 1.5, -1.07, 0.09, 1.48, 2.19, 2.56, 1.28, 0.58, 0.51,
			// 				0.56, 0.35, -0.3, 1.67, 5.6, -5.43, 0.58, 1.25, 2.54, 1.02, 0.57,
			// 				0.33, 0.55, 0.42, 1.15, -0.47, 0.79, 0.88, -0.05, 0.27, 0.29,
			// 				0.09, 2.23, 0.74, 0, 0.54, 0.72, 1.16, 1.14, 0.27, 0.06, 0.94,
			// 				0.19, -1.19, -1.38, 3.24, -0.12, -0.74, 1.04, 1.15, 0.76, 0.2,
			// 				0.3, -0.41, -0.25, 4.3, -0.03, -1.5, 3.8, 3.19, 1.53, 0.29, 0.14,
			// 				0.8, 1.41, 0.11, 0.49, 0.81, 0.63, 0.02, 3.22, 5.86, -3.19, 2.65,
			// 				3.09, 1.56, 0.32, 0.42, 0.64, 0.87, 0.87, 0.35, 0.16, 1.84, 0.45,
			// 				-0.85, 3.2, 0.76, -0.67, 2.87, -1.68, -4.79, -0.23, -0.25, -0.12,
			// 				0.02, 0.31, -0.66, -0.31, 4.92, 1.52, -1.94, 1.67, 2.57, 1.73,
			// 				0.65, 0.56, 0.64, 0.61, 0.56, 0.35, 0.05, 0.51, 0.88, 0.69, 0.27,
			// 				0.24, 0.03, 0.68, 1.22, 0.93, 0.61, 0.36, -0.06, 0.47, -0.02,
			// 				0.09, 1.47, 5.32, -6.15, 0.95, 2.08, 2.38, 1.94, 2.9, 3.02, -6.06,
			// 				1.25, 0.55, 1.15, 1.28, 0.49, 0.37, 0.7, 0.52, 1.67, 0.34, -0.08,
			// 				1.54, 0.85, -0.88, -2.71, 0.26, -0.31, -0.43, 2.91, -0.62, -3,
			// 				3.06, 2.41, 3.39, 2.11, 1.13, 1.04, 0.62, 0.29, 1.13, -0.37, 0.23,
			// 				1.54, 0.37, -1.11, 2.52, 2.18, 0.7, -1.36, 1.29, 0.57, 0.05, 0.17,
			// 				1.45, -4.95, 4.91, 1.85, 0.51, -0.37, 0.37, -0.06, -0.24, -0.13,
			// 				0.27, 3.12, 1.48, 1.28, 0.85, 0.32, 0.24, 0.66, 0.9, 1, 0.73,
			// 				0.27, 0.42,
			// 			],
			// 		},
			// 		{
			// 			element: 'float',
			// 			value: [
			// 				-0.46, -0.31, -1.33, -1.19, -0.3, -0.19, -1.26, -1.01, -0.19,
			// 				-0.25, -1.04, -0.88, -0.65, -0.54, -0.3, 0.07, -0.74, 0.15, -1.15,
			// 				-2.76, -5.67, 0.67, -1, -0.97, -1.08, -0.75, -0.97, 0, 2.19, 3.63,
			// 				5.4, 3.17, -0.42, 1.06, 0.39, 0.09, -0.16, -0.44, -0.47, 0.33,
			// 				-0.24, -1.82, -13.72, 0.16, -0.57, -1.12, -0.66, 0.62, 1.26,
			// 				-1.31, -0.24, -0.6, -2.27, 2.13, 6.87, 5.35, 7.29, 3.75, -0.18,
			// 				-0.48, 0.1, -0.01, -0.38, -0.62, -1.29, -0.86, -0.51, 0.3, -1.56,
			// 				-0.21, 0.18, -1.3, -0.93, 0.14, -0.62, -0.27, 0.34, -2.11, -6.82,
			// 				12.65, 0.53, -1.92, -4.06, 0.43, 5.79, 4.27, 5.61, -0.41, -0.48,
			// 				-0.2, -0.01, -0.79, -1.46, -0.73, -0.38, 0.52, -1.43, -0.49, 0.18,
			// 				-0.64, -1.51, 0.09, -0.71, -0.24, -0.73, -1.37, -0.71, -0.06,
			// 				-0.26, -0.14, -1.14, -3.34, -9.02, 1.71, 0.93, -0.12, 1.22, -1.23,
			// 				-1.15, 0.36, -0.29, -1.31, -1.04, -0.54, -0.43, -0.6, -0.82,
			// 				-0.46, -0.52, -0.39, -1.02, -1.06, -0.42, -1.17, -0.89, -0.16,
			// 				-0.91, -0.46, -0.57, -1.87, -0.18, 2.25, -0.6, 0.53, -1.02, -2.05,
			// 				-0.15, -0.37, -0.1, -0.82, -2.9, -7.76, 1.68, 1.19, -1.17, -0.77,
			// 				-1.79, 1.11, 1.63, -0.04, -1.14, 2.25, 0.35, -0.5, -0.72, -0.99,
			// 				-1.35, -1.17, -0.59, -0.89, -0.94, -0.18, -0.54, -1.13, -1.13,
			// 				-0.52, -0.38, -1.43, -1.25, 3.03, 6.46, 4.44, 7.56, 2.17, 0.3,
			// 				-0.15, 0.23, 0.23, -0.13, -0.59, -1.19, -0.86, -0.47, -0.65,
			// 				-0.68, -0.75, -0.99, 1.68, 6.6, 4.2, 5.74, 4.6, 0.87, -0.31,
			// 				-0.31, -0.01, -0.47, -0.97, -1.04, -0.42, -0.48, -0.8, -0.86,
			// 				-1.04, -0.18, 3.24, 5.63, 4.93, 5.76, -0.47, 0.22, 2.09, 5.52,
			// 				-0.79, -0.77, 0.23, -0.63, -0.41, -0.35, -0.38, -0.65, -0.48,
			// 				-0.53, -0.44, 1.15, -1.07, -0.38, -1.83, -3.66, -3.28, 0.67, 0.26,
			// 				-0.83, -0.58, -1.29, -0.98, -1.13, -1.5, 3.96, 6.69, 6.16, 4.29,
			// 				0.17, -0.41, -0.06, 4.31, -1.07, -1.85, 0.16, -1.69, 1.09, 5.18,
			// 				3.44, 4.19, -0.67, -0.4, -0.03, -0.2, -0.85, -1.21, -0.75, -0.48,
			// 				-1.17, -0.77, 1.15, -1.01, 0.12, -2.1, -5.79, 16, 2.71, -3.24,
			// 				-0.02, -1.26, -1.31, -0.49, -0.01, -0.48, -1.03, 0.28, -0.59,
			// 				-0.61, -0.73, -1.39, -1.19, -0.6, -0.34, -1.42, -0.84, 0.02,
			// 				-1.51, -0.94, -0.43, -1.77, -0.77, -0.01, -0.71, -0.76, -0.64,
			// 				-1.93, -0.6, -0.53, -0.95, -1.28, 1.07, 1.48, -3.23, -0.93, 1.98,
			// 				0.15, -0.35, -0.77, -1.49, -1.5, -0.7, -0.01, -0.57, -1.6, -0.69,
			// 				-0.05, -0.39, -1.78, -0.66, -0.2, -0.95, -1.08, -1.48, 2.78, 6.45,
			// 				4.12, 8.35, 2.67, 0.44, -0.45, -0.16, -0.04, -0.36, -0.71, -1.19,
			// 				-0.63, -0.21, -0.54, -1.3, -0.81, -0.59, 0.06, -0.27, -0.75,
			// 				-1.04, -1.92, -8.27, 5.55, 2.13, -0.01, -0.31, -1.47, -1.97,
			// 				-0.56, -0.02, -0.88, -1.43, -0.72, -0.7, 0.22, -1.78, 0.69, -0.67,
			// 				-1.74, -1.19, 0.07, 0.15, -0.7, -1.53, -1.76, -0.5, 0.33, -0.19,
			// 				-1.97, -0.89, -0.21, -0.1, -1.44, -0.91, -0.04, -1.32, -0.44,
			// 				-0.73, -1.17, -1.19, -0.03, 0.42, -1.85, -0.46, 0.27, -3.87, -1,
			// 				0.1, -0.49, -0.58, -0.91, -1.37, -1.23, -0.49, -0.63, -0.88,
			// 				-1.25, -1.26, 3.36, 4.18, 6.26, 3.47, -0.08, -1.98, -0.05, 2.98,
			// 				3.12, 10.66, 5.2, 1.2, 2.36, 1.75, 1.04, 1.94, 0.03, -1.09, -0.71,
			// 				-0.56, -0.6, 0.5, 0.29, -1.31, -4.82, -1.11, 2.03, -0.1, -0.39,
			// 				-0.47, -1.8, -2.03, -0.37, 0.11, -1.7, -1.02, -0.13, -0.05, -1.85,
			// 				-1.23, -0.44, -0.61, -1.14, -0.2, -0.8, -0.95, 0.34, 4.77, 5.02,
			// 				4.81, 3.75, -0.41, -0.53, -0.1, -0.25, -0.53, -1.2, -1.06, -0.31,
			// 				-0.45, -0.96, -0.8, -0.64, -0.71, -0.68, -0.36, -0.35, -0.99,
			// 				-1.3, -0.51, -0.3, -0.82, -1.15, 0.82, 5.26, 9.22, 7.3, 4.64,
			// 				0.47, -0.39, 0.16, 3.59, -0.69, -1.34, -0.64, -1.28, -2.31, 2.94,
			// 				6.3, 4.08, 5.76, 1.27, 0.23, -0.41, -0.13, -0.24, -1.02, -1.13,
			// 				-0.51, -0.32, -1.51, -0.78, -0.35, -0.9, -1.02, -0.63, 0.16,
			// 				-1.36, -1.27, -0.05, -0.42, -1.55, -0.69, -0.62, -0.65, -0.21,
			// 				-1.02, -1.1, -0.54, 0.04, -0.84, -0.07, 0.68, -2.69, -7.27, 9.82,
			// 				1.33, -1.27, -1.27, -0.76, -0.35, -0.5, -0.35, -0.69, -2.31,
			// 				-7.04, 11.98, 2.19, 0.49, -0.58, -1.12, -2.08, -1.01, -0.07,
			// 				-0.13, -1.59, -1.04, -0.41, -0.3, -0.28, -1.43, -0.73, -0.21,
			// 				-0.7, -1.23, -0.87, 3.46, 6.59, 3.34, 4.37, -0.69, -0.21, -0.35,
			// 				-0.3, -0.53, -1.02, -1.12, -0.69, -0.5, -0.48, -1.23, -0.34,
			// 				-0.25, -1.11, -1.26, -1.03, -0.49, 1.52, 4.22, 3.15, -1.45, -0.29,
			// 				0.35, 0.52, -0.05, -0.44, -0.62, -1.01, -0.59, -0.42, 0.56, -1.18,
			// 				-0.13, -1.84, -4.14, -0.1, 2.68, -2.27, -2.27, -1.19, 4.2, 4.47,
			// 				5.11, 1.65, -0.26, -0.3, -0.03, -0.03, -1.1, -0.87, -0.18, -0.46,
			// 				-1.9, -1.5, -0.61, 0.02, 0.76, -1.96, -0.42, 0.12, -1.21, -1.51,
			// 				-0.07, 0.02, -1.18, -0.89, -0.79, 0.26, 0.64, -2.33, -0.29, -0.26,
			// 				-0.88, -3.89, -2.66, 0.03, -0.13, -0.66, -0.71, -1.13, -1.11,
			// 				-1.16, -0.74, -0.61, -0.27, -0.47, -1.42, -0.95, -0.16, -0.35,
			// 				-0.83, -1.39, -1.16, -1.33, -0.6, -0.17, -0.59, -1.25, -0.69,
			// 				-0.79, -0.41, -0.46, -0.22, -0.54, -1.53, -0.22, -0.14, -2.04,
			// 				-0.93, -0.16, -1.23, -0.33, -0.14, -0.93, -1.73, -0.83, -0.28,
			// 				0.03, -0.72, -0.64, -1.44, -1.12, 3.75, 6.5, 4.55, 8.34, 2.17,
			// 				0.78, -0.53, 0.46, 0.55, -0.06, -0.46, -1.06, -0.91, -0.76, -0.9,
			// 				-0.67, -0.29, 0.62, 0.47, -0.4, -0.37, -0.28, -0.35, -0.38, -0.65,
			// 				-0.72, -1.53, 2.47, 6.98, 4.35, 7.15, 3.37, 0.59, -0.33, -0.31,
			// 				-0.07, -0.24, -0.8, -0.95, -0.98, 0.08, -1.13, -0.93, -0.32,
			// 				-0.65, -0.74, -0.24, -0.98, -0.53, -0.45, -0.76, -0.19, -0.86,
			// 				-0.71, -0.28, -0.87, -2.86, -5.72, 3.62, -1.62, -1.38, -1.83,
			// 				-0.4, -0.46, -0.79, -0.58, -0.64, -1.2, -1.71, 2.53, 6.76, 3.64,
			// 				5.52, 0.28, -0.31, -0.6, -0.5, -0.87, -0.86, -0.88, -0.54, -0.35,
			// 				-0.8, -1.06, 4.03, 8.34, 3.47, 8.1, 1.71, 0.39, -0.81, -0.48,
			// 				-0.36, -0.52, -0.85, -0.93, -0.69, -0.5, -0.91, -0.6, 2.04, -0.77,
			// 				-0.85, -1.48, -8.19, 11.41, 2.18, -0.5, -1.12, -0.98, -0.6, -1.51,
			// 				-1.47, 3.41, 6.62, 5.12, 6.58, 1.61, 0.33, -0.72, -0.42, -0.36,
			// 				-0.34, -0.83, -1.08, -0.66, -0.45, -1.07, -0.98, -0.48, -0.04,
			// 				-0.43, -0.61, -1.02, -0.71, -0.46, -0.56, -0.56, -0.35, -1.19,
			// 				-0.85, 3.04, 6.2, 4.21, 7.97, 3.09, 0.71, -1.14, 1.12, 4.08, 5.2,
			// 				11.21, 4.33, 1.16, 0.71, 0.32, 0.27, -0.03, -0.08, -0.41, -0.98,
			// 				-0.04, -1.03, -3.06, -5.84, -2.13, 1.3, -0.41, -1.01, -0.23, 1.54,
			// 				2.22, 4.48, 3.07, 1.19, 0.48, 1.1, 1.03, 0.37, -0.14, -0.26, -0.8,
			// 				-0.27, -1.15, -0.61, -0.44, 0.19, -1.02, -0.43, -2.28, -0.95,
			// 				-0.44, -0.82, -0.53, -0.14, -2.71, -4.8, -0.05, 0, -0.79, -1.19,
			// 				-1.65, -0.43, 1.15, -0.56, -1.4, 2.01, 0.37, -0.33, -0.56, -0.6,
			// 				-0.05, 0.53, 1.51, 1.86, 1.34, 1.21,
			// 			],
			// 		},
			// 	],
			// 	invalid_data: null,
			// 	outputs: [
			// 		{
			// 			element: 'blow',
			// 			value: 1,
			// 		},
			// 	],
			// 	slices: [
			// 		{
			// 			element: 'Int',
			// 			end_index: 21,
			// 			outputs: [
			// 				{
			// 					element: 'blow',
			// 					value: 1,
			// 				},
			// 			],
			// 			start_index: 21,
			// 		},
			// 		{
			// 			element: 'Int',
			// 			end_index: 43,
			// 			outputs: [
			// 				{
			// 					element: 'blow',
			// 					value: 1,
			// 				},
			// 			],
			// 			start_index: 43,
			// 		},
			// 		{
			// 			element: 'Int',
			// 			end_index: 79,
			// 			outputs: [
			// 				{
			// 					element: 'blow',
			// 					value: 1,
			// 				},
			// 			],
			// 			start_index: 79,
			// 		},
			// 		{
			// 			element: 'float',
			// 			end_index: 113,
			// 			outputs: [
			// 				{
			// 					element: 'blow',
			// 					value: 1,
			// 				},
			// 			],
			// 			start_index: 113,
			// 		},
			// 		{
			// 			element: 'float',
			// 			end_index: 152,
			// 			outputs: [
			// 				{
			// 					element: 'blow',
			// 					value: 1,
			// 				},
			// 			],
			// 			start_index: 152,
			// 		},
			// 		{
			// 			element: 'float',
			// 			end_index: 161,
			// 			outputs: [
			// 				{
			// 					element: 'blow',
			// 					value: 1,
			// 				},
			// 			],
			// 			start_index: 161,
			// 		},
			// 	],
			// 	state: 'complete',
			// 	uuid: 'e859267e-25a5-495e-af23-86b02b9f6755',
			// },
		],
		totalPredictions: 0,
	},
	reducers: {
		SET_CURRENT_PREDICTION: (state, { payload }) => {
			console.log('SET_CURRENT_PREDICTION');
			state.currentPrediction = payload;
		},
	},
	extraReducers: (builder) => {
		// GET

		builder.addCase(GET_PREDICTIONS.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_PREDICTIONS.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			state.predictions = payload.data;
			state.totalPredictions = payload.total_count;
		});
		builder.addCase(GET_PREDICTIONS.rejected, (state) => {
			state.isLoading = false;
		});
		// POST

		builder.addCase(CREATE_PREDICTION.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(CREATE_PREDICTION.fulfilled, (state, { payload }) => {
			state.isLoading = false;
			console.log(payload);
			state.predictions = [...state.predictions, payload];
		});
		builder.addCase(CREATE_PREDICTION.rejected, (state) => {
			state.isLoading = false;
		});
	},
});

// Action creators are generated for each case reducer function
export const { SET_CURRENT_PREDICTION } = predictionSlice.actions;

export default predictionSlice.reducer;
