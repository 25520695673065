/* eslint-disable */

import { colors } from '../../consts/colors';

export class Nodes {
	static createSimpleNode(id, type, position, label, name, element_type) {
		return {
			id: id,
			position: position,
			data: {
				id: id,
				type: type,
				label: label,
				name: name,
				element_type: element_type,
			},
			name: name,
			...(type === 'input'
				? {
						type: 'customInput',
						style: {
							backgroundColor: '#fff',
							border: `1px solid ${colors.taskInput}`,
							borderRadius: '5px',
							padding: 10,
							zIndex: 0,
						},
				  }
				: {
						type: 'customOutput',
						style: {
							backgroundColor: '#fff',
							border: `1px solid ${colors.taskOutput}`,
							borderRadius: '5px',
							padding: 10,
							zIndex: 0,
						},
				  }),
		};
	}

	static createMetadataNode(id, type, position, label, name, element_type) {
		return {
			id: id,
			type: 'customMetadata',
			data: {
				id: id,
				type: type,
				label: label,
				name: name,
				element_type: element_type,
			},
			name: name,
			position: position,
			style: {
				backgroundColor: '#fff',
				border: `1px solid ${colors.taskMetaData}`,
				borderRadius: '5px',
				padding: 10,
				zIndex: 0,
			},
		};
	}

	static createGroupNode(id, position, label, name) {
		return {
			id: id,
			type: 'group',
			position: position,
			data: {
				label: label,
			},
			name: name,
			style: {
				backgroundColor: '#fff',
				border: '1px solid #777',
				borderRadius: '5px',
				padding: 10,
				zIndex: 0,
			},
		};
	}
}
