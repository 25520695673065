import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { FormControl, Grid, IconButton, MenuItem, Select } from '@mui/material';
import { faEquals } from '@fortawesome/free-solid-svg-icons';

// Styles
import useStyles from './styles';
import { getElementToEdit } from '../../../Examples/elementEditor.service';

const QueryBuilder = (props) => {
	const { tmpColumns, filters, setFilters } = props;

	const classes = useStyles();

	const { categories: categoriesState } = useSelector((state) => state.schema);

	// useEffect(() => {
	// 	if (query.field !== '' && query.value !== '')
	// 		setFilters({ ...filters, query: `${query.field}=${query.value}` });
	// }, [query]);

	// todo: when multiple query supported
	// const handleAddRule = () => {
	// 	setQuery([...query, { field: '', operator: '==', value: '' }]);
	// };

	// const handleRemoveRule = (field) => {
	// 	setQuery(query.filter((q) => q.field !== field));
	// };

	const handleChangeField = (e) => {
		const { name, value } = e.target;
		setFilters({ ...filters, query: `${value}=` });
		// setQuery({ ...query, field: value, value: '' });

		// todo: when multiple query supported
		// setQuery(
		// 	query.map((q) => {
		// 		console.log(q.field, value);
		// 		if (q.field === name || q.field === '') {
		// 			return { ...q, field: value };
		// 		}
		// 		return q;
		// 	})
		// );
	};

	const handleChangeValue = (name, value) => {
		setFilters({
			...filters,
			query: `${filters.query.split('=')[0]}=${value || ''}`,
		});
		// setQuery({ ...query, value });

		// todo: when multiple query supported
		// setQuery(
		// 	query.map((q) => {
		// 		if (q.field === name) return { ...q, value };
		// 		return q;
		// 	})
		// );
	};

	return (
		<Grid container sx={{ display: 'flex', gap: 1 }}>
			{
				// todo: when multiple query supported
			}
			{/* {query.length > 0 &&
				query.map((row, index) => ( */}
			<Grid
				container
				spacing={3}
				// key={index}
				sx={{ display: 'flex', alignItems: 'center' }}
			>
				{/* <Grid item xs={1}>
							{index !== 0 && 'AND'}
						</Grid> */}
				<Grid item xs={3}>
					<FormControl fullWidth sx={{ backgroundColor: 'white' }}>
						<Select
							// name={row.field}
							// value={row.field || ''}
							name={filters.query !== '' && filters.query.split('=')[0]}
							value={
								(filters.query !== '' && filters.query.split('=')[0]) || ''
							}
							onChange={handleChangeField}
						>
							{tmpColumns
								.filter(
									({ valueType }) =>
										valueType !== 'time_serie' &&
										valueType !== 'image_file' &&
										valueType !== 'audio_file' &&
										valueType !== 'video_file' &&
										valueType !== 'document_file' &&
										valueType !== 'generic_file'
								)
								.map(({ name, field }) => (
									<MenuItem key={uuidv4()} value={field}>
										{name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={1}>
					<IconButton aria-label="delete" color="primary">
						<FontAwesomeIcon
							icon={faEquals}
							style={{
								fontSize: '18px',
							}}
						/>
					</IconButton>
				</Grid>
				<Grid item xs={3}>
					{filters.query !== '' &&
						filters.query.split('=')[0] &&
						categoriesState &&
						tmpColumns &&
						tmpColumns.find(
							(col) => col.field === filters.query.split('=')[0]
						) &&
						getElementToEdit[
							tmpColumns.find(
								(col) => col.field === filters.query.split('=')[0]
							).valueType
						]({
							classes,
							currentCellId: filters.query.split('=')[0],
							currentElementType:
								tmpColumns.find(
									(col) => col.field === filters.query.split('=')[0]
								).fieldType === 'metadata'
									? 'metadata'
									: `${
											tmpColumns.find(
												(col) => col.field === filters.query.split('=')[0]
											).fieldType
									  }s`,
							currentElementValueType: tmpColumns.find(
								(col) => col.field === filters.query.split('=')[0]
							).valueType,
							currentValue: filters.query !== '' && filters.query.split('=')[1],
							setCurrentValue: handleChangeValue,
							categoriesState,
							name: filters.query !== '' && filters.query.split('=')[0],
						})}
					{
						// todo: when multiple query supported
					}
					{/* {row.field &&
						categoriesState &&
						tmpColumns &&
						tmpColumns.find((col) => col.field === row.field) &&
						getElementToEdit[
							tmpColumns.find((col) => col.field === row.field).valueType
						]({
							classes,
							currentCellId: row.field,
							currentElementType:
								tmpColumns.find((col) => col.field === row.field).fieldType ===
								'metadata'
									? 'metadata'
									: `${
											tmpColumns.find((col) => col.field === row.field)
												.fieldType
									  }s`,
							currentValue: row.value,
							setCurrentValue: handleChangeValue,
							categoriesState,
							name: row.field,
						})} */}
				</Grid>
				{
					// todo: when multiple query supported
				}
				{/* <Grid item xs={1}>
							<IconButton
								aria-label="delete"
								color="primary"
								onClick={() => handleRemoveRule(row.field)}
							>
								<FontAwesomeIcon
									icon={faTrashCan}
									style={{
										fontSize: '18px',
										color: 'red',
									}}
								/>
							</IconButton>
						</Grid> */}
			</Grid>
			{/* ))} */}
			{
				// todo: when multiple query supported
			}
			{/* <Grid item xs={12}>
				<StandardButton value="Add rule" handleClick={handleAddRule} />
			</Grid> */}
		</Grid>
	);
};
export default QueryBuilder;

QueryBuilder.propTypes = {
	tmpColumns: PropTypes.array,
	filters: PropTypes.object,
	setFilters: PropTypes.func,
};
