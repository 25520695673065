import { makeStyles } from '@mui/styles';
import { colors } from '../../../../../../consts/colors';

export const useStyles = makeStyles({
	trashIcon: {
		fontSize: '24px',
		color: colors.red,
		margin: '0px 6px',
	},
	addOutputIcon: {
		fontSize: '12px',
		color: colors.buttonPrimaryColor,
		margin: '0 6px 0 0',
	},
});
