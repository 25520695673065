import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import requestFactory from '../services/request.factory';

// CREATE

// GET

export const GET_USER = createAsyncThunk(
	'user/GET_USER',
	async ({ accessToken, dispatch, navigate }) => {
		console.log('user/GET_USER');
		const res = await requestFactory({
			type: 'GET',
			url: `/myaccount`,
			accessToken,
			dispatch,
		});

		if (res) return res;
		navigate('/complete-profile');
	}
);

export const GET_USER_SETTINGS = createAsyncThunk(
	'user/GET_USER_SETTINGS',
	async ({ accessToken, dispatch }) => {
		console.log('user/GET_USER_SETTINGS');
		const res = await requestFactory({
			type: 'GET',
			url: `/myaccount/settings`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_USER_PERMISSIONS_AND_ROLES = createAsyncThunk(
	'user/GET_USER_PERMISSIONS_AND_ROLES',
	async ({ accessToken, organizationId, userId, dispatch }) => {
		console.log('user/GET_USER_PERMISSIONS_AND_ROLES');

		// // todo: tmp get from organization cause myAccount/permissions isn't work
		const res1 = await requestFactory({
			type: 'GET',
			url: `/myaccount/permissions`,
			accessToken,
			dispatch,
		});
		// const res1 = await requestFactory(
		// 	'GET',
		// 	`/organizations/${organizationId}/users/${userId}/permissions`,
		// 	accessToken
		// );

		const res2 = await requestFactory({
			type: 'GET',
			url: `/organizations/${organizationId}/users/${userId}/roles`,
			accessToken,
			dispatch,
		});

		if (res1 && res2) return { permissions: res1.data, roles: res2.roles };
	}
);

// PUT

export const UPDATE_EMAIL_NOTIFICATIONS = createAsyncThunk(
	'user/UPDATE_EMAIL_NOTIFICATIONS',
	async ({ data, accessToken, dispatch }) => {
		console.log('user/UPDATE_EMAIL_NOTIFICATIONS');
		const res = await requestFactory({
			type: 'PUT',
			url: `/myaccount/settings`,
			data,
			accessToken,
			dispatch,
		});

		return res;
	}
);

// export const UPDATE_USER_TERMS = createAsyncThunk(
// 	'user/UPDATE_USER_TERMS',
// 	async (data) => {
// 		console.log('user/UPDATE_USER_TERMS');
// 		const res = await requestFactory('put', `/account/${data.userId}`, {
// 			terms_agreed: data.termsAgreed,
// 		});

// 		return res;
// 	}
// );

// DELETE

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		id: null,
		uid: null,
		location: null,
		isLoading: true,
		isAuthorized: false,
		first_name: null,
		last_name: null,
		email: null,
		accessToken: '',
		isNew: null,
		termsAgreed: null,
		notifications: null,
		permissions: [],
		roles: [],
	},
	reducers: {
		// SET_USER_STATE: (state, action) => {
		// 	console.log('user/SET_USER_STATE');

		// 	state.uid = action.payload.sub;
		// 	state.full_name = `${action.payload.given_name} ${action.payload.family_name}`;
		// 	state.given_name = action.payload.given_name;
		// 	state.family_name = action.payload.family_name;
		// 	state.email = action.payload.email;
		// 	state.nickname = action.payload.nickname;
		// 	state.locale = action.payload.locale;
		// 	state.created = action.payload.created;
		// 	state.picture = action.payload.picture;
		// 	state.isNew = action.payload.is_new;
		// },
		SET_ACCESS_TOKEN: (state, { payload }) => {
			console.log('user/SET_ACCESS_TOKEN');
			state.accessToken = payload;
			state.isAuthorized = true;
		},
		SET_USER_LOCATION: (state, { payload }) => {
			console.log('user/SET_USER_LOCATION');
			state.location = payload;
		},
		// CHANGE_FIRSTNAME: (state, action) => {
		// 	console.log('user/CHANGE_FIRSTNAME');
		// 	// API call .then change state
		// 	state.given_name = action.payload;
		// },
		// CHANGE_LASTNAME: (state, action) => {
		// 	console.log('user/CHANGE_LASTNAME');
		// 	// API call .then change state
		// 	state.family_name = action.payload;
		// },
	},
	extraReducers: (builder) => {
		// GET

		builder.addCase(GET_USER.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_USER.fulfilled, (state, { payload }) => {
			state.isLoading = false;

			// state.termsAgreed = payload.terms_agreed;
			state.id = payload.id;
			state.uid = payload.uid;
			state.first_name = payload.first_name;
			state.last_name = payload.last_name;
			state.email = payload.email;
		});
		builder.addCase(GET_USER.rejected, (state) => {
			state.isLoading = true;
		});

		builder.addCase(GET_USER_SETTINGS.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(GET_USER_SETTINGS.fulfilled, (state, { payload }) => {
			state.isLoading = false;

			state.notifications = payload.notifications;
		});
		builder.addCase(GET_USER_SETTINGS.rejected, (state) => {
			state.isLoading = true;
		});

		builder.addCase(GET_USER_PERMISSIONS_AND_ROLES.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(
			GET_USER_PERMISSIONS_AND_ROLES.fulfilled,
			(state, { payload }) => {
				state.isLoading = false;

				state.roles = payload.roles;
				state.permissions = payload.permissions;
			}
		);
		builder.addCase(GET_USER_PERMISSIONS_AND_ROLES.rejected, (state) => {
			state.isLoading = true;
		});

		// POST

		// PUT

		builder.addCase(UPDATE_EMAIL_NOTIFICATIONS.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(
			UPDATE_EMAIL_NOTIFICATIONS.fulfilled,
			(state, { payload }) => {
				state.isLoading = false;
				state.notifications = payload.notifications;
			}
		);
		builder.addCase(UPDATE_EMAIL_NOTIFICATIONS.rejected, (state) => {
			state.isLoading = false;
		});

		// builder.addCase(UPDATE_USER_TERMS.pending, (state) => {
		// 	state.isLoading = true;
		// });
		// builder.addCase(UPDATE_USER_TERMS.fulfilled, (state, { payload }) => {
		// 	state.isLoading = false;
		// 	state.termsAgreed = payload.terms_agreed;
		// });
		// builder.addCase(UPDATE_USER_TERMS.rejected, (state, { payload }) => {
		// 	state.isLoading = false;
		// });

		// DELETE
	},
});

// Action creators are generated for each case reducer function
export const {
	// SET_USER_STATE,
	// CHANGE_FIRSTNAME,
	// CHANGE_LASTNAME,
	SET_ACCESS_TOKEN,
	SET_USER_LOCATION,
} = userSlice.actions;

export default userSlice.reducer;
