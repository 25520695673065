import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Skeleton } from '@mui/material';
import { SearchInput } from '../../../Components/Shared/Inputs';

// Styles
import useStyles from './styles';

// import { documentationLinks } from '../../../data/dashboard';

function Documentation() {
	const classes = useStyles();

	const [currentValue, setCurrentValue] = useState('');
	const [currentPostList, setCurrentPostList] = useState([]);

	const getDefaultPosts = async () =>
		fetch('https://enaia.ai/wp-json/wp/v2/posts?per_page=5&page=1', {
			method: 'get',
		})
			.then((response) => response.json())
			.then((result) => {
				const tmp = result.slice(0, 3);
				setCurrentPostList(tmp);
			})
			.catch((err) => console.error(err));

	const getSearchedPosts = async (value) =>
		fetch(
			`https://enaia.ai/wp-json/wp/v2/search?search=${value}&per_page=5&page=1`,
			{
				method: 'get',
			}
		)
			.then((response) => response.json())
			.then((result) => {
				setCurrentPostList(result);
			})
			.catch((err) => console.error(err));

	useEffect(() => {
		getDefaultPosts();
	}, []);

	const handleChange = (e) => {
		const { value } = e.target;
		setCurrentValue(value);
		setCurrentPostList([]);
		setTimeout(() => {
			getSearchedPosts(value);
		}, 2000);
	};

	return (
		<div className={classes.documentationContainer}>
			<SearchInput
				value={currentValue}
				handleChange={handleChange}
				placeholder="Help me find..."
			/>
			<div>
				{currentPostList.length > 0 &&
					currentPostList.map((post) => (
						<p key={uuidv4()}>
							<a
								href={`${post.link ? post.link : post.url}`}
								target="_blank"
								rel="noreferrer"
							>
								{post.title && post.title.rendered
									? post.title.rendered
									: post.title}
							</a>
						</p>
					))}
				{currentPostList.length === 0 && (
					<>
						<div style={{ padding: '6px' }}>
							<Skeleton
								variant="text"
								style={{
									height: '12px',
									width: '100%',
									marginTop: '12px',
								}}
							/>
						</div>
						<div style={{ padding: '6px' }}>
							<Skeleton
								variant="text"
								style={{
									height: '12px',
									width: '100%',
								}}
							/>
						</div>
						<div style={{ padding: '6px' }}>
							<Skeleton
								variant="text"
								style={{
									height: '12px',
									width: '100%',
								}}
							/>
						</div>
						<div style={{ padding: '6px' }}>
							<Skeleton
								variant="text"
								style={{
									height: '12px',
									width: '100%',
								}}
							/>
						</div>
						<div style={{ padding: '6px' }}>
							<Skeleton
								variant="text"
								style={{
									height: '12px',
									width: '100%',
								}}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default Documentation;
