import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';

// Syles
import useStyles from './styles';
import StandardButton from '../Buttons/StandardButton';

export const StandardModalDialog = ({
	open,
	setOpen,
	title,
	titleDescription,
	content,
	confirmActions,
}) => {
	const classes = useStyles();

	const handleClose = () => {
		setOpen(!open);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			className={classes.dialogContainer}
			sx={{
				'& .MuiDialog-paper': {
					borderRadius: '24px',
				},
			}}
		>
			<div className={classes.dialogTitle}>{title}</div>
			<DialogContent>
				{titleDescription && (
					<DialogContentText>{titleDescription}</DialogContentText>
				)}
				{content}
			</DialogContent>
			<DialogActions>
				<div className={classes.dialogActions}>
					{confirmActions}
					<StandardButton
						type="danger"
						handleClick={handleClose}
						value="Close"
					/>
				</div>
			</DialogActions>
		</Dialog>
	);
};

StandardModalDialog.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	title: PropTypes.string,
	titleDescription: PropTypes.string,
	content: PropTypes.any,
	confirmActions: PropTypes.any,
};
