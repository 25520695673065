import React, { memo } from 'react';
import { Handle } from 'reactflow';
// import { Handle } from 'react-flow-renderer';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * CustomGroupNode is a HOC that defines a custom node for the react-flow-renderer library. It is used to render a schema
 * group node, which groups inputs and metadata together for an arbitrary number of outputs, so it has two different
 * `<Handle/>`s for the inputs and metadata, and a single `<Handle/>` for the output.
 *
 * @param {Object} data
 * @param {boolean} isConnectable
 * @return {JSX.Element} The rendered node.
 *
 * @author Enrique Hernández Calabrés
 */
function CustomGroupNode({ data, isConnectable }) {
	return (
		<>
			<Handle id="inputs" type="target" position="left" />
			<Handle id="metadata" type="target" position="bottom" />
			<Typography variant="body2">{data.label}</Typography>
			<Handle id="outputs" type="source" position="right" />
		</>
	);
}
// function CustomGroupNode({ data, isConnectable }) {
// 	return (
// 		<>
// 			<Handle
// 				id="inputs"
// 				type="target"
// 				position="left"
// 				isConnectable={isConnectable}
// 			/>
// 			<Handle
// 				id="metadata"
// 				type="target"
// 				position="bottom"
// 				isConnectable={isConnectable}
// 			/>
// 			<Typography variant="body2">{data.label}</Typography>
// 			<Handle
// 				id="outputs"
// 				type="source"
// 				position="right"
// 				isConnectable={isConnectable}
// 			/>
// 		</>
// 	);
// }

export default memo(CustomGroupNode);

CustomGroupNode.propTypes = {
	data: PropTypes.any,
	isConnectable: PropTypes.any,
};
