import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	maintenance: {
		textAlign: 'center',
		margin: '100px auto',
		maxWidth: '400px',
		padding: '20px',
		backgroundColor: '#f7f7f7',
		border: '1px solid #ddd',
		borderRadius: '5px',
	},

	maintenanceImage: {
		maxWidth: '100%',
		marginTop: '20px',
	},
});
