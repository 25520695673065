import React from 'react';
import PropTypes from 'prop-types';

// Styles
import useStyles from './styles';

function Section({ title, children }) {
	const classes = useStyles();

	return (
		<div className={classes.sectionContainer}>
			<div className={classes.sectionTitle}>{title}</div>
			<div className={classes.sectionContent}>{children}</div>
		</div>
	);
}

Section.propTypes = {
	children: PropTypes.object,
	title: PropTypes.any,
};

export default Section;
