import { v4 as uuidv4 } from 'uuid';

// Components
import {
	Box,
	ThemeProvider,
	Tooltip,
	Typography,
	createTheme,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheck,
	faXmark,
	faSpinner,
	faBan,
	faTag,
	faFilePen,
	faMessage,
} from '@fortawesome/free-solid-svg-icons';
import { CustomTooltip } from '../../../../Components/Shared/CustomTooltip';

// Services
import { elementTypeToIconMap } from '../../../../services/tasks';
import { createCell } from './cellCreation.services';

// Styles
import { colors } from '../../../../consts/colors';
import { ADD_ALERT, REMOVE_ALERT } from '../../../../redux/alerts.slice';
import {
	GET_EXAMPLES,
	UPDATE_EXAMPLE,
	// UPDATE_EXAMPLE
} from '../../../../redux/examples.slice';
import requestFactory from '../../../../services/request.factory';

// Tooltip theme
const tooltipTheme = createTheme({
	components: {
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					color: 'black',
					backgroundColor: 'white',
					border: '1px solid gray',
				},
			},
		},
	},
});

export const getCategoryValues = async ({
	id,
	nodeType,
	setIsLocalLoading = null,
	allColumns,
}) => {
	if (!allColumns.length) return;
	const categories = [];
	if (setIsLocalLoading !== null) setIsLocalLoading(true);
	const nodeTypeForRequest =
		nodeType === 'metadata' ? nodeType : `${nodeType}s`;
	const res = await requestFactory(
		'get',
		`/schema/${nodeTypeForRequest}/${id}/categories`
	);
	categories.push({ field: id, categories: res });
	if (setIsLocalLoading !== null) setIsLocalLoading(false);

	return {
		id,
		res,
		categories,
	};
};

const rowStatusToIcon = {
	unlabeled: () => '',
	pending_review: (
		<FontAwesomeIcon icon={faSpinner} style={{ color: colors.red }} />
	),
	labeled: <FontAwesomeIcon icon={faCheck} style={{ color: colors.green }} />,
	rejected: <FontAwesomeIcon icon={faXmark} style={{ color: colors.red }} />,
	null: () => '',
	undefined: () => '',
};

export const getColumns = ({
	setAllColumns,
	classes,
	schemaState,
	examplesState,
	categoriesState,
	currentTaskState,
	accessToken,
	setCurrentRowId,
	dispatch,
	imagesBufferState,
	documentsBufferState,
	documentSelectedNumPages,
	onDocumentLoadSuccess,
	from,
	setOpenCommentsModal,
}) => {
	const tmpCols = [];

	if (Object.keys(schemaState) && Object.keys(schemaState).length > 0) {
		if (from !== 'predictions') {
			// // EDIT
			// tmpCols.push({
			// 	field: 'edit',
			// 	sortable: false,
			// 	disableColumnMenu: true,
			// 	renderCell: (params) =>
			// 		examplesState &&
			// 		examplesState.length > 0 && (
			// 			<HasAccess
			// 				roles={[]}
			// 				permissions="task.update"
			// 				renderAuthFailed={
			// 					<FontAwesomeIcon
			// 						icon={faPenToSquare}
			// 						style={{ width: '50px' }}
			// 						disabled
			// 					/>
			// 				}
			// 			>
			// 				<FontAwesomeIcon
			// 					icon={faPenToSquare}
			// 					style={{ color: colors.blue, width: '50px' }}
			// 					onClick={() => {
			// 						setCurrentRowId(params.row.id);
			// 						setOpenEditExampleModal(true);
			// 					}}
			// 				/>
			// 			</HasAccess>
			// 		),
			// 	renderHeader: () => (
			// 		<FontAwesomeIcon
			// 			icon={faPenToSquare}
			// 			style={{ color: colors.blue, width: '50px' }}
			// 		/>
			// 	),
			// });

			// STATUS
			tmpCols.push({
				fieldType: 'status',
				field: 'status',
				name: 'Status',
				sortable: true,
				disableColumnMenu: true,
				headerClassName: 'border-rigth',
				cellClassName: 'gray-column-border-rigth',
				width: 150,

				renderHeader: () => (
					<Box key={uuidv4()} className={classes.headerCell}>
						Status
					</Box>
				),
				renderCell: (params) => {
					const handleClick = async (status) => {
						const currentRowId = params.row.id;
						let tmpUpdate = { values: [] };
						const tmpExample = examplesState.find(
							(example) => example.id === currentRowId
						);
						const inputs = tmpExample.inputs
							?.filter((val) => {
								if (val.value === '') return false;
								return val;
							})
							?.map((val) => ({ element: val.element, value: val.value }));
						const metadata = tmpExample.metadata
							?.filter((val) => {
								if (val.value === '') return false;
								return val;
							})
							?.map((val) => ({ element: val.element, value: val.value }));
						const outputs = tmpExample.outputs
							?.filter((val) => {
								if (val.value === '') return false;
								return val;
							})
							?.map((val) => ({ element: val.element, value: val.value }));

						if (tmpExample) {
							tmpUpdate = { ...tmpUpdate, labeling_status: status };
							if (inputs) tmpUpdate.values.push(...inputs);
							if (metadata) tmpUpdate.values.push(...metadata);
							if (outputs) tmpUpdate.values.push(...outputs);
						}

						await dispatch(
							UPDATE_EXAMPLE({
								taskId: currentTaskState.uuid,
								exampleId: currentRowId,
								examplesToUpdate: tmpUpdate,
								dispatch,
								accessToken,
							})
						);
						tmpUpdate = {};
						await dispatch(
							GET_EXAMPLES({
								taskId: currentTaskState.uuid,
								accessToken,
								dispatch,
							})
						);
					};

					if (examplesState && examplesState.length > 0) {
						return (
							<Box
								className={classes.rowCell}
								sx={{
									maxWidth: '130px',
									display: 'flex',
								}}
							>
								<ThemeProvider theme={tooltipTheme}>
									<Tooltip
										title={
											<div
												style={{
													display: 'flex',
													gap: '6px',
												}}
											>
												<Typography style={{ cursor: 'pointer' }}>
													<CustomTooltip title="Labeled">
														<FontAwesomeIcon
															icon={faCheck}
															style={{
																fontSize: '18px',
																color:
																	params.row.status === 'labeled' &&
																	colors.green,
															}}
															onClick={() => handleClick('labeled')}
														/>
													</CustomTooltip>
												</Typography>
												<Typography style={{ cursor: 'pointer' }}>
													<CustomTooltip title="Unlabeled">
														<FontAwesomeIcon
															icon={faTag}
															style={{
																fontSize: '18px',
																color:
																	params.row.status === 'unlabeled' &&
																	colors.red,
															}}
															onClick={() => handleClick('unlabeled')}
														/>
													</CustomTooltip>
												</Typography>
												<Typography style={{ cursor: 'pointer' }}>
													<CustomTooltip title="Pending review">
														<FontAwesomeIcon
															icon={faFilePen}
															style={{
																fontSize: '18px',
																color:
																	params.row.status === 'pending_review' &&
																	colors.red,
															}}
															onClick={() => handleClick('pending_review')}
														/>
													</CustomTooltip>
												</Typography>
												<Typography style={{ cursor: 'pointer' }}>
													<CustomTooltip title="Rejected">
														<FontAwesomeIcon
															icon={faBan}
															style={{
																fontSize: '18px',
																color:
																	params.row.status === 'rejected' &&
																	colors.red,
															}}
															onClick={() => handleClick('rejected')}
														/>
													</CustomTooltip>
												</Typography>
											</div>
										}
									>
										<div>
											{params.row.status === 'labeled' && (
												<div
													style={{
														display: 'flex',
														gap: 4,
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<FontAwesomeIcon
														icon={faCheck}
														style={{
															fontSize: '18px',
															color:
																params.row.status === 'labeled' && colors.green,
														}}
													/>
													Labeled
												</div>
											)}
											{params.row.status === 'unlabeled' && (
												<div
													style={{
														display: 'flex',
														gap: 4,
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<FontAwesomeIcon
														icon={faTag}
														style={{
															fontSize: '18px',
															color:
																params.row.status === 'unlabeled' && colors.red,
														}}
													/>
													Unlabeled
												</div>
											)}
											{params.row.status === 'pending_review' && (
												<div
													style={{
														display: 'flex',
														gap: 4,
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<FontAwesomeIcon
														icon={faFilePen}
														style={{
															fontSize: '18px',
															color:
																params.row.status === 'pending_review' &&
																colors.red,
														}}
													/>
													Pending review
												</div>
											)}
											{params.row.status === 'rejected' && (
												<div
													style={{
														display: 'flex',
														gap: 4,
														alignItems: 'center',
														cursor: 'pointer',
													}}
												>
													<FontAwesomeIcon
														icon={faBan}
														style={{
															fontSize: '18px',
															color:
																params.row.status === 'rejected' && colors.red,
														}}
													/>
													Rejected
												</div>
											)}
										</div>
									</Tooltip>
								</ThemeProvider>
							</Box>
						);
					}
				},
				valueGetter: (params) => {
					if (examplesState && examplesState.length > 0)
						return params.row.status;
				},
			});
		}

		// COMMENTS
		tmpCols.push({
			fieldType: 'comments',
			field: 'comments',
			sortable: false,
			disableColumnMenu: true,
			headerClassName: 'message-column',
			cellClassName: 'message-column',
			maxWidth: 50,
			renderCell: (params) =>
				examplesState &&
				examplesState.length > 0 && (
					<FontAwesomeIcon
						icon={faMessage}
						style={{ color: colors.blue, width: '50px', cursor: 'pointer' }}
						onClick={() => {
							setCurrentRowId(params.row.id);
							setOpenCommentsModal(true);
						}}
					/>
				),
			renderHeader: () => (
				<div />
				// <FontAwesomeIcon
				// 	icon={faMessage}
				// 	style={{ color: colors.blue, width: '50px' }}
				// />
			),
		});

		// ITERATE EACH EXAMPLE
		Object.keys(schemaState).forEach((type) => {
			// ITERATE INPUTS
			if (
				type === 'inputs' &&
				schemaState[type] &&
				schemaState[type].length > 0
			) {
				schemaState[type].forEach((input) => {
					if (input.type !== 'shape' && input.type !== 'slice')
						tmpCols.push({
							id: input.id,
							fieldType: 'input',
							valueType: input.type,
							multiValue: input.multi_value,
							field: input.name,
							name: input.display_name || input.name,
							sortable: true,
							disableColumnMenu: true,
							width: 125,
							renderHeader: () => (
								<CustomTooltip title={input.display_name || input.name}>
									<Box
										sx={{
											display: 'flex !important',
											alignItems: 'center',
											minWidth: '80px',
											'&>*': {
												display: 'block !important',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
											},
										}}
									>
										{input.type && elementTypeToIconMap[input.type]('input')}
										<div>{input.display_name || input.name}</div>
									</Box>
								</CustomTooltip>
							),
							renderCell: (params) => {
								if (params.row[input.id] !== undefined) {
									return (
										<div
											style={{
												width: '100%',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											{createCell[input.type]({
												classes,
												cellValue:
													params.row[input.id] || params.row[input.element],
												cellName: input.name,
												imagesBufferState,
												documentsBufferState,
												documentSelectedNumPages,
												onDocumentLoadSuccess,
												categoriesState,
												valueType: 'inputs',
												params,
												multiValue: input.multi_value,
											})}
										</div>
									);
								}
							},
							valueGetter: (params) => {
								if (examplesState && examplesState.length > 0)
									return params.row[input.id];
							},
						});
				});
			}

			// ITERATE OUTPUTS
			if (
				type === 'outputs' &&
				schemaState[type] &&
				schemaState[type].length > 0
			) {
				schemaState[type].forEach((output) => {
					if (output.type !== 'shape' && output.type !== 'slice')
						tmpCols.push({
							id: output.id,
							fieldType: 'output',
							valueType: output.type,
							multiValue: output.multi_value,
							field: output.name,
							name: output.display_name || output.name,
							sortable: true,
							disableColumnMenu: true,
							width: 125,
							renderCell: (params) => {
								if (params.row[output.id] !== undefined) {
									return (
										<div
											style={{
												width: '100%',
												height: '100%',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											{createCell[output.type]({
												classes,
												cellValue:
													params.row[output.id] || params.row[output.element],
												cellName: output.name,
												imagesBufferState,
												documentsBufferState,
												documentSelectedNumPages,
												onDocumentLoadSuccess,
												categoriesState,
												valueType: 'outputs',
												params,
												multiValue: output.multi_value,
											})}
										</div>
									);
								}
							},
							renderHeader: () => (
								<CustomTooltip title={output.display_name || output.name}>
									<Box
										sx={{
											display: 'flex !important',
											alignItems: 'center',
											minWidth: '80px',
											'&>*': {
												display: 'block !important',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
											},
										}}
									>
										{output.type && elementTypeToIconMap[output.type]('output')}
										{output.display_name || output.name}
									</Box>
								</CustomTooltip>
							),
							valueGetter: (params) => {
								if (examplesState && examplesState.length > 0)
									return params.row[output.id];
							},
						});
				});
			}

			// ITERATE METADATA
			if (
				type === 'metadata' &&
				schemaState[type] &&
				schemaState[type].length > 0
			) {
				schemaState[type].forEach((meta) => {
					if (meta.type !== 'shape' && meta.type !== 'slice')
						tmpCols.push({
							id: meta.id,
							fieldType: 'metadata',
							valueType: meta.type,
							multiValue: meta.multi_value,
							field: meta.name,
							name: meta.display_name || meta.name,
							sortable: true,
							disableColumnMenu: true,
							width: 125,
							renderCell: (params) =>
								createCell[meta.type]({
									classes,
									cellValue: params.row[meta.id],
									cellName: meta.name,
									imagesBufferState,
									documentsBufferState,
									onDocumentLoadSuccess,
									categoriesState,
									valueType: 'metadata',
									params,
									multiValue: meta.multi_value,
								}),
							renderHeader: () => (
								<CustomTooltip title={meta.display_name || meta.name}>
									<Box
										sx={{
											display: 'flex !important',
											alignItems: 'center',
											minWidth: '80px',
											'&>*': {
												display: 'block !important',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
											},
										}}
									>
										{meta.type && elementTypeToIconMap[meta.type]('metadata')}
										{meta.display_name || meta.name}
									</Box>
								</CustomTooltip>
							),
							valueGetter: (params) => {
								if (examplesState && examplesState.length > 0)
									return params.row[meta.id];
							},
						});
				});
			}
		});
	}

	setAllColumns(tmpCols);
};

export const getRows = ({
	setRows,
	schemaState,
	examplesState,
	predictionsState,
	from,
}) => {
	const tmpRows = [];
	let tmpRowData = {};

	if (
		from !== 'predictions' &&
		examplesState &&
		examplesState.length > 0 &&
		Object.keys(schemaState) &&
		Object.keys(schemaState).length > 0
	) {
		examplesState.forEach((example) => {
			tmpRowData = {
				...tmpRowData,
				id: example.element || example.id,
				status: example.labeling_status,
			};

			// INPUTS
			if (example && example.inputs && example.inputs.length > 0) {
				example.inputs.forEach((input) => {
					let tmp = [];
					if (
						schemaState &&
						schemaState.inputs &&
						schemaState.inputs.length > 0
					)
						tmp = schemaState.inputs.find(
							(element) => element.name === input.element
						);
					if (tmp && Object.keys(tmp).length > 0)
						tmpRowData = {
							...tmpRowData,
							[tmp.id]:
								typeof input.value === 'boolean' ||
								typeof input.value === 'number'
									? `${input.value}`
									: input.value || '',
						};
				});
			}

			// OUTPUTS
			if (example && example.outputs && example.outputs.length > 0) {
				example.outputs.forEach((output) => {
					let tmp = [];
					if (
						schemaState &&
						schemaState.outputs &&
						schemaState.outputs.length > 0
					)
						tmp = schemaState.outputs.find(
							(element) => element.name === output.element
						);
					if (tmp && Object.keys(tmp).length > 0)
						tmpRowData = { ...tmpRowData, [tmp.id]: output.value || '' };
				});
			}

			// METADATA
			if (example && example.metadata && example.metadata.length > 0) {
				example.metadata.forEach((meta) => {
					let tmp = [];
					if (
						schemaState &&
						schemaState.metadata &&
						schemaState.metadata.length > 0
					)
						tmp = schemaState.metadata.find(
							(element) => element.name === meta.element
						);
					if (tmp && Object.keys(tmp).length > 0)
						tmpRowData = { ...tmpRowData, [tmp.id]: meta.value || '' };
				});
			}

			if (Object.keys(tmpRowData) && Object.keys(tmpRowData).length > 0)
				tmpRows.push(tmpRowData);

			tmpRowData = {};
		});
	}
	if (
		from === 'predictions' &&
		predictionsState &&
		predictionsState.length > 0 &&
		Object.keys(schemaState) &&
		Object.keys(schemaState).length > 0
	) {
		predictionsState.forEach((prediction) => {
			tmpRowData = {
				...tmpRowData,
				id: prediction.id,
			};

			// INPUTS
			if (prediction && prediction.inputs && prediction.inputs.length > 0) {
				prediction.inputs.forEach((input) => {
					let tmp = [];
					if (
						schemaState &&
						schemaState.inputs &&
						schemaState.inputs.length > 0
					)
						tmp = schemaState.inputs.find(
							(element) => element.name === input.element
						);
					if (tmp && Object.keys(tmp).length > 0)
						tmpRowData = {
							...tmpRowData,
							[tmp.id]:
								typeof input.value === 'boolean' ||
								typeof input.value === 'number'
									? `${input.value}`
									: input.value || '',
						};
				});
			}

			// OUTPUTS
			if (prediction && prediction.outputs && prediction.outputs.length > 0) {
				prediction.outputs.forEach((output) => {
					let tmp = [];
					if (
						schemaState &&
						schemaState.outputs &&
						schemaState.outputs.length > 0
					)
						tmp = schemaState.outputs.find(
							(element) => element.name === output.element
						);
					if (tmp && Object.keys(tmp).length > 0)
						tmpRowData = { ...tmpRowData, [tmp.id]: output.value || '' };
				});
			}

			// METADATA
			if (prediction && prediction.metadata && prediction.metadata.length > 0) {
				prediction.metadata.forEach((meta) => {
					let tmp = [];
					if (
						schemaState &&
						schemaState.metadata &&
						schemaState.metadata.length > 0
					)
						tmp = schemaState.metadata.find(
							(element) => element.name === meta.element
						);
					if (tmp && Object.keys(tmp).length > 0)
						tmpRowData = { ...tmpRowData, [tmp.id]: meta.value || '' };
				});
			}

			if (Object.keys(tmpRowData) && Object.keys(tmpRowData).length > 0)
				tmpRows.push(tmpRowData);

			tmpRowData = {};
		});
	}

	setRows(tmpRows);
};

export const createValidatedExampleObjects = (
	parsedData,
	schemaState,
	dispatch
) => {
	let tmp = {};
	let result = [];

	const createData = (element) => {
		tmp = {
			labeling_status: element.status,
			values: Object.keys(element)
				.filter((el) => el !== 'status')
				.map((el) => ({
					element: el,
					value: element[el],
				})),
		};

		// tmp = {
		// 	status: element.status,
		// 	inputs: [],
		// 	outputs: [],
		// 	metadata: [],
		// };

		const tmpValidationErrors = [];
		// todo: add element validation

		// Object.keys(element).forEach((name) => {
		// 	// INPUTS
		// 	if (schemaState.inputs && schemaState.inputs.length > 0) {
		// 		if (schemaState.inputs.some((input) => input.name === name)) {
		// 			const input = schemaState.inputs.find((input) => input.name === name);

		// 			const validated = validateElement[input.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${input.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.inputs.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}

		// 	// OUTPUTS
		// 	if (schemaState.outputs && schemaState.outputs.length > 0) {
		// 		if (schemaState.outputs.some((output) => output.name === name)) {
		// 			const output = schemaState.outputs.find(
		// 				(output) => output.name === name
		// 			);

		// 			const validated = validateElement[output.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${output.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.outputs.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}

		// 	// METADATA
		// 	if (schemaState.metadata && schemaState.metadata.length > 0) {
		// 		if (schemaState.metadata.some((meta) => meta.name === name)) {
		// 			const meta = schemaState.metadata.find((meta) => meta.name === name);

		// 			const validated = validateElement[meta.type](name, element);

		// 			if (validated && validated.error) {
		// 				tmpValidationErrors.push(`${meta.name}: ${validated.error}`);
		// 			} else {
		// 				tmp.metadata.push({ element: name, value: element[name] });
		// 			}
		// 		}
		// 	}
		// });

		if (Object.keys(tmpValidationErrors).length > 0) {
			dispatch(ADD_ALERT({ type: 'error', message: tmpValidationErrors }));
			return null;
		}

		return tmp;
	};

	if (parsedData && parsedData.length > 0) {
		parsedData.forEach((element) => {
			const tmpData = createData(element);
			result.push(tmpData);
		});
	} else {
		result = createData(parsedData);
	}

	return result;
};

// todo: check if neccesary or need to be updated
export const parseValue = (output, element) => {
	const result = output.value;

	if (output.type === 'number') {
		return parseInt(output.value);
	}

	if (element.type === 'Boolean') {
		if (output.checked) return true;
		return false;
	}

	return result;
};
