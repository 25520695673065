import { makeStyles } from '@mui/styles';
// import { colors } from '../../../consts/colors';

const useStyles = makeStyles({
	menuContainer: {
		display: 'flex',
	},
	menuImage: {
		width: '50px',
		height: '50px',
	},
	title: {
		fontSize: 'regular',
	},
});

export default useStyles;
