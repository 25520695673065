import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import emailjs from '@emailjs/browser';

// Components
import { Box, DialogActions, FormControl, Input } from '@mui/material';
import StandardButton from '../../../Components/Shared/Buttons/StandardButton';
import StandardModal from '../../../Components/Shared/StandardModal';

// Styles
import useStyles from './styles.js';
import styles from './styles.module.css';

// Alerts
import { ADD_ALERT, REMOVE_ALERT } from '../../../redux/alerts.slice';
import { EMAIL_SENT_SUCCESS } from '../../../AlertsList/dashboardAlerts';

// Validation schema
import { sendSupportMessageSchema } from '../../../FormValidations';

function Support() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const form = useRef();

	const { info: organizationinfoState } = useSelector(
		(state) => state.organization
	);

	const [openSupportModal, setOpenSupportModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleValidate = async (e) => {
		const { name, value } = e.target;
		// Field validation
		await sendSupportMessageSchema.fields[name].validate(value).catch((err) => {
			console.log(err);
			dispatch(ADD_ALERT({ type: 'warning', message: err.errors[0] }));
			setTimeout(() => {
				dispatch(REMOVE_ALERT(err.errors[0]));
			}, 2000);
		});
	};

	const handleContact = () => {
		window.location.href = 'mailto:support@neuraptic.ai';
	};

	const formik = useFormik({
		initialValues: {
			name: '',
			companyName: organizationinfoState.name,
			message: '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { resetForm }) => {
			let hasError = false;
			// Validate form
			await sendSupportMessageSchema.validate(values).catch((err) => {
				hasError = true;
				dispatch(ADD_ALERT({ type: 'warning', message: err.errors[0] }));
				setTimeout(() => {
					dispatch(REMOVE_ALERT(err.errors[0]));
				}, 2000);
			});
			if (!hasError) {
				setIsLoading(true);

				const res = await emailjs
					.sendForm(
						process.env.REACT_APP_SUPPORT_EMAIL_SERVICE_ID,
						process.env.REACT_APP_SUPPORT_EMAIL_TEMPLATE_ID,
						form.current,
						process.env.REACT_APP_SUPPORT_EMAIL_USER_ID
					)
					.then(
						(result) => {
							console.log(result.text);
						},
						(error) => {
							console.log(error.text);
						}
					);

				// todo:
				if (res && res !== null) {
					dispatch(ADD_ALERT({ type: 'success', message: EMAIL_SENT_SUCCESS }));
					setTimeout(() => {
						dispatch(REMOVE_ALERT(EMAIL_SENT_SUCCESS));
					}, 2000);
					setOpenSupportModal(false);
				}
				setIsLoading(false);
				resetForm();
			}
		},
	});

	return (
		<>
			<div className={classes.supportContainer}>
				<p>Having some trouble? Reach out to us.</p>
				<StandardButton
					value="Contact support"
					handleClick={() => handleContact()}
					// handleClick={() => setOpenSupportModal(!openSupportModal)}
				/>
			</div>
			{/* <StandardModal
				open={openSupportModal}
				setOpen={setOpenSupportModal}
				title="Contact support:"
				content={
					<form ref={form} onSubmit={formik.handleSubmit}>
						<Box className={classes.dialogContentContainer}>
							<Box
								className={classes.dialogContent}
								style={{ maxWidth: '70%' }}
							>
								<div style={{ fontSize: '13px' }}>Name</div>
								<FormControl>
									<Input
										className={styles.common_input_type}
										id="name"
										name="name"
										type="name"
										placeholder="Name"
										onChange={formik.handleChange}
										onBlur={(e) => handleValidate(e)}
										value={formik.values.name}
									/>
								</FormControl>
							</Box>
							<Box
								className={classes.dialogContent}
								style={{ maxWidth: '70%' }}
							>
								<div style={{ fontSize: '13px' }}>Company name</div>
								<FormControl>
									<Input
										className={styles.common_input_type}
										id="companyName"
										name="companyName"
										type="companyName"
										placeholder="Company name"
										onChange={formik.handleChange}
										onBlur={(e) => handleValidate(e)}
										value={formik.values.companyName}
									/>
								</FormControl>
							</Box>
							<Box
								className={classes.dialogContent}
								style={{
									maxWidth: '70%',
									display: 'flex',
									alignItems: 'flex-start',
								}}
							>
								<div
									style={{
										fontSize: '13px',
									}}
								>
									Message
								</div>
								<FormControl>
									<textarea
										style={{
											width: '100%',
											minHeight: '150px',
											padding: '12px',
											background: '#fafbfd',
											border: '1px solid #70707045',
											borderRadius: '3px',
											letterSpacing: '0.17px',
											color: '#1a1c21',
											fontSize: '14px',
											resize: 'none',
										}}
										id="message"
										name="message"
										type="Message"
										placeholder="Message"
										onChange={formik.handleChange}
										onBlur={(e) => handleValidate(e)}
										value={formik.values.message}
									/>
								</FormControl>
							</Box>
						</Box>
						<DialogActions>
							<StandardButton
								value={isLoading ? 'Sending' : 'Send message'}
								type="submit"
								loading={isLoading}
							/>
							<StandardButton
								value="Close"
								handleClick={() => {
									formik.resetForm();
									setOpenSupportModal(!openSupportModal);
								}}
								close
							/>
						</DialogActions>
					</form>
				}
			/> */}
		</>
	);
}

export default Support;
