/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

// Manage roles & permissions
import { HasAccess } from '@permify/react-role';

// Components
import {
	Box,
	Grid,
	Typography,
	Skeleton,
	IconButton,
	/* Toolbar  Link */
} from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import FixedFlow from '../../../../Components/Core/Task/Schema/FixedFlow';
import CardItem from '../../../../Components/Shared/CardItem';
import StandardButton from '../../../../Components/Shared/Buttons/StandardButton';
import StandardModal from '../../../../Components/Shared/StandardModal';
import AccessDenied from '../../../../Components/Core/AccessDenied';
import StatusBar from '../../../../Components/Shared/StatusBar';

// Styles
import useStyles from './styles';
import { measures } from '../../../../consts/sizes';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';
import { getIntervalDate, getLocalDateTime } from '../../../../services/date';

// Redux
import { ADD_ALERT, REMOVE_ALERT } from '../../../../redux/alerts.slice';

import requestFactory from '../../../../services/request.factory';
import { colors } from '../../../../consts/colors';

export function TaskStatus({ setCurrentTab }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const {
		currentTask: currentTaskState,
		currentTaskSettings: currentTaskSettingsState,
	} = useSelector((state) => state.tasks);
	const { totalExamples: totalExamplesState } = useSelector(
		(state) => state.examples
	);
	const { accessToken } = useSelector((state) => state.user);
	const { productionAIRelease: productionAIReleaseState } = useSelector(
		(state) => state.aireleases
	);
	const {
		info: taskInfoLoaderState,
		services: taskServiceLoaderState,
		productionRelease: taskProductionReleaseLoaderState,
	} = useSelector((state) => state.loaders.task.status);
	const { location: userLocationState } = useSelector((state) => state.user);

	const { info: currentOrganizationInfo } = useSelector(
		(state) => state.organization
	);
	const { displayedUsers: organizationUsersState } = useSelector(
		(state) => state.organization.users
	);
	const { apps: organizationAppsState } = useSelector(
		(state) => state.organization
	);

	const [servicesStatus, setServicesStatus] = useState({
		active_learning: 0,
		continual_learning: 0,
		monitoring: 0,
		inference: 0,
	});
	const [modifiedByInfo, setModifiedByInfo] = useState({});

	// Modals
	const [openShowSchema, setOpenShowSchema] = useState(false);

	const handleCloseShowModal = () => {
		setOpenShowSchema(false);
	};

	const getServicesStatus = async () => {
		let tmpServices = servicesStatus;
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/inference`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				inference: res.status,
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/monitoring`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				monitoring: res.status,
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/active-learning`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				active_learning: res.status,
			};
		});
		await requestFactory({
			type: 'GET',
			url: `/tasks/${currentTaskState.uuid}/services/continual-learning`,
			accessToken,
			dispatch,
		}).then((res) => {
			tmpServices = {
				...tmpServices,
				continual_learning: res.status,
			};
		});

		setServicesStatus(tmpServices);
	};

	useEffect(() => {
		if (currentTaskState.uuid) getServicesStatus();
	}, [currentTaskSettingsState]);

	const getModifiedByInfo = async () => {
		if (
			currentTaskState.modified_by &&
			Object.keys(currentTaskState.modified_by).length > 0
		) {
			if (currentTaskState.modified_by.type === 'user') {
				const user = await requestFactory({
					type: 'GET',
					url: `/organizations/${currentOrganizationInfo.id}/users/${currentTaskState.modified_by.id}`,
					accessToken,
					dispatch,
				});
				if (user) setModifiedByInfo(user);
			} else {
				const app = await requestFactory({
					type: 'GET',
					url: `/organizations/${currentOrganizationInfo.id}/apps/${currentTaskState.modified_by.id}/api-key`,
					accessToken,
					dispatch,
				});
				if (app) setModifiedByInfo(app);
			}
		}
	};

	useEffect(() => {
		getModifiedByInfo();
	}, [currentTaskState]);

	const handleCopyTaskId = (uuid) => {
		navigator.clipboard.writeText(uuid);
		dispatch(ADD_ALERT({ type: 'success', message: 'Task UUID copied' }));
		setTimeout(() => {
			dispatch(REMOVE_ALERT('Task UUID copied'));
		}, 2000);
	};

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			<Grid container spacing={3}>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							padding: 1,
							// marginBottom: 3,
						}}
						type="noIcon"
						title="Status"
					>
						{!taskInfoLoaderState ? (
							<Box className={classes.descriptionContent}>
								{currentTaskState?.status && (
									<>
										{Object.keys(currentTaskState)?.length > 0 &&
											currentTaskState.status &&
											currentTaskState.status.status_code && (
												<StatusBar
													type="task"
													code={currentTaskState.status.status_code}
													name={currentTaskState.status.display_name}
													description={currentTaskState.status.description}
												/>
											)}
										{/* <StatusBar type="task" status={currentTaskState.status} /> */}
										<h3 style={{ marginBottom: '0px' }}>Name:</h3>
										{currentTaskState.name || ''}
										<h3 style={{ marginBottom: '0px' }}>Description:</h3>
										{currentTaskState.description || ''}
										<h3 style={{ marginBottom: '0px' }}>Last modification:</h3>
										{currentTaskState.modified_at &&
											getIntervalDate(
												getLocalDateTime(
													currentTaskState.modified_at,
													userLocationState
												)
											)}
										<h3 style={{ marginBottom: '0px' }}>Task ID:</h3>
										<div style={{ display: 'flex', alignItems: 'center' }}>
											{Object.keys(currentTaskState).length > 0 && (
												<>
													{` ${currentTaskState.uuid}`}
													<IconButton
														sx={{ '& > *': { fontSize: '18px' } }}
														edge="end"
														aria-label="copy api key"
														onClick={() =>
															handleCopyTaskId(currentTaskState.uuid)
														}
													>
														<ContentCopyOutlinedIcon />
													</IconButton>
												</>
											)}
										</div>
									</>
								)}
							</Box>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Name:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '50%',
										}}
									/>
								</div>
								<h3>Description:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
								<h3>Last modification:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
								<h3>Task ID:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '18px',
										width: '100%',
									}}
								/>
							</div>
						)}
					</CardItem>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					{/* Examples */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
						}}
						type="noIcon"
						title="Services status"
					>
						{!taskServiceLoaderState &&
						currentTaskSettingsState.active_learning &&
						currentTaskSettingsState.continual_learning &&
						currentTaskSettingsState.inference &&
						currentTaskSettingsState.monitoring ? (
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
									gap: '24px',
								}}
							>
								{servicesStatus.active_learning !== 0 ? (
									currentTaskSettingsState.active_learning.enabled ? (
										Object.keys(servicesStatus.active_learning)?.length > 0 && (
											<StatusBar
												type="service"
												status={servicesStatus.active_learning}
											/>
										)
									) : (
										<StatusBar type="service" code="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								{servicesStatus.continual_learning !== 0 ? (
									currentTaskSettingsState.continual_learning.enabled ? (
										Object.keys(servicesStatus.continual_learning)?.length >
											0 &&
										servicesStatus.continual_learning && (
											<StatusBar
												type="service"
												status={servicesStatus.continual_learning}
											/>
										)
									) : (
										<StatusBar type="service" code="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								{servicesStatus.inference !== 0 ? (
									currentTaskSettingsState.inference.enabled ? (
										Object.keys(servicesStatus.inference)?.length > 0 &&
										servicesStatus.inference && (
											<StatusBar
												type="service"
												status={servicesStatus.inference}
											/>
										)
									) : (
										<StatusBar type="service" code="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
								{servicesStatus.monitoring !== 0 ? (
									currentTaskSettingsState.monitoring.enabled ? (
										Object.keys(servicesStatus.monitoring)?.length > 0 &&
										servicesStatus.monitoring && (
											<StatusBar
												type="service"
												status={servicesStatus.monitoring}
											/>
										)
									) : (
										<StatusBar type="service" code="service_disabled" />
									)
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								)}
							</div>
						) : (
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
									flexDirection: 'column',
								}}
							>
								<h3 style={{ marginBottom: '0px' }}>Active learning:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3 style={{ marginBottom: '0px' }}>Coninual learning:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3 style={{ marginBottom: '0px' }}>Inference:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
								<h3 style={{ marginBottom: '0px' }}>Monitoring:</h3>
								<Skeleton
									variant="text"
									style={{
										height: '48px',
										width: '100%',
									}}
								/>
							</div>
						)}
					</CardItem>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					xl={4}
					sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
				>
					{/* Models */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
						}}
						type="noIcon"
						title="Production AI release"
					>
						{!taskProductionReleaseLoaderState ? (
							productionAIReleaseState &&
							Object.keys(productionAIReleaseState).length > 0 ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'flex-start',
										flexDirection: 'column',
									}}
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginBottom: -12,
											gap: '6px',
										}}
									>
										<h3>Version: </h3>
										{productionAIReleaseState.docker_image?.tag || ''}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginBottom: -12,
											gap: '6px',
										}}
									>
										<h3>ID: </h3>
										{productionAIReleaseState.id}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '6px',
										}}
									>
										<h3>Creation date:</h3>
										{format(
											new Date(
												getLocalDateTime(
													productionAIReleaseState?.created_at,
													userLocationState
												)
											),
											'yyyy/MM/dd, HH:mm'
										)}
									</div>
									<div
										style={{
											width: '100%',
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<StandardButton
											value="Show schema"
											handleClick={() => setOpenShowSchema(true)}
										/>
									</div>
								</div>
							) : (
								<div>No Production releases found</div>
							)
						) : (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Version:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>ID:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										gap: 12,
										alignItems: 'center',
									}}
								>
									<h3>Creation date:</h3>
									<Skeleton
										variant="text"
										style={{
											height: '18px',
											width: '100%',
										}}
									/>
								</div>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '100%',
										}}
									/>
								</div>
							</div>
						)}
					</CardItem>

					{/* Examples */}
					<CardItem
						elevation={measures.cardItemElevation}
						sx={{
							display: 'flex',
							'&:hover': {
								cursor: 'pointer',
								outline: `2px solid ${colors.buttonPrimaryColor}`,
							},
						}}
						type="noIcon"
						title="Total examples"
						onClick={() => setCurrentTab('examples')}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: 12,
							}}
						>
							<StorageIcon sx={{ color: '#666666' }} />
							<Typography
								variant="span"
								sx={{
									fontSize: 22,
									color: '#666666',
								}}
							>
								{typeof totalExamplesState === 'number' ? (
									totalExamplesState
								) : (
									<Skeleton
										variant="text"
										style={{
											height: '48px',
											width: '150px',
										}}
									/>
								)}
							</Typography>
						</div>
					</CardItem>
				</Grid>

				{/* <Grid container spacing={3} sx={{ paddingLeft: 3, marginTop: 3 }}>
					<Grid item xs={12} md={4}>
						// Training 
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								marginBottom: 3,
							}}
							title="Training"
							titleicon={<ChangeHistoryIcon />}
						>
							<Training />
						</CardItem>
					</Grid>

					<Grid item xs={6}>
            // Training 
						<CardItem
							elevation={measures.cardItemElevation}
							sx={{
								display: 'flex',
								marginBottom: 3,
							}}
							title="Documentation"
							titleicon={<MenuBookIcon />}
						>
							<Documentation />
						</CardItem>
					</Grid> 
         
				</Grid> */}
			</Grid>
			{openShowSchema && (
				<StandardModal
					open={openShowSchema}
					setOpen={handleCloseShowModal}
					title="Production AI Release schema"
					content={
						<Box sx={{ height: '584px' }}>
							<FixedFlow tmpSchema={productionAIReleaseState.task_schema} />
						</Box>
					}
					actions={
						<StandardButton
							close
							value="Close"
							handleClick={handleCloseShowModal}
						/>
					}
				/>
			)}
		</HasAccess>
	);
}

TaskStatus.propTypes = {
	setCurrentTab: PropTypes.string,
};
