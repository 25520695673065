import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Manage roles and permissions
import { HasAccess } from '@permify/react-role';

// Components
import { Grid, Skeleton } from '@mui/material';
import PageRow from '../../../../Components/Shared/PageRow';
import Section from '../../../../Components/Shared/Section';
import CircleProgress from '../../../../Components/Shared/CircleProgress';
import AccessDenied from '../../../../Components/Core/AccessDenied';

// Redux
import { UPDATE_ORGANIZATION_INFO } from '../../../../redux/organization.slice';

// Syles
import { colors } from '../../../../consts/colors';
import { useStyles } from './styles';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';

function Usage() {
	const classes = useStyles();

	const dispatch = useDispatch();

	const organizationInfoState = useSelector((state) => state.organization.info);
	const organizationSubscriptionPlanState = useSelector(
		(state) => state.organization.subscription.plan
	);
	const organizationSubscriptionUsageState = useSelector(
		(state) => state.organization.subscription.usage
	);
	const { organizationSettings: organizationSettingsLoaderManager } =
		useSelector((state) => state.loaders);

	const [tmpOrganizationData, setTmpOrganizationData] = useState({
		name: (organizationInfoState !== null && organizationInfoState.name) || '',
		trn: (organizationInfoState !== null && organizationInfoState.trn) || '',
		domain:
			(organizationInfoState !== null && organizationInfoState.domain) || '',
		address:
			(organizationInfoState !== null && organizationInfoState.address) || '',
	});

	useEffect(() => {
		setTmpOrganizationData({
			...tmpOrganizationData,
			name: organizationInfoState !== null && organizationInfoState.name,
			trn: organizationInfoState !== null && organizationInfoState.trn,
			domain: organizationInfoState !== null && organizationInfoState.domain,
			address: organizationInfoState !== null && organizationInfoState.address,
		});
	}, [organizationInfoState]);

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="organization.read"
			renderAuthFailed={<AccessDenied />}
		>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				sx={{
					width: '100%',
					marginTop: '24px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<PageRow
					column1={
						<Section title="Usage">
							<span style={{ fontSize: 'small', color: colors.darkGray }}>
								This is your organization usage of the{' '}
								{process.env.REACT_APP_APP_NAME} subscription.
							</span>
						</Section>
					}
					column2={
						<div>
							<div className={classes.usageCharts}>
								<div className={classes.chartContainer}>
									{organizationSettingsLoaderManager.usage ||
									organizationSubscriptionPlanState === {} ||
									organizationInfoState === {} ? (
										<>
											<Skeleton variant="circular" width={40} height={40} />
											<Skeleton variant="text" width={50} />
										</>
									) : (
										<>
											<CircleProgress
												value={organizationSubscriptionUsageState.num_users}
												max={organizationSubscriptionPlanState.max_users}
											/>
											<span className={classes.chartInfo}>Users</span>
											<span className={classes.chartInfo}>
												{organizationSubscriptionUsageState.num_users}/
												{organizationSubscriptionPlanState.max_users}
											</span>
										</>
									)}
								</div>
								<div className={classes.chartContainer}>
									{organizationSettingsLoaderManager.usage ||
									organizationSubscriptionPlanState === {} ||
									organizationInfoState === {} ? (
										<>
											<Skeleton variant="circular" width={40} height={40} />
											<Skeleton variant="text" width={50} />
										</>
									) : (
										<>
											<CircleProgress
												value={
													organizationSubscriptionUsageState.num_collaborators
												}
												max={
													organizationSubscriptionPlanState.max_collaborators
												}
											/>
											<span className={classes.chartInfo}>Collaborators</span>
											<span className={classes.chartInfo}>
												{organizationSubscriptionUsageState.num_collaborators}/
												{organizationSubscriptionPlanState.max_collaborators}
											</span>
										</>
									)}
								</div>
								<div className={classes.chartContainer}>
									{organizationSettingsLoaderManager.usage ||
									organizationSubscriptionPlanState === {} ||
									organizationInfoState === {} ? (
										<>
											<Skeleton variant="circular" width={40} height={40} />
											<Skeleton variant="text" width={50} />
										</>
									) : (
										<>
											<CircleProgress
												value={organizationSubscriptionUsageState.num_roles}
												max={organizationSubscriptionPlanState.max_roles}
											/>
											<span className={classes.chartInfo}>Roles</span>
											<span className={classes.chartInfo}>
												{organizationSubscriptionUsageState.num_roles}/
												{organizationSubscriptionPlanState.max_roles}
											</span>
										</>
									)}
								</div>
								<div className={classes.chartContainer}>
									{organizationSettingsLoaderManager.usage ||
									organizationSubscriptionPlanState === {} ||
									organizationInfoState === {} ? (
										<>
											<Skeleton variant="circular" width={40} height={40} />
											<Skeleton variant="text" width={50} />
										</>
									) : (
										<>
											<CircleProgress
												value={organizationSubscriptionUsageState.num_tasks}
												max={organizationSubscriptionPlanState.max_tasks}
											/>
											<span className={classes.chartInfo}>Tasks</span>
											<span className={classes.chartInfo}>
												{organizationSubscriptionUsageState.num_tasks}/
												{organizationSubscriptionPlanState.max_tasks}
											</span>
										</>
									)}
								</div>
								<div className={classes.chartContainer}>
									{organizationSettingsLoaderManager.usage ||
									organizationSubscriptionPlanState === {} ||
									organizationInfoState === {} ? (
										<>
											<Skeleton variant="circular" width={40} height={40} />
											<Skeleton variant="text" width={50} />
										</>
									) : (
										<>
											<CircleProgress
												value={
													organizationSubscriptionUsageState.num_deployments
												}
												max={organizationSubscriptionPlanState.max_deployments}
											/>
											<span className={classes.chartInfo}>Deployments</span>
											<span className={classes.chartInfo}>
												{organizationSubscriptionUsageState.num_deployments}/
												{organizationSubscriptionPlanState.max_deployments}
											</span>
										</>
									)}
								</div>
								<div className={classes.chartContainer}>
									{organizationSettingsLoaderManager.usage ||
									organizationSubscriptionPlanState === {} ||
									organizationInfoState === {} ? (
										<>
											<Skeleton variant="circular" width={40} height={40} />
											<Skeleton variant="text" width={50} />
										</>
									) : (
										<>
											<CircleProgress
												value={organizationSubscriptionUsageState.num_apps}
												max={organizationSubscriptionPlanState.max_apps}
											/>
											<span className={classes.chartInfo}>Apps</span>
											<span className={classes.chartInfo}>
												{organizationSubscriptionUsageState.num_apps}/
												{organizationSubscriptionPlanState.max_apps}
											</span>
										</>
									)}
								</div>
								<div className={classes.chartContainer}>
									{organizationSettingsLoaderManager.usage ||
									organizationSubscriptionPlanState === {} ||
									organizationInfoState === {} ? (
										<>
											<Skeleton variant="circular" width={40} height={40} />
											<Skeleton variant="text" width={50} />
										</>
									) : (
										<>
											<CircleProgress
												value={Math.round(
													organizationSubscriptionUsageState.space_usage /
														(1024 * 1024 * 1024)
												)}
												max={
													organizationSubscriptionPlanState.space_limit /
													(1024 * 1024 * 1024)
												}
											/>
											<span className={classes.chartInfo}>Space (GB)</span>
											<span className={classes.chartInfo}>
												{(
													organizationSubscriptionUsageState.space_usage /
													(1024 * 1024 * 1024)
												).toFixed(2)}
												/
												{organizationSubscriptionPlanState.space_limit /
													(1024 * 1024 * 1024)}
											</span>
										</>
									)}
								</div>
							</div>
						</div>
					}
				/>
			</Grid>
		</HasAccess>
	);
}

export default Usage;
