import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Styles
import useStyles from './styles';

import styles from './styles.module.css';
import { CustomTooltip } from '../../CustomTooltip';

function StandardButton({
	type,
	variant,
	handleClick,
	handleChange,
	value,
	linkTo = null,
	close = null,
	loading,
	icon,
	id,
	name,
	accept,
	disabled,
	tooltip = null,
}) {
	const classes = useStyles();
	const [anchorDropdown, setAnchorDropdown] = useState(null);
	const openDropdown = Boolean(anchorDropdown);

	const handleLinkTo = () => {
		if (linkTo) window.location.replace(`https://${linkTo}`);
	};

	const handleClickDropdown = (event) => {
		setAnchorDropdown(event.currentTarget);
	};
	const handleCloseDropdown = (e) => {
		if (e.target.id) handleChange(e);
		setAnchorDropdown(null);
	};

	if (loading) {
		return (
			<LoadingButton
				loading
				variant="outlined"
				className={classes.loadingButton}
			>
				{value}
			</LoadingButton>
		);
	}

	if (type === 'uploadFile') {
		if (loading) {
			return (
				<LoadingButton
					loading
					variant="outlined"
					className={classes.loadingButton}
				>
					{value}
				</LoadingButton>
			);
		}
		return (
			<Button
				variant="contained"
				component="label"
				className={classes.button}
				startIcon={icon}
				// loading
			>
				{value}
				<input
					hidden
					accept={accept}
					multiple
					type="file"
					onClick={handleClick}
					onChange={handleChange}
					id={id}
					name={name}
				/>
			</Button>
		);
	}

	if (type === 'danger') {
		return (
			<Button
				className={styles.danger_btn}
				onClick={() => {
					if (linkTo) handleLinkTo();
					if (handleClick) handleClick();
				}}
				variant="contained"
				color="error"
				size="small"
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'textButton') {
		return (
			<Button
				type="button"
				className={classes.textButton}
				onClick={() => {
					if (linkTo) handleLinkTo();
					if (handleClick) handleClick();
				}}
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'disabled') {
		if (tooltip) {
			return (
				<Tooltip title={tooltip}>
					<Button
						className={`${classes.button} ${classes.closeButton}`}
						variant="contained"
						disabled
						startIcon={icon}
					>
						{value}
					</Button>
				</Tooltip>
			);
		}
		return (
			<Button
				className={`${classes.button} ${classes.closeButton}`}
				variant="contained"
				disabled
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'filled') {
		return (
			<Button
				className={classes.button}
				onClick={() => {
					if (handleClick) handleClick();
				}}
				variant="contained"
				disabled={disabled || false}
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'with-icon') {
		return (
			<Button
				className={classes.withIcon}
				onClick={() => {
					if (handleClick) handleClick();
				}}
				variant="contained"
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}

	if (type === 'submit') {
		return (
			<Button
				className={classes.button}
				variant="contained"
				size="small"
				type="submit"
				onClick={() => {
					if (handleClick) handleClick();
				}}
				loading
				startIcon={icon}
			>
				{value}
			</Button>
		);
	}
	if (type === 'icon') {
		if (variant === 'dropdown') {
			if (tooltip)
				return (
					<CustomTooltip title={tooltip}>
						<div>
							<Button
								className={`${classes.button} ${
									close !== null && classes.closeButton
								}`}
								onClick={(e) => {
									if (linkTo) handleLinkTo();
									if (handleClick) handleClickDropdown(e);
								}}
								variant="contained"
								size="small"
								sx={{
									padding: '6px !important',
									minWidth: '0px !important',
								}}
								disabled={variant === 'disabled'}
							>
								{icon}
							</Button>
							<Menu
								anchorEl={anchorDropdown}
								open={openDropdown}
								onClose={handleCloseDropdown}
							>
								{value.map((val, index) => (
									<MenuItem
										key={index}
										id={val.value}
										onClick={(e) => handleCloseDropdown(e)}
									>
										{val.display}
									</MenuItem>
								))}
							</Menu>
						</div>
					</CustomTooltip>
				);
			return (
				<div>
					<Button
						className={`${classes.button} ${
							close !== null && classes.closeButton
						}`}
						onClick={(e) => {
							if (linkTo) handleLinkTo();
							if (handleClick) handleClickDropdown(e);
						}}
						variant="contained"
						size="small"
						sx={{
							padding: '6px !important',
							minWidth: '0px !important',
						}}
						disabled={variant === 'disabled'}
					>
						{icon}
					</Button>
					<Menu
						anchorEl={anchorDropdown}
						open={openDropdown}
						onClose={handleCloseDropdown}
					>
						{value.map((val, index) => (
							<MenuItem
								key={index}
								id={val.value}
								onClick={(e) => handleCloseDropdown(e)}
							>
								{val.display}
							</MenuItem>
						))}
					</Menu>
				</div>
			);
		}

		if (tooltip)
			return (
				<CustomTooltip title={tooltip}>
					<div>
						<Button
							className={`${classes.button} ${
								close !== null ||
								(variant === 'disabled' && classes.closeButton)
							}`}
							onClick={() => {
								if (linkTo) handleLinkTo();
								if (handleClick) handleClick();
							}}
							variant="contained"
							size="small"
							sx={{
								padding: '6px !important',
								minWidth: '0px !important',
							}}
							disabled={variant === 'disabled'}
						>
							{icon}
						</Button>
					</div>
				</CustomTooltip>
			);

		return (
			<div>
				<Button
					className={`${classes.button} ${
						close !== null || (variant === 'disabled' && classes.closeButton)
					}`}
					onClick={() => {
						if (linkTo) handleLinkTo();
						if (handleClick) handleClick();
					}}
					variant="contained"
					size="small"
					sx={{
						padding: '6px !important',
						minWidth: '0px !important',
					}}
					disabled={variant === 'disabled'}
				>
					{icon}
				</Button>
			</div>
		);
	}

	return (
		<Button
			className={`${classes.button} ${close !== null && classes.closeButton}`}
			onClick={() => {
				if (linkTo) handleLinkTo();
				if (handleClick) handleClick();
			}}
			variant="contained"
			size="small"
			startIcon={icon}
		>
			{value}
		</Button>
	);
}

StandardButton.propTypes = {
	type: PropTypes.string,
	variant: PropTypes.string,
	tooltip: PropTypes.string,
	handleClick: PropTypes.func,
	linkTo: PropTypes.string,
	value: PropTypes.any,
	close: PropTypes.bool,
	loading: PropTypes.bool,
	icon: PropTypes.element,
	handleChange: PropTypes.func,
	id: PropTypes.string,
	name: PropTypes.string,
	accept: PropTypes.string,
	disabled: PropTypes.bool,
};

export default StandardButton;
