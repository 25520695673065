import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	companyNameInput: {
		maxWidth: '203px',
		border: '1px solid #70707045',
		borderRadius: '5px',
		background: '#FAFBFD',
	},
});

export default useStyles;
