import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	documentationContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
});

export default useStyles;
