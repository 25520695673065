import * as React from 'react';
import { useSelector } from 'react-redux';

// Components
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearBuffer() {
	const progressRef = React.useRef(() => {});

	const {
		loadingProgress: loadingProgressState,
		loadingBuffer: loadingBufferState,
	} = useSelector((state) => state.loaders);

	React.useEffect(() => {
		const timer = setInterval(() => {
			progressRef.current();
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, []);
	if (typeof loadingProgressState === 'number')
		return (
			<Box sx={{ padding: '12px 10%' }}>
				<LinearProgress
					sx={{ height: '10px', borderRadius: '12px' }}
					variant="buffer"
					value={loadingProgressState}
					valueBuffer={loadingBufferState}
				/>
			</Box>
		);

	return (
		<Box sx={{ padding: '12px 10%' }}>
			<LinearProgress
				sx={{ height: '10px', borderRadius: '12px', backgroundColor: 'red' }}
				variant="buffer"
				value={0}
				valueBuffer={100}
				color="error"
			/>
		</Box>
	);
}
