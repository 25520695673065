import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validate } from 'react-email-validator';

// Components
import { FormControl, Grid, IconButton, Input } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PageRow from '../../../Components/Shared/PageRow';
import Section from '../../../Components/Shared/Section';
import StandardButton from '../../../Components/Shared/Buttons/StandardButton';
import PageTitle from '../../../Components/Shared/PageTitle';
import StandardModal from '../../../Components/Shared/StandardModal';

// Pages
import Info from './Info';
import Users from './Users';
import Usage from './Usage';
import Subscription from './Subscription';
import Roles from './Roles';
import Collaborators from './Collaborators';

// Redux
// import {
// 	ADD_OWNER_EMAIL,
// 	REMOVE_OWNER_EMAIL,
// 	DELETE_ORGANIZATION,
// 	UPDATE_ORGANIZATION_NAME,
// } from '../../../redux/organization.slice';

// CONSTS
import {
	DUPLICATED_EMAIL,
	EMPTY_EMAIL,
	INVALID_EMAIL,
} from '../../../AlertsList/organizationSettingsAlerts';
import { measures } from '../../../consts/sizes';

// Syles
import { colors } from '../../../consts/colors';
import useStyles from './styles';

import styles from './styles.module.css';
import Apps from './Apps';

function OrganizationSettings() {
	const organizationInfo = useSelector((state) => state.organization.info);

	const [currentTab, setCurrentTab] = useState('info');

	return (
		organizationInfo !== null && (
			<>
				<PageTitle
					title="Organization settings"
					organizationId={organizationInfo.uuid}
					tabs={{
						currentTab,
						setCurrentTab,
						tabsArray: [
							{
								label: 'Info',
								linkTo: 'info',
							},
							{
								label: 'Usage',
								linkTo: 'usage',
							},
							{
								label: 'Subscription',
								linkTo: 'subscription',
							},
							{
								label: 'Users',
								linkTo: 'users',
							},
							{
								label: 'Collaborators',
								linkTo: 'collaborators',
							},
							{
								label: 'Roles',
								linkTo: 'roles',
							},
							{
								label: 'Apps',
								linkTo: 'apps',
							},
						],
					}}
				/>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					sx={{
						px: {
							xs: 1,
							sm: 4,
							md: measures.mdGeneralMargin,
							lg: measures.lgGeneralMargin,
						},
						width: '100%',
						marginTop: '50px',
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{currentTab === 'info' && <Info />}
					{currentTab === 'users' && <Users />}
					{currentTab === 'usage' && <Usage />}
					{currentTab === 'subscription' && <Subscription />}
					{currentTab === 'collaborators' && <Collaborators />}
					{currentTab === 'roles' && <Roles />}
					{currentTab === 'apps' && <Apps />}
				</Grid>
			</>
		)
	);
}

export default OrganizationSettings;
