import React from 'react';
import PropTypes from 'prop-types';

// Components
import { TextField } from '@mui/material';

// Styles
import useStyles from './styles';

// import styles from './styles.module.css';

export const TextInput = ({ ...props }) => {
	const classes = useStyles();

	return (
		<TextField
			placeholder={props.placeholder}
			variant="outlined"
			className={classes.companyNameInput}
			size="small"
			hiddenLabel
			onChange={props.onChange}
			{...props}
		/>
	);
};

TextInput.propTypes = {
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func,
};
