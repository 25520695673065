import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// Components
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
	FormControlLabel,
	Grid,
	Input,
	Pagination,
	Switch,
	TablePagination,
	TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
	faFileCsv,
	faKeyboard,
	faCloudArrowUp,
	faCheck,
	faBan,
	faTag,
	faFilePen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line, LineChart } from 'recharts';
import StandardModal from '../../../../../../Components/Shared/StandardModal';
import StandardButton from '../../../../../../Components/Shared/Buttons/StandardButton';
import { Loader } from '../../../../../../Components/Shared/Loader';
import PageRow from '../../../../../../Components/Shared/PageRow';
import Section from '../../../../../../Components/Shared/Section';

// Styles
import { useStyles } from './styles';
import { elementTypeToIconMap } from '../../../../../../services/tasks';
import styles from './styles.module.css';
import { colors } from '../../../../../../consts/colors';

// Services
import {
	createCSVTemplate,
	getFilesFromParsedCSV,
	parseCSVFile,
	parseCSVTimeSeriesFile,
} from '../../csv.services';
import { createValidatedExampleObjects } from '../../examples.services';
import { getLocalDateTime } from '../../../../../../services/date';

// Redux
import {
	CREATE_EXAMPLE,
	GET_EXAMPLE_FILE,
	GET_EXAMPLES,
	UPDATE_EXAMPLE,
} from '../../../../../../redux/examples.slice';
import {
	IMAGE_FILE_UPLOAD_ERROR,
	IMAGE_FILE_UPLOAD_SUCCESS,
} from '../../../../../../AlertsList/examplesAlerts';
import { ADD_ALERT, REMOVE_ALERT } from '../../../../../../redux/alerts.slice';
import { CREATE_TASK_FILE } from '../../../../../../redux/tasks.slice';
import requestFactory from '../../../../../../services/request.factory';
import { CustomPagination } from '../../../../../../Components/Shared/CustomPagination';
import { GET_SCHEMA_NODE_CATEGORIES } from '../../../../../../redux/schema.slice';

export const CreateOrUpdateExampleModal = ({
	open,
	setOpen,
	allColumns,
	type,
	step = 0,
	currentRowId,
	setCurrentRowId,
	setSelectedRows,
	setOpenDeleteExampleModal,
}) => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const hiddenFileInput = useRef(null);

	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);
	const { accessToken } = useSelector((state) => state.user);
	const { currentExample: currentExampleState } = useSelector(
		(state) => state.examples
	);
	const { categories: categoriesState, schema: schemaState } = useSelector(
		(state) => state.schema
	);
	const { location: userLocationState } = useSelector((state) => state.user);

	const [creationStep, setCreationStep] = useState(step);
	const [fileToUpload, setFileToUpload] = useState(null);
	const [isLoadingFile, setIsLoadingFile] = useState(false);
	const [csvReport, setCsvReport] = useState({});
	// const [CSVTmpData, setCSVTmpData] = useState([]);
	const [manualExample, setManualExample] = useState({
		values: [],
		labeling_status: 'unlabeled',
		// tags: { elements: [], groups: [] },
	});
	const [csvData, setCsvData] = useState([]);
	const [uploadStatus, setUploadStatus] = useState('Processing CSV file...');
	const [tmpImage, setTmpImage] = useState({});

	// CSV upload errors
	const [csvErrors, setCsvErrors] = useState([]);
	const [openCsvErrorModal, setOpenCsvErrorModal] = useState(false);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(4);

	const getThumbnails = async () => {
		const tmpArray = manualExample.values.filter(
			(example) =>
				example.value !== '' && Object.keys(example).includes('thumbnailUrl')
		);
		if (manualExample.values.length > 0) {
			if (tmpArray.length > 0) {
				await Promise.all(
					tmpArray.map(async (example) =>
						requestFactory({
							type: 'GET',
							url: `/tasks/${currentTaskState.id}/files/${example.value}`,
							accessToken,
							dispatch,
						})
					)
				).then((res) => {
					const tmpExamples = manualExample.values.map((example) => {
						if (res.find((r) => r.id === example.value))
							return {
								...example,
								thumbnailUrl: res.find((r) => r.id === example.value)[
									'download_url'
								],
							};
						return example;
					});
					setManualExample({ ...manualExample, values: tmpExamples });
				});
			}
		}
	};

	const getExample = async (tmp) => {
		let newTmp = tmp;
		allColumns.forEach(async (column) => {
			if (column.field === 'status')
				newTmp = {
					...tmp,
					labeling_status: currentExampleState.labeling_status,
				};
			// todo: add status to example
			if (column.field !== 'status' && column.field !== 'edit') {
				if (
					column.valueType === 'generic_file' ||
					column.valueType === 'document_file' ||
					column.valueType === 'image_file' ||
					column.valueType === 'video_file' ||
					column.valueType === 'audio_file'
				) {
					if (
						currentExampleState[
							column.fieldType !== 'metadata'
								? `${column.fieldType}s`
								: column.fieldType
						] &&
						currentExampleState[
							column.fieldType !== 'metadata'
								? `${column.fieldType}s`
								: column.fieldType
						].find((example) => example.element === column.field) &&
						currentExampleState[
							column.fieldType !== 'metadata'
								? `${column.fieldType}s`
								: column.fieldType
						].find((example) => example.element === column.field).value &&
						currentExampleState[
							column.fieldType !== 'metadata'
								? `${column.fieldType}s`
								: column.fieldType
						].find((example) => example.element === column.field).value !== ''
					) {
						Promise.resolve(
							requestFactory({
								type: 'GET',
								url: `/tasks/${currentTaskState.id}/files/${
									currentExampleState[
										column.fieldType !== 'metadata'
											? `${column.fieldType}s`
											: column.fieldType
									].find((example) => example.element === column.field).value
								}`,
								accessToken,
								dispatch,
							})
						).then((res) => {
							const tmpExamples = newTmp.values.map((example) => {
								if (example.value === res.id)
									return { ...example, thumbnailUrl: res.download_url };
								return example;
							});
							setManualExample({ ...newTmp, values: tmpExamples });
						});

						newTmp = {
							...newTmp,
							values: newTmp.values.map((element) => {
								if (element.element === column.field) {
									return {
										element: column.field,
										value:
											(currentExampleState[
												column.fieldType !== 'metadata'
													? `${column.fieldType}s`
													: column.fieldType
											] &&
												currentExampleState[
													column.fieldType !== 'metadata'
														? `${column.fieldType}s`
														: column.fieldType
												].find((example) => example.element === column.field)
													.value) ||
											'',
										thumbnailUrl: '',
										tmpInfo: '',
										isLoading: false,
									};
								}
								return element;
							}),
						};
					}
				} else {
					newTmp = {
						...newTmp,
						values: newTmp.values.map((element) => {
							if (element.element === column.field) {
								return {
									element: column.field,
									value:
										(currentExampleState[
											column.fieldType !== 'metadata'
												? `${column.fieldType}s`
												: column.fieldType
										] &&
											currentExampleState[
												column.fieldType !== 'metadata'
													? `${column.fieldType}s`
													: column.fieldType
											].find((example) => example.element === column.field)
												.value) ||
										'',
								};
							}
							return element;
						}),
					};
				}
			}
		});
		setManualExample(newTmp);
	};

	useEffect(() => {
		if (!open) {
			setCreationStep(0);
			setFileToUpload(null);
		}

		if (allColumns.length > 0) {
			let tmp = manualExample;
			allColumns.forEach((column) => {
				if (column.field !== 'status' && column.field !== 'edit')
					if (
						column.valueType === 'generic_file' ||
						column.valueType === 'document_file' ||
						column.valueType === 'image_file' ||
						column.valueType === 'video_file' ||
						column.valueType === 'audio_file'
					) {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: '',
									thumbnailUrl: '',
									tmpInfo: '',
									isLoading: false,
								},
							],
						};
					} else if (column.valueType === 'boolean') {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: false,
								},
							],
						};
					} else if (column.valueType === 'datetime') {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: null,
								},
							],
						};
					} else {
						tmp = {
							...tmp,
							values: [
								...tmp.values,
								{
									element: column.field,
									value: '',
								},
							],
						};
					}
				setManualExample(tmp);
			});
			if (type === 'update') {
				if (Object.keys(currentExampleState)?.length > 0) {
					getExample(tmp);
					getThumbnails();
				}
			}
		}
	}, [open, currentExampleState]);

	useEffect(() => {
		setCsvReport(createCSVTemplate(allColumns));

		// todo: review if is necessary
		// if (!allColumns.length) return;
		// const categories = [];
		// const getCategoryValues = async (id, nodeType) => {
		// 	setIsLocalLoading(true);
		// 	const nodeTypeForRequest =
		// 		nodeType === 'metadata' ? nodeType : `${nodeType}s`;
		// 	const res = await requestFactory(
		// 		'get',
		// 		`/schema/${nodeTypeForRequest}/${id}/categories`,
		// 		null
		// 	);
		// 	categories.push({ field: id, categories: res });
		// 	setIsLocalLoading(false);
		// 	return {
		// 		id,
		// 		res,
		// 	};
		// };

		// allColumns.forEach((column) => {
		// 	if (column.valueType === 'Category') {
		// 		getCategoryValues(column.field, column.fieldType).then(() => {
		// 			setManualElementCategories(categories);
		// 		});
		// 	}
		// });
	}, [allColumns]);

	const handleClose = (isUpdated) => {
		if (isUpdated)
			dispatch(
				GET_EXAMPLES({
					taskId: currentTaskState.uuid,
					accessToken,
					dispatch,
				})
			);
		setCreationStep(0);
		setOpen(false);
		setOpenCsvErrorModal(false);
		setFileToUpload(null);
		setManualExample({
			values: [],
			labeling_status: 'unlabeled',
			tags: { elements: [], groups: [] },
		});
	};

	const handleAddExampleManually = () => {
		setCreationStep(1);
	};

	const handleChangeCurrentRowStatus = (e) => {
		setManualExample({ ...manualExample, labeling_status: e.target.value });
	};

	useEffect(() => {
		if (Object.keys(tmpImage).length > 0)
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === tmpImage.element)
						return {
							element: tmpImage.element,
							value: tmpImage.value,
							thumbnailUrl: tmpImage.thumbnailUrl,
							tmpInfo: tmpImage.tmpInfo,
							isLoading: false,
						};
					return val;
				}),
			});
	}, [tmpImage]);

	const handleChangeManualExample = async ({
		e,
		usedFor,
		fileType,
		field,
		valueType,
		dateValue,
	}) => {
		let value = null;
		const { name, checked } = e.target;
		if (!dateValue) {
			value = e.target.value;
		}

		if (
			!dateValue &&
			e.target.files &&
			e.target.files[0] &&
			fileType === 'image'
		) {
			e.preventDefault();
			e.stopPropagation();

			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field) return { ...val, isLoading: true };
					return val;
				}),
			});

			const file = e.target.files[0];

			const res = await dispatch(
				CREATE_TASK_FILE({
					taskId: currentTaskState.id,
					file,
					accessToken,
					dispatch,
					usedFor,
					fileType,
				})
			);

			if (res.payload) {
				const resFile = await dispatch(
					GET_EXAMPLE_FILE({
						taskId: currentTaskState.id,
						fileId: res.payload.id,
						accessToken,
						dispatch,
						thumbnail: fileType === 'image',
					})
				);

				setTmpImage({
					element: field,
					value: res.payload.id,
					thumbnailUrl: resFile.payload.download_url,
					tmpInfo: res.payload,
					isLoading: false,
				});
				dispatch(
					ADD_ALERT({ type: 'success', message: IMAGE_FILE_UPLOAD_SUCCESS })
				);
				setTimeout(() => {
					dispatch(REMOVE_ALERT(IMAGE_FILE_UPLOAD_SUCCESS));
				}, 2000);
			} else {
				dispatch(
					ADD_ALERT({ type: 'error', message: IMAGE_FILE_UPLOAD_ERROR })
				);
			}
		} else if (
			!dateValue &&
			e.target.files &&
			e.target.files[0] &&
			fileType === 'csv'
		) {
			e.preventDefault();
			e.stopPropagation();

			// Parse csv file
			const parsedData = await parseCSVTimeSeriesFile(
				e.target.files[0],
				allColumns,
				dispatch,
				setIsLoadingFile,
				setFileToUpload,
				schemaState
			);

			// Set parsed value to manual example
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field)
						return {
							...val,
							isLoading: false,
							fileName: e.target.files[0].name,
							value: parsedData,
						};
					return val;
				}),
			});
		} else if ((value === 'on' || value === 'off') && field !== 'anomaly') {
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field) {
						return { element: field, value: 'checked' };
					}
					return val;
				}),
			});
		} else if (field === 'anomaly') {
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === name && checked) return { ...val, value: 1.0 };
					if (val.element === name && !checked) return { ...val, value: 0.0 };
					return val;
				}),
			});
		} else if (dateValue) {
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field) {
						return {
							element: field,
							value: getLocalDateTime(dateValue, userLocationState),
						};
					}
					return val;
				}),
			});
		} else {
			setManualExample({
				...manualExample,
				values: manualExample.values.map((val) => {
					if (val.element === field)
						return {
							element: field,
							value:
								valueType === 'number' ||
								valueType === 'integer' ||
								valueType === 'float'
									? Number(value)
									: value,
						};
					return val;
				}),
			});
		}
	};

	const handleSaveCreate = async () => {
		if (creationStep === 0) {
			setCreationStep(1);
		}
		if (creationStep === 1) {
			setCreationStep(0);
			setOpen(false);
			setFileToUpload(null);

			// CREATE EXAMPLE MANUALLY
			const tmpExample = manualExample.values
				.filter((val) => {
					if (val.value === '' || val.value === null || val.value === undefined)
						return false;
					return val;
				})
				.map((val) => ({ element: val.element, value: val.value }));

			await dispatch(
				CREATE_EXAMPLE({
					taskId: currentTaskState.id,
					newExample: {
						batch: [
							{
								labeling_status: manualExample.labeling_status,
								values: tmpExample,
							},
						],
					},
					accessToken,
					dispatch,
				})
			);
			setTimeout(() => {}, 1000);

			setCurrentRowId('');
			setSelectedRows([]);
			handleClose(true);
		}
	};

	const handleSaveUpdate = async () => {
		setCreationStep(0);
		setOpen(false);

		// CREATE EXAMPLE MANUALLY
		const tmpExample = manualExample.values
			.filter((val) => {
				if (val.value === '') return false;
				return val;
			})
			.map((val) => ({ element: val.element, value: val.value }));

		await dispatch(
			UPDATE_EXAMPLE({
				taskId: currentTaskState.id,
				exampleId: currentRowId,
				examplesToUpdate: {
					labeling_status: manualExample.labeling_status,
					values: tmpExample,
				},
				dispatch,
				accessToken,
			})
		);

		handleClose(true);
	};

	const handleClickUploadFile = () => {
		hiddenFileInput.current.click();
	};

	const handleChangeUploadCSV = async (event) => {
		setIsLoadingFile(true);
		setFileToUpload(event.target.files[0]);
		const newExamples = createValidatedExampleObjects(
			await parseCSVFile(
				event.target.files[0],
				allColumns,
				dispatch,
				setIsLoadingFile,
				setFileToUpload,
				schemaState,
				currentTaskState,
				accessToken,
				csvData,
				setCsvData
			),
			schemaState,
			dispatch
		);

		const res = await getFilesFromParsedCSV(
			newExamples,
			schemaState,
			dispatch,
			currentTaskState,
			accessToken,
			setUploadStatus,
			setCsvErrors
		);

		if (!res.errors) {
			await dispatch(
				CREATE_EXAMPLE({
					taskId: currentTaskState.id,
					newExample: { batch: res },
					accessToken,
					dispatch,
				})
			);

			await dispatch(
				GET_EXAMPLES({
					taskId: currentTaskState.id,
					accessToken,
					dispatch,
				})
			);
			setIsLoadingFile(false);
			handleClose();
		} else {
			setIsLoadingFile(false);
			setCsvErrors(res.errors);
			setOpenCsvErrorModal(true);
		}
	};

	const handleResetInputFile = (e) => {
		e.target.value = null;
	};

	const handleOpenDeleteExampleModal = () => {
		setOpenDeleteExampleModal(true);
	};

	const handleChangePage = async ({
		e,
		newPage,
		currentElementType,
		column,
	}) => {
		console.log({
			e,
			newPage,
			currentElementType,
			column,
		});
		setPage(newPage);
		await dispatch(
			GET_SCHEMA_NODE_CATEGORIES({
				accessToken,
				taskId: currentTaskState.uuid,
				dispatch,
				nodeName: column.field,
				nodeType: currentElementType,
				nodeId: column.id,
				page: parseInt(newPage + 1),
			})
		);
	};

	return (
		<>
			<StandardModal
				open={open}
				setOpen={setOpen}
				title="Create example:"
				content={
					<>
						{creationStep === 0 && (
							<Box className={classes.dialogContentContainer}>
								<Box className={classes.createTypeContainer}>
									<div className={classes.csvUploadColumn}>
										<button
											type="button"
											onClick={handleAddExampleManually}
											className={styles.createExampleButton}
										>
											Create manually
											<FontAwesomeIcon icon={faKeyboard} />
										</button>
									</div>
									<div className={classes.csvUploadColumn}>
										{isLoadingFile && (
											<button
												type="button"
												onClick={handleClickUploadFile}
												className={classes.createExampleButton}
											>
												{uploadStatus}
												<Loader size="L" local />
											</button>
										)}
										{!isLoadingFile && fileToUpload === null && (
											<>
												<button
													type="button"
													onClick={handleClickUploadFile}
													className={classes.createExampleButton}
												>
													Add CSV file
													<FontAwesomeIcon icon={faFileCsv} />
												</button>
												<input
													type="file"
													ref={hiddenFileInput}
													onChange={handleChangeUploadCSV}
													style={{ display: 'none' }}
												/>
											</>
										)}
										{!isLoadingFile && fileToUpload !== null && (
											<button
												type="button"
												className={classes.createExampleButton}
											>
												Selected file:
												{fileToUpload.name} upload finished
												<FontAwesomeIcon icon={faCloudArrowUp} />
											</button>
										)}
										{/* <input type="file"
                  className={classes.createExampleButton}
                  value={
                    <>
                      Add CSV file
                      <FontAwesomeIcon icon={faFileCsv} />
                    </>
                  }
                /> */}
										{!isLoadingFile &&
											fileToUpload === null &&
											Object.keys(csvReport)?.length > 0 && (
												<div className={styles.Export_btn}>
													<StandardButton
														type="textButton"
														value={
															// <CSVLink {...csvReport}>Export CSV template</CSVLink>
															<CSVLink
																// data={csvReport.data}
																filename={csvReport.filename}
																headers={csvReport.headers}
																data={[]}
															>
																Export CSV template
															</CSVLink>
														}
													/>
												</div>
											)}
									</div>
								</Box>
							</Box>
						)}

						{creationStep === 1 && (
							<Box className={classes.exampleColumnsContainer}>
								<Box className={classes.exampleColumnsRow}>
									<Box className={classes.nameColumn}>STATUS</Box>
									<Box className={classes.inputColumn}>
										<Select
											value={manualExample.labeling_status}
											onChange={handleChangeCurrentRowStatus}
											SelectDisplayProps={{
												style: {
													display: 'flex',
													alignItems: 'center',
													gap: '6px',
												},
											}}
										>
											<MenuItem
												value="labeled"
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '6px',
												}}
											>
												<FontAwesomeIcon
													icon={faCheck}
													style={{
														fontSize: '18px',
													}}
												/>
												Labeled
											</MenuItem>
											<MenuItem
												value="unlabeled"
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '6px',
												}}
											>
												<FontAwesomeIcon
													icon={faTag}
													style={{
														fontSize: '18px',
													}}
												/>
												Unlabeled
											</MenuItem>
											<MenuItem
												value="pending_review"
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '6px',
												}}
											>
												<FontAwesomeIcon
													icon={faFilePen}
													style={{
														fontSize: '18px',
													}}
												/>
												Pending review
											</MenuItem>
											<MenuItem
												value="rejected"
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '6px',
												}}
											>
												<FontAwesomeIcon
													icon={faBan}
													style={{
														fontSize: '18px',
													}}
												/>
												Rejected
											</MenuItem>
										</Select>
									</Box>
								</Box>

								{allColumns.map(
									(column) =>
										column.field !== 'edit' &&
										column.field !== 'status' && (
											<Box
												key={column.id}
												className={classes.exampleColumnsRow}
											>
												<Box className={classes.nameColumn}>
													{column.fieldType &&
														column.fieldType !== 'status' &&
														column.valueType &&
														elementTypeToIconMap[column.valueType](
															column.fieldType
														)}
													<div className={styles.create_data_labels}>
														{column.fieldType !== 'status' && column.name}
													</div>
												</Box>
												<Box className={classes.inputColumn}>
													{
														// INTEGER TYPE
														column.valueType === 'integer' &&
															(!column.multiValue ? (
																<Input
																	className={styles.common_input_type}
																	type="number"
																	value={
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value || ''
																	}
																	name={column.field}
																	onChange={(e) =>
																		handleChangeManualExample({
																			e,
																			field: column.field,
																			valueType: column.valueType,
																		})
																	}
																/>
															) : (
																<div style={classes.uploadContainer}>
																	{manualExample.values.find(
																		(element) =>
																			element.element === column.field
																	)?.isLoading ? (
																		<StandardButton
																			value={<Loader size="S" local />}
																			type="uploadFile"
																		/>
																	) : (
																		<StandardButton
																			accept=".csv"
																			value={
																				manualExample.values.find(
																					(element) =>
																						element.element === column.field
																				)?.fileName || 'Upload CSV'
																			}
																			type="uploadFile"
																			handleChange={(e) =>
																				handleChangeManualExample({
																					e,
																					usedFor: column.fieldType,
																					fileType: 'csv',
																					field: column.field,
																				})
																			}
																			id={column.field}
																			name={column.field}
																			handleClick={(e) =>
																				handleResetInputFile(e, 'time_serie')
																			}
																		/>
																	)}
																	<div style={styles.csvDataPreview}>
																		{manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value &&
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value.length > 1 ? (
																			<LineChart
																				width={200}
																				height={50}
																				data={
																					manualExample.values.find(
																						(element) =>
																							element.element === column.field
																					)?.value
																				}
																			>
																				<Line
																					dataKey={(v) => v}
																					stroke="#8884d8"
																					strokeWidth={1}
																					dot={false}
																				/>
																			</LineChart>
																		) : (
																			<div>
																				{
																					manualExample.values.find(
																						(element) =>
																							element.element === column.field
																					)?.value
																				}
																			</div>
																		)}
																	</div>
																</div>
															))
													}
													{
														// FLOAT TYPE
														column.valueType === 'float' &&
															(!column.multiValue ? (
																<Input
																	className={styles.common_input_type}
																	value={
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value || ''
																	}
																	name={column.field}
																	onChange={(e) =>
																		handleChangeManualExample({
																			e,
																			field: column.field,
																			valueType: column.valueType,
																		})
																	}
																	type="number"
																/>
															) : (
																<div className={classes.uploadContainer}>
																	{manualExample.values.find(
																		(element) =>
																			element.element === column.field
																	)?.isLoading ? (
																		<StandardButton
																			value={<Loader size="S" local />}
																			type="uploadFile"
																		/>
																	) : (
																		<StandardButton
																			accept=".csv"
																			value={
																				manualExample.values.find(
																					(element) =>
																						element.element === column.field
																				)?.fileName || 'Upload CSV'
																			}
																			type="uploadFile"
																			handleChange={(e) =>
																				handleChangeManualExample({
																					e,
																					usedFor: column.fieldType,
																					fileType: 'csv',
																					field: column.field,
																				})
																			}
																			id={column.field}
																			name={column.field}
																			handleClick={(e) =>
																				handleResetInputFile(e, 'time_serie')
																			}
																		/>
																	)}
																	<div className={classes.csvDataPreview}>
																		{manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value &&
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value.length > 1 ? (
																			<LineChart
																				width={200}
																				height={50}
																				data={
																					manualExample.values.find(
																						(element) =>
																							element.element === column.field
																					)?.value
																				}
																			>
																				<Line
																					dataKey={(v) => v}
																					stroke="#8884d8"
																					strokeWidth={1}
																					dot={false}
																				/>
																			</LineChart>
																		) : (
																			<div>
																				{
																					manualExample.values.find(
																						(element) =>
																							element.element === column.field
																					)?.value
																				}
																			</div>
																		)}
																	</div>
																</div>
															))
													}
													{
														// BOOLEAN TYPE
														column.valueType === 'boolean' && (
															<FormControlLabel
																control={
																	<Switch
																		checked={
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value || false
																		}
																		name={column.field}
																		onChange={(e) =>
																			handleChangeManualExample({
																				e,
																				field: column.field,
																			})
																		}
																	/>
																}
																label={
																	manualExample.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value
																		? 'True'
																		: 'False'
																}
															/>
														)
													}
													{
														// DATETIME TYPE
														column.valueType === 'datetime' && (
															<LocalizationProvider dateAdapter={AdapterDayjs}>
																<DateTimePicker
																	className={styles.date_pick_input_type}
																	value={
																		manualExample.values.find(
																			(element) =>
																				element.element === column.field
																		)?.value || ''
																	}
																	name={column.field}
																	onChange={(dateValue) =>
																		handleChangeManualExample({
																			dateValue,
																			field: column.field,
																		})
																	}
																	renderInput={(params) => (
																		<TextField {...params} helperText={null} />
																	)}
																	inputFormat="YYYY/MM/DD hh:mm"
																/>
															</LocalizationProvider>
														)
													}
													{
														// TEXT TYPE
														column.valueType === 'text' && (
															<TextField
																multiline
																rows={5}
																maxRows={5}
																inputProps={{ maxLength: 32000 }}
																id={column.field}
																className={styles.common_input_type}
																value={
																	manualExample.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value || ''
																}
																name={column.field}
																onChange={(e) =>
																	handleChangeManualExample({
																		e,
																		field: column.field,
																	})
																}
															/>
														)
													}
													{
														// CATEGORY TYPE
														column.valueType === 'category' && (
															<Select
																id="Category"
																className={classes.typeCategory}
																value={
																	manualExample.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value || ''
																}
																name={column.field}
																onChange={(e) =>
																	handleChangeManualExample({
																		e,
																		field: column.field,
																	})
																}
															>
																<MenuItem value={null}>None</MenuItem>
																{categoriesState &&
																	categoriesState[
																		column.fieldType !== 'metadata'
																			? `${column.fieldType}s`
																			: column.fieldType
																	] &&
																	categoriesState[
																		column.fieldType !== 'metadata'
																			? `${column.fieldType}s`
																			: column.fieldType
																	].length > 0 &&
																	categoriesState[
																		column.fieldType !== 'metadata'
																			? `${column.fieldType}s`
																			: column.fieldType
																	]
																		.find((c) => c.id === column.id)
																		?.categories?.map((category) => (
																			<MenuItem
																				value={category.name}
																				key={category.id}
																			>
																				<em>
																					{category.display_name ||
																						category.name}
																				</em>
																			</MenuItem>
																		))}
																{categoriesState &&
																	categoriesState[
																		column.fieldType !== 'metadata'
																			? `${column.fieldType}s`
																			: column.fieldType
																	] &&
																	categoriesState[
																		column.fieldType !== 'metadata'
																			? `${column.fieldType}s`
																			: column.fieldType
																	].length > 0 &&
																	categoriesState[
																		column.fieldType !== 'metadata'
																			? `${column.fieldType}s`
																			: column.fieldType
																	].find((c) => c.id === column.id) && (
																		<Grid
																			conteiner
																			style={{
																				display: 'flex',
																				justifyContent: 'center',
																			}}
																		>
																			<CustomPagination
																				total={
																					categoriesState[
																						column.fieldType !== 'metadata'
																							? `${column.fieldType}s`
																							: column.fieldType
																					].find((c) => c.id === column.id)
																						.total_count ||
																					categoriesState[
																						column.fieldType !== 'metadata'
																							? `${column.fieldType}s`
																							: column.fieldType
																					].find((c) => c.id === column.id)
																						?.categories?.length
																				}
																				rowsPerPage={rowsPerPage}
																				page={page}
																				handleChangePage={(e, value) => {
																					handleChangePage({
																						e,
																						newPage: value,
																						currentElementType:
																							column.fieldType !== 'metadata'
																								? `${column.fieldType}s`
																								: column.fieldType,
																						column,
																					});
																				}}
																				rowsPerPageOptions={[4]}
																				simple
																			/>
																			{/* <Pagination
																				count={Math.ceil(
																					categoriesState[
																						column.fieldType !== 'metadata'
																							? `${column.fieldType}s`
																							: column.fieldType
																					].find((c) => c.id === column.id)
																						.total_count / rowsPerPage
																				)}
																				siblingCount={0}
																				page={page + 1}
																				onChange={(e, value) => {
																					handleChangePage({
																						e,
																						newPage: value - 1,
																						currentElementType:
																							column.fieldType !== 'metadata'
																								? `${column.fieldType}s`
																								: column.fieldType,
																						column,
																					});
																				}}
																				boundaryCount={1}
																				size="small"
																			/> */}
																		</Grid>
																	)}
															</Select>
														)
													}
													{
														// GENERIC FILE TYPE
														column.valueType === 'generic_file' && (
															<div className={classes.uploadContainer}>
																{manualExample.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept="*"
																		value={
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value || 'Upload file'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualExample({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'generic',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={handleResetInputFile}
																	/>
																)}
																<div className={styles.data_preview}>
																	Can't load file preview
																</div>
															</div>
														)
													}
													{
														// DOCUMENT FILE TYPE
														column.valueType === 'document_file' && (
															<div className={classes.uploadContainer}>
																{manualExample.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
																		value={
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value || 'Upload document'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualExample({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'document',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={handleResetInputFile}
																	/>
																)}
																<div className={styles.data_preview}>
																	Can't load document preview
																</div>
															</div>
														)
													}
													{
														// IMAGE TYPE
														column.valueType === 'image_file' && (
															<div className={classes.uploadContainer}>
																{manualExample.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept="image/.jpg, image/.png"
																		value={
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value || 'Upload image'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualExample({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'image',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={handleResetInputFile}
																	/>
																)}
																<div className={styles.data_preview}>
																	{manualExample.values.find(
																		(element) =>
																			element.element === column.field
																	)?.thumbnailUrl ? (
																		<img
																			alt={
																				manualExample.values.find(
																					(element) =>
																						element.element === column.field
																				)?.value
																			}
																			src={
																				// manualExample.values.find(
																				// 	(element) =>
																				// 		element.element === column.field
																				// )?.value &&
																				manualExample.values.find(
																					(element) =>
																						element.element === column.field
																				)?.thumbnailUrl !== '' &&
																				// ? () =>
																				// 		getThumbnailImage(
																				// 			manualExample.values.find(
																				// 				(element) =>
																				// 					element.element ===
																				// 					column.field
																				// 			)?.value
																				// 		)
																				// :
																				manualExample.values.find(
																					(element) =>
																						element.element === column.field
																				)?.thumbnailUrl
																			}
																			className={classes.imagePreview}
																		/>
																	) : (
																		`Can't load image preview`
																	)}
																</div>
															</div>
														)
													}
													{
														// VIDEO TYPE
														column.valueType === 'video_file' && (
															<div className={classes.uploadContainer}>
																{manualExample.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept="video/*"
																		value={
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value || 'Upload video'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualExample({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'video',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={handleResetInputFile}
																	/>
																)}
																<div className={styles.data_preview}>
																	Can't load video preview
																</div>
															</div>
														)
													}
													{
														// AUDIO TYPE
														column.valueType === 'audio_file' && (
															<div className={classes.uploadContainer}>
																{manualExample.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept="audio/*"
																		value={
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.value || 'Upload audio'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualExample({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'audio',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={handleResetInputFile}
																	/>
																)}
																<div className={styles.data_preview}>
																	Can't load audio preview
																</div>
															</div>
														)
													}
													{
														// TIME SERIES TYPE
														column.valueType === 'slice' && (
															<div style={styles.uploadContainer}>
																{manualExample.values.find(
																	(element) => element.element === column.field
																)?.isLoading ? (
																	<StandardButton
																		value={<Loader size="S" local />}
																		type="uploadFile"
																	/>
																) : (
																	<StandardButton
																		accept=".csv"
																		value={
																			manualExample.values.find(
																				(element) =>
																					element.element === column.field
																			)?.fileName || 'Upload CSV'
																		}
																		type="uploadFile"
																		handleChange={(e) =>
																			handleChangeManualExample({
																				e,
																				usedFor: column.fieldType,
																				fileType: 'csv',
																				field: column.field,
																			})
																		}
																		id={column.field}
																		name={column.field}
																		handleClick={(e) =>
																			handleResetInputFile(e, 'time_serie')
																		}
																	/>
																)}
																<div style={styles.csvDataPreview}>
																	{manualExample.values.find(
																		(element) =>
																			element.element === column.field
																	)?.value && (
																		<LineChart
																			width={200}
																			height={50}
																			data={
																				manualExample.values.find(
																					(element) =>
																						element.element === column.field
																				)?.value
																			}
																		>
																			<Line
																				dataKey={(v) => v}
																				stroke="#8884d8"
																				strokeWidth={1}
																				dot={false}
																			/>
																		</LineChart>
																	)}
																</div>
															</div>
														)
													}
												</Box>
											</Box>
										)
								)}
							</Box>
						)}
						{type === 'update' && (
							<PageRow
								type="danger"
								column1={
									<Section title="Delete this examples">
										<span style={{ fontSize: 'small', color: colors.darkGray }}>
											Once deleted, it will be gone forever. Please be certain.
										</span>
									</Section>
								}
								column2={
									<Section style={{ fontWeight: 'bold' }}>
										<StandardButton
											handleClick={handleOpenDeleteExampleModal}
											type="danger"
											value="Delete this example"
										/>
									</Section>
								}
							/>
						)}
					</>
				}
				actions={
					<>
						{creationStep === 1 && (
							<StandardButton
								loading={manualExample.values.some(
									(element) => element.isLoading
								)}
								value={type === 'create' ? 'Create example' : 'Update example'}
								handleClick={
									type === 'create' ? handleSaveCreate : handleSaveUpdate
								}
							/>
						)}
						<StandardButton value="Close" handleClick={handleClose} close />
					</>
				}
			/>
			{openCsvErrorModal && (
				<StandardModal
					open={openCsvErrorModal}
					setOpen={setOpenCsvErrorModal}
					title="CSV upload errors report:"
					content={
						<ul>
							{csvErrors.map((error) => (
								<li key={uuidv4()}>{error}</li>
							))}
						</ul>
					}
					actions={
						<StandardButton value="Close" handleClick={handleClose} close />
					}
				/>
			)}
		</>
	);
};

CreateOrUpdateExampleModal.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	allColumns: PropTypes.array,
	type: PropTypes.string,
	step: PropTypes.number,
	currentRowId: PropTypes.string,
	setOpenDeleteExampleModal: PropTypes.func,
	setCurrentRowId: PropTypes.func,
	setSelectedRows: PropTypes.func,
};
