export const templates = {
	empty: {
		name: 'Empty schema',
		description: '',
	},
	image_classification: {
		name: 'Image Classification',
		description: 'Classify images into different categories.',
	},
	object_detection: {
		name: 'Object Detection',
		description:
			'Detect objects in images and draw bounding boxes around them.',
	},
	object_segmentation: {
		name: 'Object Segmentation',
		description: 'Segment objects in images and draw masks around them.',
	},
	text_classification: {
		name: 'Text Classification',
		description: 'Classify text into different categories.',
	},
	text_summarization: {
		name: 'Text Summarization',
		description: 'Summarize text into a shorter version.',
	},
	question_answering: {
		name: 'Question Answering',
		description: 'Answer questions based on a given context.',
	},
	audio_classification: {
		name: 'Audio Classification',
		description: 'Classify audio into different categories.',
	},
	speech_recognition: {
		name: 'Speech Recognition',
		description: 'Transcribe speech into text.',
	},
	tabular_classification: {
		name: 'Tabular Data Classification',
		description: 'Classify tabular data into different categories.',
	},
};
