import { makeStyles } from '@mui/styles';
import { colors } from '../../../../../consts/colors';
// import { colors } from '../../../../../../consts/colors';

export const useStyles = makeStyles({
	topMenu: {
		display: 'flex',
		width: '100%',
		marginBottom: '12px',
	},
	topMenuIcon: {
		fontSize: '24px',
		color: colors.blue,
		margin: '0px 6px',
		cursor: 'pointer',
	},
	JSON: {
		maxHeight: '500px',
		overflowY: 'auto',
	},
});

export const styles = {
	topMenu: {
		display: 'flex',
		width: '100%',
		marginBottom: '12px',
		alignItems: 'center',
	},
	topMenuIcon: {
		fontSize: '24px',
		color: colors.blue,
		margin: '0px 6px',
		cursor: 'pointer',
	},
	JSON: {
		maxHeight: '500px',
		overflowY: 'auto',
	},
};
