// SETTINGS VIEW
// SUCCESS
export const ADD_SETTING_USER_PERMISSION_SUCCESS = 'User succesfuly added';
export const DELETE_SETTING_USER_PERMISSION_SUCCESS = 'User succesfuly removed';
export const ADD_SETTING_ROLE_PERMISSION_SUCCESS = 'User succesfuly added';
export const DELETE_SETTING_ROLE_PERMISSION_SUCCESS = 'User succesfuly removed';

// ALERT

// WARNING
export const ADD_SETTING_USER_PERMISSION_WARNING =
	'Select a user to add to your task';
export const ADD_SETTING_ROLE_PERMISSION_WARNING =
	'Select a user to add to your task';

// ERROR
