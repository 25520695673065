import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { useFlowActions } from './FlowContext';

export const useStyles = makeStyles({
	edgebutton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '30px',
		height: '30px',
		background: '#eee',
		color: 'red',
		border: '1px solid red',
		cursor: 'pointer',
		borderRadius: '50%',
		fontSize: '1rem',
		lineHeight: 1,
		fontWeight: 'bold',
		'&:hover': {
			boxShadow: '0 0 6px 2px rgba(0, 0, 0, 0.08)',
		},
	},
});

export default function CustomEdge({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	markerEnd,
}) {
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	const { onRemoveEdgeButtonClick } = useFlowActions();

	const classes = useStyles();

	return (
		<>
			<BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
			<EdgeLabelRenderer>
				<div
					style={{
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						fontSize: 12,
						// everything inside EdgeLabelRenderer has no pointer events by default
						// if you have an interactive element, set pointer-events: all
						pointerEvents: 'all',
					}}
					className="nodrag nopan"
				>
					<button
						type="button"
						className={classes.edgebutton}
						onClick={(event) => onRemoveEdgeButtonClick(event, id)}
					>
						X
					</button>
				</div>
			</EdgeLabelRenderer>
		</>
	);
}

CustomEdge.propTypes = {
	id: PropTypes.any,
	sourceX: PropTypes.any,
	sourceY: PropTypes.any,
	targetX: PropTypes.any,
	targetY: PropTypes.any,
	sourcePosition: PropTypes.any,
	targetPosition: PropTypes.any,
	style: PropTypes.any,
	markerEnd: PropTypes.any,
};
