import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import requestFactory from '../services/request.factory';
import { ADD_ALERT, REMOVE_ALERT } from './alerts.slice';
import { SCHEMA_NODE_DELETED } from '../AlertsList/projectSettingsAlerts';

// GET

export const GET_SCHEMA = createAsyncThunk(
	'schema/GET_SCHEMA',
	async ({ accessToken, taskId, dispatch }) => {
		console.log('schema/GET_SCHEMA');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/schema`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_SCHEMA_GROUPS = createAsyncThunk(
	'schema/GET_SCHEMA_GROUPS',
	async ({ accessToken, taskId, dispatch }) => {
		console.log('schema/GET_SCHEMA_GROUPS');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/schema/groups`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_SCHEMA_INPUTS = createAsyncThunk(
	'schema/GET_SCHEMA_INPUTS',
	async ({ accessToken, taskId, dispatch }) => {
		console.log('schema/GET_SCHEMA_INPUTS');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/schema/inputs`,
			accessToken,
			dispatch,
		});
		if (res) return res;
	}
);

export const GET_SCHEMA_OUTPUTS = createAsyncThunk(
	'schema/GET_SCHEMA_OUTPUTS',
	async ({ accessToken, taskId, dispatch }) => {
		console.log('schema/GET_SCHEMA_OUTPUTS');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/schema/outputs`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_SCHEMA_METADATA = createAsyncThunk(
	'schema/GET_SCHEMA_METADATA',
	async ({ accessToken, taskId, dispatch }) => {
		console.log('schema/GET_SCHEMA_METADATA');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/schema/metadata`,
			accessToken,
			dispatch,
		});

		if (res) return res;
	}
);

export const GET_SCHEMA_NODE_CATEGORIES = createAsyncThunk(
	'schema/GET_SCHEMA_NODE_CATEGORIES',
	async ({
		accessToken,
		taskId,
		dispatch,
		nodeType,
		nodeId,
		nodeName,
		page = 1,
	}) => {
		console.log('schema/GET_SCHEMA_NODE_CATEGORIES');
		const res = await requestFactory({
			type: 'GET',
			url: `/tasks/${taskId}/schema/${nodeType}/${nodeId}/categories?page=${page}&per_page=4&total_count=true`,
			accessToken,
			dispatch,
		});

		if (res) return { nodeId, nodeName, nodeType, res };
	}
);

// POST

export const CREATE_SCHEMA_NODE = createAsyncThunk(
	'schema/CREATE_SCHEMA_NODE',
	async ({ node, taskId, nodeType, accessToken, dispatch }) => {
		console.log('schema/CREATE_SCHEMA_NODE');
		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/schema/${nodeType}`,
			data: node,
			accessToken,
			dispatch,
		});
		if (res) {
			return {
				res,
				nodeType,
			};
		}
	}
);

export const CREATE_SCHEMA_NODE_CATEGORY = createAsyncThunk(
	'schema/CREATE_SCHEMA_NODE_CATEGORY',
	async ({ taskId, nodeType, nodeId, category, accessToken, dispatch }) => {
		console.log('schema/CREATE_SCHEMA_NODE_CATEGORY');
		const res = await requestFactory({
			type: 'POST',
			url: `/tasks/${taskId}/schema/${nodeType}/${nodeId}/categories`,
			data: category,
			accessToken,
			dispatch,
		});

		return { nodeType, nodeId, res };
	}
);

// PUT

// DELETE

export const DELETE_SCHEMA_NODE = createAsyncThunk(
	'schema/DELETE_SCHEMA_NODE',
	async ({ nodeType, nodeId, taskId, dispatch, accessToken }) => {
		console.log('schema/DELETE_SCHEMA_NODE');
		requestFactory({
			type: 'DELETE',
			url: `/tasks/${taskId}/schema/${nodeType}/${nodeId}`,
			accessToken,
			dispatch,
		});

		return {
			nodeId,
			nodeType,
		};
	}
);

export const DELETE_SCHEMA_NODE_CATEGORY = createAsyncThunk(
	'schema/DELETE_SCHEMA_NODE_CATEGORY',
	async ({ nodeType, nodeId, taskId, categoryId, dispatch, accessToken }) => {
		console.log('schema/DELETE_SCHEMA_NODE_CATEGORY');
		requestFactory({
			type: 'DELETE',
			url: `/tasks/${taskId}/schema/${nodeType}/${nodeId}/categories/${categoryId}`,
			accessToken,
			dispatch,
		});

		return {
			nodeType,
			nodeId,
			categoryId,
		};
	}
);

/** *********** OLD REQUESTS ************** */

// export const UPDATE_SCHEMA_ELEMENT = createAsyncThunk(
// 	'schema/UPDATE_SCHEMA_ELEMENT',
// 	async ({ accessToken, taskId, dispatch, element }) => {
// 		console.log('schema/UPDATE_SCHEMA_ELEMENT');
// 		await requestFactory({
// 			type: 'POST',
// 			url: `/tasks/${taskId}/schema/${element}`,
// 			accessToken,
// 			dispatch,
// 			data: {},
// 		});

// 		dispatch(GET_SCHEMA);
// 	}
// );

// export const UPDATE_SCHEMA_NODE = createAsyncThunk(
// 	'schema/UPDATE_SCHEMA_NODE',
// 	async (data, thunkAPI) => {
// 		console.log('schema/UPDATE_SCHEMA_NODE');
// 		const res = await requestFactory(
// 			'put',
// 			`/schema/${data.nodeType}/${data.nodeId}`,
// 			data.body,
// 			data.testServerId
// 		);

// 		if (data.valueType === 'Category') {
// 			for (const category of data.categories) {
// 				// if (category.isNew) {
// 				thunkAPI.dispatch(
// 					// eslint-disable-next-line no-use-before-define
// 					CREATE_SCHEMA_NODE_CATEGORY({
// 						nodeType: data.nodeType,
// 						nodeId: res.id,
// 						body: {
// 							name: category.value,
// 							caption: category.value,
// 							color: category.color,
// 						},
// 						testServerId: data.testServerId,
// 					})
// 				);
// 			}
// 		}

// 		return {
// 			nodeType: data.nodeType === 'targets' ? 'targets' : data.nodeType,
// 			...res,
// 		};
// 	}
// );

// CATEGORIES

// export const UPDATE_SCHEMA_NODE_CATEGORIES = createAsyncThunk(
// 	'schema/UPDATE_SCHEMA_NODE_CATEGORIES',
// 	async ({ nodeType, nodeId, categoryId, testServerId, body }) => {
// 		console.log('schema/UPDATE_SCHEMA_NODE_CATEGORIES');
// 		await requestFactory(
// 			'put',
// 			`/schema/${nodeType}/${nodeId}/categories/${categoryId}`,
// 			body,
// 			testServerId
// 		);
// 	}
// );

// export const GET_SCHEMA_NODE_CATEGORIES = createAsyncThunk(
// 	'schema/GET_SCHEMA_NODE_CATEGORIES',
// 	async ({ nodeType, nodeId, taskId, accessToken, dispatch }) => {
// 		let _nodeType;
// 		if (nodeType === 'meta-data') {
// 			_nodeType = 'metadata';
// 		} else if (nodeType === 'targets') {
// 			_nodeType = 'targets';
// 		} else {
// 			_nodeType = nodeType;
// 		}
// 		const res = await requestFactory({
// 			type: 'GET',
// 			url: `/tasks/${taskId}/schema/${_nodeType}/${nodeId}/categories`,
// 			accessToken,
// 			dispatch,
// 		});

// 		return {
// 			nodeId,
// 			nodeType,
// 			categories: res,
// 		};
// 	}
// );

// export const DELETE_SCHEMA_NODE_CATEGORIES = createAsyncThunk(
// 	'schema/DELETE_SCHEMA_NODE_CATEGORIES',
// 	async ({ nodeType, nodeId, categoryId, taskId, accessToken, dispatch }) => {
// 		let _nodeType;
// 		if (nodeType === 'meta-data') {
// 			_nodeType = 'metadata';
// 		} else {
// 			_nodeType = nodeType;
// 		}
// 		await requestFactory({
// 			type: 'DELETE',
// 			url: `/tasks/${taskId}/schema/${_nodeType}/${nodeId}/categories/${categoryId}`,
// 			accessToken,
// 			dispatch,
// 		});
// 	}
// );

export const schemaSlice = createSlice({
	name: 'schema',
	initialState: {
		isLoading: true,
		schema: {},
		currentSchemaElements: {
			nodes: [],
			edges: [],
		},
		selectedNodeCategories: {},
		categories: {},
	},
	reducers: {
		SET_CURRENT_SCHEMA_ELEMENTS: (state, { payload }) => {
			state.currentSchemaElements = payload;
		},
		SET_GROUPS: (state, { payload }) => {
			state.groups = payload;
		},

		SET_INPUTS: (state, { payload }) => {
			state.inputs = payload;
		},

		SET_METADATA: (state, { payload }) => {
			state.metadata = payload;
		},

		DELETE_GROUP: (state, { payload }) => {
			state.groups = state.groups.filter((group) => group.id !== payload);
		},

		DELETE_INPUT: (state, { payload }) => {
			state.inputs = state.inputs.filter((input) => input.id !== payload);
		},

		DELETE_OUTPUT: (state, { payload }) => {
			state.targets = state.targets.filter((output) => output.id !== payload);
		},

		DELETE_METADATA: (state, { payload }) => {
			state.metadata = state.metadata.filter((m) => m.id !== payload);
		},

		RESET_ALL_CATEGORIES: (state) => {
			state.categories = {};
		},
		CLEAR_SELECTED_NODE_CATEGORIES: (state) => {
			state.selectedNodeCategories = {};
		},
	},
	extraReducers: {
		// GET

		[GET_SCHEMA.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_SCHEMA.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.schema = payload;
		},
		[GET_SCHEMA.rejected]: (state) => {
			state.isLoading = false;
		},

		[GET_SCHEMA_GROUPS.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_SCHEMA_GROUPS.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.groups = payload;
		},
		[GET_SCHEMA_GROUPS.rejected]: (state) => {
			state.isLoading = false;
		},

		[GET_SCHEMA_INPUTS.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_SCHEMA_INPUTS.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.inputs = payload;
		},
		[GET_SCHEMA_INPUTS.rejected]: (state) => {
			state.isLoading = false;
		},

		[GET_SCHEMA_OUTPUTS.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_SCHEMA_OUTPUTS.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.targets = payload;
		},
		[GET_SCHEMA_OUTPUTS.rejected]: (state) => {
			state.isLoading = false;
		},

		[GET_SCHEMA_METADATA.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_SCHEMA_METADATA.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.metadata = payload;
		},
		[GET_SCHEMA_METADATA.rejected]: (state) => {
			state.isLoading = false;
		},

		[GET_SCHEMA_NODE_CATEGORIES.pending]: (state) => {
			state.isLoading = true;
		},
		[GET_SCHEMA_NODE_CATEGORIES.fulfilled]: (state, { payload }) => {
			state.isLoading = false;

			if (state.categories[payload.nodeType]) {
				if (
					state.categories[payload.nodeType].find(
						(category) => category.id === payload.nodeId
					)
				) {
					state.categories = {
						...state.categories,
						[payload.nodeType]: state.categories[payload.nodeType].map(
							(category) => {
								if (category.id === payload.nodeId)
									return {
										id: payload.nodeId,
										name: payload.nodeName,
										total_count: payload.res.total_count,
										categories: payload.res.data,
									};
								return category;
							}
						),
					};
				} else {
					state.categories = {
						...state.categories,
						[payload.nodeType]: [
							...state.categories[payload.nodeType],
							{
								id: payload.nodeId,
								name: payload.nodeName,
								total_count: payload.res.total_count,
								categories: payload.res.data,
							},
						],
					};
				}
			} else {
				state.categories = {
					...state.categories,
					[payload.nodeType]: [
						{
							id: payload.nodeId,
							name: payload.nodeName,
							total_count: payload.res.total_count,
							categories: payload.res.data,
						},
					],
				};
			}
		},
		[GET_SCHEMA_NODE_CATEGORIES.rejected]: (state) => {
			state.isLoading = false;
		},

		// POST

		[CREATE_SCHEMA_NODE.pending]: (state) => {
			state.isLoading = true;
		},
		[CREATE_SCHEMA_NODE.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			if (payload.res.type === 'category') {
				state.categories[payload.nodeType] = [
					...state.categories[payload.nodeType],
					{ id: payload.res.id, name: payload.res.name, categories: [] },
				];
			}

			if (payload && state.schema[payload.nodeType])
				state.schema[payload.nodeType] = state.schema[payload.nodeType].concat(
					payload.res
				);
			else
				state.schema = {
					...state.schema,
					[payload.nodeType]: [payload.res],
				};
		},
		[CREATE_SCHEMA_NODE.rejected]: (state) => {
			state.isLoading = false;
		},

		[CREATE_SCHEMA_NODE_CATEGORY.pending]: (state) => {
			state.isLoading = true;
		},
		[CREATE_SCHEMA_NODE_CATEGORY.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			const tmpCat = state.categories[payload.nodeType].find(
				(cat) => cat.id === payload.nodeId
			);
			let newCategories;
			if (tmpCat)
				newCategories = state.categories[payload.nodeType].map((cat) => {
					const newCat = cat;
					if (cat.id === payload.nodeId) {
						newCat.total_count += 1;
						newCat.categories = [...newCat.categories, payload.res];
						return newCat;
					}
					return cat;
				});
			// else
			//   newCategories = [...state.categories[payload.nodeType].map((cat) => {
			// 	const newCat = cat;
			// 	if (cat.id === payload.nodeId) {
			// 		newCat.total_count += 1;
			// 		newCat.categories = [...newCat.categories, payload.res];
			// 		return newCat;
			// 	}
			// 	return cat;
			// });

			state.categories[payload.nodeType] = newCategories;
		},
		[CREATE_SCHEMA_NODE_CATEGORY.rejected]: (state) => {
			state.isLoading = false;
		},

		// DELETE NODE

		[DELETE_SCHEMA_NODE.pending]: (state) => {
			state.isLoading = true;
		},
		[DELETE_SCHEMA_NODE.fulfilled]: (state, { payload }) => {
			state.isLoading = false;

			state.currentSchemaElements = state.currentSchemaElements.nodes.filter(
				(node) => (node.element || node.id) !== payload.nodeId
			);

			const currentNode = state.schema[payload.nodeType].find(
				(node) => node.id === payload.nodeId
			);
			console.log(currentNode);
			if (currentNode.type === 'category') {
				console.log('isCategory');
				const newCategories = state.categories[payload.nodeType].filter(
					(node) => node.id !== payload.nodeId
				);

				state.categories[payload.nodeType] = newCategories;
			}
		},
		[DELETE_SCHEMA_NODE.rejected]: (state) => {
			state.isLoading = false;
		},

		[DELETE_SCHEMA_NODE_CATEGORY.pending]: (state) => {
			state.isLoading = true;
		},
		[DELETE_SCHEMA_NODE_CATEGORY.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			const newCategories = state.categories[payload.nodeType].map((node) => {
				if (node.id === payload.nodeId) {
					return {
						...node,
						total_count: node.total_count - 1,
						categories: node.categories.filter(
							(cat) => cat.id !== payload.categoryId
						),
					};
				}
				return node;
			});

			state.categories[payload.nodeType] = newCategories;
		},
		[DELETE_SCHEMA_NODE_CATEGORY.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export const {
	SET_CURRENT_SCHEMA_ELEMENTS,
	SET_GROUPS,
	SET_INPUTS,
	SET_METADATA,
	DELETE_GROUP,
	DELETE_INPUT,
	DELETE_METADATA,
	DELETE_OUTPUT,
	CLEAR_SELECTED_NODE_CATEGORIES,
	RESET_ALL_CATEGORIES,
} = schemaSlice.actions;

export default schemaSlice.reducer;
