import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	foreground: {
		position: 'relative',
		zIndex: 2,
		'& > svg': {
			color: 'var(--color_050) !important',
		},
	},
	background: {
		position: 'absolute',
		zIndex: 1,
		right: 0,
		'& > svg': {
			color: 'var(--color_300)',
			circle: {
				strokeDashoffset: '0px !important',
			},
		},
	},
});
