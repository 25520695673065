import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';

// Manage roles & permissions
import { HasAccess, usePermify } from '@permify/react-role';

// Components
import {
	Container,
	Box,
	Grid,
	Typography,
	Skeleton,
	Dialog,
	DialogTitle,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CardItem from '../../../../Components/Shared/CardItem';
import Documentation from '../../../Dashboard/Documentation';
import Training from '../../../Dashboard/Training';
import AccessDenied from '../../../../Components/Core/AccessDenied';
import { ConfirmationDialog } from '../../../../Components/Shared/ConfirmationDialog';

// Redux
import {
	GET_MODELS,
	GET_PRODUCTION_MODEL,
	GET_TESTING_MODEL,
	// GET_PRODUCTION_MODELS,
	SET_PRODUCTION_MODEL,
	SET_TESTING_MODEL,
} from '../../../../redux/aireleases.slice';

// Styles
import useStyles from './styles';
import { colors } from '../../../../consts/colors';
import { measures } from '../../../../consts/sizes';
import StandardModal from '../../../../Components/Shared/StandardModal';
import FixedFlow from '../../../../Components/Core/Task/Schema/FixedFlow';
import StandardButton from '../../../../Components/Shared/Buttons/StandardButton';

// Consts
import { defaultRoles } from '../../../../consts/rolesAndPermissions';
import checkIsAuthorized from '../../../../services/checkIsAuthorized';
import { getLocalDateTime } from '../../../../services/date';

export function TaskModels() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isAuthorized } = usePermify();

	const { aireleases: aireleasesLoaderState } = useSelector(
		(state) => state.loaders.task
	);
	const aireleases = useSelector((state) => state.aireleases);
	const { accessToken } = useSelector((state) => state.user);
	const { currentTask: currentTaskState } = useSelector((state) => state.tasks);

	const [currentSchema, setCurrentSchema] = useState(false);
	const [canUpdate, setCanUpdate] = useState(false);
	const [selectedModel, setSelectedModel] = useState();

	// Modals
	const [openShowSchema, setOpenShowSchema] = useState(false);
	const [openConfirmProduction, setOpenConfirmProduction] = useState(false);
	const [openConfirmTesting, setOpenConfirmTesting] = useState(false);

	useEffect(() => {
		checkIsAuthorized({
			isAuthorized,
			setCanUpdate,
			permission: 'task.update',
		});
	}, []);

	const handleSetProductionModel = async () => {
		if (selectedModel.uuid !== aireleases.productionAIRelease.uuid) {
			await dispatch(
				SET_PRODUCTION_MODEL({
					taskId: currentTaskState.uuid,
					accessToken,
					dispatch,
					modelId: selectedModel.uuid,
				})
			);

			await dispatch(
				GET_MODELS({ accessToken, dispatch, taskId: currentTaskState.uuid })
			);
			await dispatch(
				GET_TESTING_MODEL({
					taskId: currentTaskState.uuid,
					accessToken,
					dispatch,
				})
			);
			await dispatch(
				GET_PRODUCTION_MODEL({
					taskId: currentTaskState.uuid,
					accessToken,
					dispatch,
				})
			);
		}
	};
	const handleSetTestingModel = async () => {
		// if (selectedModel.uuid !== aireleases.productionAIRelease.uuid) {
		await dispatch(
			SET_TESTING_MODEL({
				taskId: currentTaskState.uuid,
				accessToken,
				dispatch,
				modelId: selectedModel.uuid,
			})
		);

		await dispatch(
			GET_MODELS({ accessToken, dispatch, taskId: currentTaskState.uuid })
		);
		await dispatch(
			GET_TESTING_MODEL({
				taskId: currentTaskState.uuid,
				accessToken,
				dispatch,
			})
		);
		await dispatch(
			GET_PRODUCTION_MODEL({
				taskId: currentTaskState.uuid,
				accessToken,
				dispatch,
			})
		);
		// }
	};

	const handleCloseShowModal = () => {
		setOpenConfirmProduction(false);
		setOpenConfirmTesting(false);
		setCurrentSchema({});
		setOpenShowSchema(false);
	};

	return (
		<HasAccess
			roles={defaultRoles}
			permissions="task.read"
			renderAuthFailed={<AccessDenied />}
		>
			<Box
				sx={{
					backgroundColor: 'Background.default',
					minHeight: '100%',
					pt: 3,
				}}
			>
				<Container maxWidth={false} style={{ padding: '0px' }}>
					<Grid container spacing={3}>
						<Grid
							item
							xs={12}
							sx={{
								paddingLeft: '0px',
								paddingRight: '0px',
							}}
						>
							<Box
								sx={{
									paddingLeft: '0px',
									paddingRight: '0px',
								}}
							>
								{/* Models */}
								<CardItem
									elevation={measures.cardItemElevation}
									sx={{
										display: 'flex',
										padding: 0,
									}}
									title="AI releases"
									type="noIcon"
								>
									<Box
										pl={2}
										pb={4}
										pr={2}
										sx={{
											display: 'flex',
											gap: 3,
											flexDirection: 'column',
											paddingLeft: '0px !important',
											paddingRight: '0px !important',
										}}
									>
										{!aireleasesLoaderState ? (
											aireleases &&
											aireleases.AIReleases &&
											aireleases.AIReleases.map((model) => (
												<Box
													className={classes.modelItemContainer}
													key={uuidv4()}
													style={{
														padding: '12px',
														outline:
															aireleases.productionAIRelease?.uuid ===
																model.uuid &&
															`2px solid ${colors.buttonPrimaryColor}`,
														borderRadius: '12px',
													}}
												>
													<Box className={classes.modelLeftContainer}>
														<Box
															sx={{
																display: 'flex',
																flexDirection: 'column',
															}}
														>
															<Typography
																variant="span"
																sx={{ color: '#666666', fontSize: 13 }}
															>
																Id: {model.uuid}
															</Typography>

															<Box display="flex" alignItems="center">
																<Typography
																	variant="span"
																	sx={{ color: '#666666', fontSize: 13 }}
																>
																	Version: {model.version || ''}
																</Typography>
															</Box>

															<Box display="flex" alignItems="center">
																Creation date:
																<Typography
																	variant="span"
																	sx={{ color: '#666666', fontSize: 13 }}
																>
																	{format(
																		new Date(
																			getLocalDateTime(model.created_at)
																		),
																		'yyyy/MM/dd, HH:mm'
																	)}
																</Typography>
															</Box>

															<Box
																sx={{
																	marginTop: '24px',
																	display: 'flex',
																	alignItems: 'center',
																	gap: 2,
																}}
															>
																<StandardButton
																	value="Show schema"
																	handleClick={() => {
																		setOpenShowSchema(true);
																		setCurrentSchema(model.task_schema);
																	}}
																/>
																<StandardButton
																	value="Deploy in production"
																	type={
																		(model.uuid ===
																			aireleases.productionAIRelease?.uuid &&
																			'disabled') ||
																		''
																	}
																	handleClick={() => {
																		if (canUpdate) {
																			if (
																				aireleases.productionAIRelease?.uuid !==
																				model.uuid
																			) {
																				setOpenConfirmProduction(true);
																				setSelectedModel(model);
																			}
																		}
																	}}
																/>
																<StandardButton
																	value="Deploy in testing"
																	type={
																		(model.uuid ===
																			aireleases.testingAIRelease?.uuid &&
																			'disabled') ||
																		''
																	}
																	handleClick={() => {
																		if (canUpdate) {
																			if (
																				aireleases.testingAIRelease?.uuid !==
																				model.uuid
																			) {
																				setOpenConfirmTesting(true);
																				setSelectedModel(model);
																			}
																		}
																	}}
																/>
															</Box>
														</Box>
														<Box className={classes.modelActiveContainer}>
															{aireleases.productionAIRelease?.uuid &&
																model.uuid ===
																	aireleases.productionAIRelease.uuid && (
																	<Box
																		sx={{
																			display: 'flex',
																			alignItems: 'center',
																			gap: '5px',
																		}}
																	>
																		<CircleIcon
																			sx={{
																				color: colors.modelActive,
																				fontSize: 10,
																			}}
																		/>
																		<Typography
																			variant="subtitle1"
																			sx={{ fontSize: 12 }}
																		>
																			Production
																		</Typography>
																	</Box>
																)}
															{aireleases.testingAIRelease?.uuid &&
																model.uuid ===
																	aireleases.testingAIRelease.uuid && (
																	<Box
																		className={classes.modelActiveContainer}
																		sx={{
																			display: 'flex',
																			flexDirection: 'row',
																			alignItems: 'center',
																		}}
																	>
																		<CircleIcon
																			sx={{
																				color: colors.buttonPrimaryColor,
																				fontSize: 10,
																			}}
																		/>
																		<Typography
																			variant="subtitle1"
																			sx={{ fontSize: 12 }}
																		>
																			Testing
																		</Typography>
																	</Box>
																)}

															{model.uuid !==
																aireleases.productionAIRelease?.uuid &&
																model.uuid !==
																	aireleases.testingAIRelease?.uuid && (
																	<Box
																		className={classes.modelActiveContainer}
																		sx={{
																			display: 'flex',
																			flexDirection: 'row',
																			alignItems: 'center',
																		}}
																	>
																		<CircleIcon
																			sx={{
																				color: colors.errorAlertDark,
																				fontSize: 10,
																			}}
																		/>
																		<Typography
																			variant="subtitle1"
																			sx={{ fontSize: 12 }}
																		>
																			Inactive
																		</Typography>
																	</Box>
																)}
														</Box>
													</Box>
												</Box>
											)).reverse()
										) : (
											<Box
												className={classes.modelItemContainer}
												key={uuidv4()}
												style={{
													padding: '12px',
													outline: '2px solid #eee',
													borderRadius: '12px',
												}}
											>
												<Box className={classes.modelLeftContainer}>
													<Box
														sx={{
															display: 'flex',
															flexDirection: 'column',
														}}
													>
														<div style={{ display: 'flex', gap: 12 }}>
															Id:
															<Skeleton
																variant="text"
																style={{
																	height: '18px',
																	width: '150px',
																}}
															/>
														</div>

														<div style={{ display: 'flex', gap: 12 }}>
															Version:
															<Skeleton
																variant="text"
																style={{
																	height: '18px',
																	width: '150px',
																}}
															/>
														</div>

														<div style={{ display: 'flex', gap: 12 }}>
															Creation date:
															<Skeleton
																variant="text"
																style={{
																	height: '18px',
																	width: '150px',
																}}
															/>
														</div>

														<Box display="flex" alignItems="center">
															<Skeleton
																variant="text"
																style={{
																	height: '48px',
																	width: '150px',
																}}
															/>
														</Box>
													</Box>
													<div className={classes.modelActiveContainer}>
														<CircleIcon
															sx={{
																color: '#eee',
																fontSize: 10,
															}}
														/>
														<Skeleton
															variant="text"
															style={{
																height: '18px',
																width: '150px',
															}}
														/>
													</div>
												</Box>
											</Box>
										)}
									</Box>
								</CardItem>
							</Box>
						</Grid>
						{/* <Grid item xs={12} md={4} xl={4}>
							<Box
								sx={{
									height: '100%',
								}}
							>
								<CardItem
									elevation={measures.cardItemElevation}
									sx={{
										display: 'flex',
										padding: 1,
										marginBottom: 3,
									}}
									title="Training"
									titleicon={<ChangeHistoryIcon />}
								>
									<Training />
								</CardItem>

								 <CardItem
									elevation={measures.cardItemElevation}
									sx={{
										width: '100%',
										display: 'flex',
										padding: 1,
										marginBottom: 3,
									}}
									title="Documentation"
									titleicon={<MenuBookIcon />}
								>
									<Documentation />
								</CardItem>
							</Box>
						</Grid> */}
					</Grid>
				</Container>
			</Box>
			{openShowSchema && (
				<StandardModal
					open={openShowSchema}
					setOpen={handleCloseShowModal}
					title="Production AI Release schema"
					content={
						<Box sx={{ height: '584px' }}>
							<FixedFlow tmpSchema={currentSchema} />
						</Box>
					}
					actions={
						<StandardButton
							close
							value="Close"
							handleClick={handleCloseShowModal}
						/>
					}
				/>
			)}
			{openConfirmProduction && (
				<ConfirmationDialog
					title="Set this AI release to production?"
					open={openConfirmProduction}
					setOpen={setOpenConfirmProduction}
					onConfirm={handleSetProductionModel}
					openShowSchema={openShowSchema}
				/>
			)}
			{openConfirmTesting && (
				<ConfirmationDialog
					title="Set this AI release to testing?"
					open={openConfirmTesting}
					setOpen={setOpenConfirmTesting}
					onConfirm={handleSetTestingModel}
					openShowSchema={openShowSchema}
				/>
			)}
		</HasAccess>
	);
}
